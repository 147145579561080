const modelOptions = {
    MBMT: Array.from({ length: 32 }, (_, i) => `MBMT-${String(i + 1).padStart(2, "0")}`),
    Aeromall: Array.from({ length: 2 }, (_, i) => `Aeromall-${String(i + 1).padStart(2, "0")}`),
    UMC: Array.from({ length: 10 }, (_, i) => `UMC-${String(i + 1).padStart(2, "0")}`),
    Uber: Array.from({ length: 4 }, (_, i) => `Uber-${String(i + 1).padStart(2, "0")}`),
};

const data = {
    fleetOwners: [
        {
            name: "Transvolt",
            group: [
                { name: "MBMT", models: [] },
                { name: "Aeromall", models: [] },
                { name: "UMC", models: [] },
                { name: "Uber", models: [] }
            ]
        }
    ]
};

// Adding models to each group's models array
data.fleetOwners[0].group.forEach(group => {
    group.models = modelOptions[group.name]; // Assign the corresponding models
});


export { data };