import React, { useState } from "react";
import "./VehicleAndFleet.css";
import Select from "react-select";
import editIcon from "../../assets/edit.png";
import deleteIcon from "../../assets/delete.png";

function VehicleAndFleet() {
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState([]);
  const [showFleetForm, setShowFleetForm] = useState(false);
  const [fleetName, setFleetName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [fleets, setFleets] = useState([]);
  const [showVehicleForm, setShowVehicleForm] = useState(false);
  const [modelName, setModelName] = useState("");
  const [chassisNumber, setChassisNumber] = useState("");
  const [vrn, setVrn] = useState("");
  const [selectedFleet, setSelectedFleet] = useState(null);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [editingVehicleIndex, setEditingVehicleIndex] = useState(null);
  const [editingFleetIndex, setEditingFleetIndex] = useState(null);
  const handleFleetClick = () => {
    setShowFleetForm(!showFleetForm);
  };

  const options = [
    { value: "Puma", label: "Puma" },
    { value: "9M-AC", label: "9M AC" },
    { value: "9M-NON-AC", label: "9M NON-AC" },
    { value: "12M-AC", label: "12M AC" },
    { value: "12M-NON-AC", label: "12M NON-AC" },
  ];

  const handleChange = (selectedOptions) => {
    setSelectedVehicleTypes(selectedOptions);
    // You can perform additional actions here based on the selected options
  };
  const handleEdit = (index) => {
    const fleetToEdit = fleets[index];
    setFleetName(fleetToEdit.fleetName);
    setContactNumber(fleetToEdit.contactNumber);
    setSelectedVehicleTypes(
      fleetToEdit.vehicleTypes.map((type) => ({ value: type, label: type }))
    );
    setShowFleetForm(true);
    setEditingFleetIndex(index);
  };

  const handleDelete = (index) => {
    if (window.confirm("Are you sure you want to delete this fleet?")) {
      setFleets(fleets.filter((_, i) => i !== index));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      fleetName,
      contactNumber,
      vehicleTypes: selectedVehicleTypes.map((option) => option.value),
      createdBy: "EKA_Admin",
      createdOn: new Date().toLocaleDateString(),
    };

    if (editingFleetIndex !== null) {
      // Editing existing fleet
      const updatedFleets = [...fleets];
      updatedFleets[editingFleetIndex] = formData;
      setFleets(updatedFleets);
      setEditingFleetIndex(null);
    } else {
      // Adding new fleet
      setFleets([...fleets, formData]);
    }

    // Reset form fields
    setFleetName("");
    setContactNumber("");
    setSelectedVehicleTypes([]);
    setShowFleetForm(false);
  };

  const handleVehicleClick = () => {
    setShowVehicleForm(!showVehicleForm);
  };

  const handleVehicleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      modelName,
      chassisNumber,
      vrn,
      fleet: selectedFleet.value,
      vehicleType: selectedVehicleType.value,
      createdBy: "EKA_Admin",
      createdOn: new Date().toLocaleDateString(),
    };
    console.log("Vehicle Form Data:", formData);

    if (editingVehicleIndex !== null) {
      // Editing existing vehicle
      const updatedVehicles = [...vehicles];
      updatedVehicles[editingVehicleIndex] = formData;
      setVehicles(updatedVehicles);
      setEditingVehicleIndex(null);
    } else {
      // Adding new vehicle
      setVehicles([...vehicles, formData]);
    }

    // Reset form fields
    setModelName("");
    setChassisNumber("");
    setVrn("");
    setSelectedFleet(null);
    setSelectedVehicleType(null);
    setShowVehicleForm(false);
  };

  const getFleetOptions = () => {
    return fleets.map((fleet) => ({
      value: fleet.fleetName,
      label: fleet.fleetName,
    }));
  };

  const getVehicleTypeOptions = () => {
    if (selectedFleet) {
      const fleet = fleets.find((f) => f.fleetName === selectedFleet.value);
      return fleet.vehicleTypes.map((type) => ({ value: type, label: type }));
    }
    return [];
  };

  const handleVehicleEdit = (index) => {
    const vehicleToEdit = vehicles[index];
    setModelName(vehicleToEdit.modelName);
    setChassisNumber(vehicleToEdit.chassisNumber);
    setVrn(vehicleToEdit.vrn);
    setSelectedFleet({
      value: vehicleToEdit.fleet,
      label: vehicleToEdit.fleet,
    });
    setSelectedVehicleType({
      value: vehicleToEdit.vehicleType,
      label: vehicleToEdit.vehicleType,
    });
    setShowVehicleForm(true);
    setEditingVehicleIndex(index);
  };

  const handleVehicleDelete = (index) => {
    if (window.confirm("Are you sure you want to delete this vehicle?")) {
      setVehicles(vehicles.filter((_, i) => i !== index));
    }
  };

  return (
    <>
      <div className="VH-header-container">
        <p>Fleet Management</p>
        <p style={{ textAlign : "center" , marginTop : "-58px"}}>Vehicle and Fleet</p>
      </div>
      <div className="VH-button-container">
        <button className="VH-Fleet-button" onClick={handleFleetClick}>
          {showFleetForm ? "Close Form" : "Add Fleet"}
        </button>
      </div>
      <div className="VH-Fleet-table-container">
        <table>
          <thead>
            <tr>
              <th>Fleet</th>
              <th>Created By</th>
              <th>Created On</th>
              <th>Vehicles</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {fleets.map((fleet, index) => (
              <tr key={index}>
                <td>{fleet.fleetName}</td>
                <td>{fleet.createdBy}</td>
                <td>{fleet.createdOn}</td>
                <td>{fleet.vehicleTypes.join(", ")}</td>
                <td>
                  <img
                    src={deleteIcon}
                    alt="Delete"
                    style={{
                      width: "15px",
                      height: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDelete(index)}
                  />
                  <img
                    src={editIcon}
                    alt="Edit"
                    style={{
                      marginLeft: "20px",
                      width: "15px",
                      height: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEdit(index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showFleetForm && (
        <form className="VH-Fleet-form" onSubmit={handleSubmit}>
          <br />
          <label>
            Fleet
            <input
              type="text"
              name="name"
              placeholder="Enter Fleet Name"
              style={{ marginLeft: "120px" }}
              value={fleetName}
              onChange={(e) => setFleetName(e.target.value)}
            />
          </label>
          <br />
          <label>
            Contact
            <input
              type="tel"
              name="mobile"
              placeholder="Mobile number"
              required
              maxLength="10"
              pattern="[0-9]{10}"
              inputMode="numeric"
              value={contactNumber}
              onChange={(e) =>
                setContactNumber(e.target.value.replace(/[^0-9]/g, ""))
              }
            />
          </label>
          <br />
          <br />
          <label>
            Vehicle type
            <Select
              isMulti
              options={options}
              className="VH-multi-select"
              isSearchable={false}
              placeholder="Select Vehicle Type"
              onChange={handleChange}
            />
          </label>
          <br />
          <button type="submit">
            {editingFleetIndex !== null ? "Update" : "Create"}
          </button>
        </form>
      )}

      <hr className="VH-separator" />

      <div className="VH-button-container">
        <button className="VH-Vehicle-button" onClick={handleVehicleClick}>
          {showVehicleForm ? "Close Form" : "Add Vehicle"}
        </button>
      </div>
      <div className="VH-Vehicle-table-container">
        <table>
          <thead>
            <tr>
              <th>Model Name</th>
              <th>Chassis Number</th>
              <th>VRN</th>
              <th>Fleet</th>
              <th>Vehicle Type</th>
              <th>Created By</th>
              <th>Created On</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {vehicles.map((vehicle, index) => (
              <tr key={index}>
                <td>{vehicle.modelName}</td>
                <td>{vehicle.chassisNumber}</td>
                <td>{vehicle.vrn}</td>
                <td>{vehicle.fleet}</td>
                <td>{vehicle.vehicleType}</td>
                <td>{vehicle.createdBy}</td>
                <td>{vehicle.createdOn}</td>
                <td>
                  {/* <img src="" */}
                  <img
                    src={deleteIcon}
                    alt="Delete"
                    style={{
                      width: "15px",
                      height: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleVehicleDelete(index)}
                  />
                  <img
                    src={editIcon}
                    alt="Edit"
                    style={{
                      marginLeft: "20px",
                      width: "15px",
                      height: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleVehicleEdit(index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showVehicleForm && (
        <form className="VH-Vehicle-form" onSubmit={handleVehicleSubmit}>
          <label>
            Model Name
            <input
              type="text"
              value={modelName}
              onChange={(e) => setModelName(e.target.value)}
              required
            />
          </label>
          <br />
          <label>
            Chassis Number
            <input
              type="text"
              value={chassisNumber}
              onChange={(e) => setChassisNumber(e.target.value)}
              required
            />
          </label>
          <br />
          <label>
            VRN
            <input
              type="text"
              value={vrn}
              onChange={(e) => setVrn(e.target.value)}
              required
            />
          </label>
          <br />
          <label>
            Fleet
            <select
              value={selectedFleet ? selectedFleet.value : ""}
              onChange={(e) => {
                const selectedOption = getFleetOptions().find(
                  (option) => option.value === e.target.value
                );
                setSelectedFleet(selectedOption || null);
              }}
              required
            >
              <option value="" disabled>
                Select Fleet
              </option>
              {getFleetOptions().map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </label>
          <br />
          <label>
            Vehicle Type
            <select
              value={selectedVehicleType ? selectedVehicleType.value : ""}
              onChange={(e) => {
                const selectedOption = getVehicleTypeOptions().find(
                  (option) => option.value === e.target.value
                );
                setSelectedVehicleType(selectedOption || null);
              }}
              required
            >
              <option value="">Select Vehicle Type</option>
              {getVehicleTypeOptions().map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </label>
          <br />
          <button type="submit">Add Vehicle</button>
        </form>
      )}
    </>
  );
}

export default VehicleAndFleet;
