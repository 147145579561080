import React, { useState } from "react";
import Select from "react-select";
import "./AlarmsAndFaults.css";
import { data } from "../DropdownData";
import editIcon from "../../assets/edit.png";
import deleteIcon from "../../assets/delete.png";

function AlarmsAndFaults() {
  const [isFormVisible, setFormVisible] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [alarms, setAlarms] = useState([]);
  const [formData, setFormData] = useState({
    type: "",
    limit: "",
    email: "",
    mobileNumber: "",
    severity: "High",
  });

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  const groupOptions = data.fleetOwners[0].group.map((group) => ({
    value: group.name,
    label: group.name,
  }));

  const modelOptions = selectedGroups.flatMap(
    (group) =>
      data.fleetOwners[0].group
        .find((g) => g.name === group.value)
        ?.models.map((model) => ({
          value: model,
          label: model,
        })) || []
  );

  // Add an option for selecting all models
  const allModelsOption = {
    value: "all",
    label: "Select All",
  };

  const handleGroupChange = (selected) => {
    setSelectedGroups(selected);
    setSelectedVehicles([]); // Clear selected vehicles when groups change
  };

  const handleVehicleChange = (selected) => {
    if (selected && selected.some((option) => option.value === "all")) {
      // If "Select All" is selected, select all models
      setSelectedVehicles(modelOptions);
    } else {
      setSelectedVehicles(selected);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newAlarm = {
      id: formData.id || Date.now(),
      alarmFault: formData.type,
      severity: formData.severity,
      assignedTo: formData.email,
      createdBy: "Eka_Admin",
      createdOn: formData.id
        ? alarms.find((a) => a.id === formData.id).createdOn
        : new Date().toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "2-digit",
          }),
      ...formData,
      selectedGroups: selectedGroups.map((group) => group.value),
      selectedVehicles: selectedVehicles.map((vehicle) => vehicle.value),
    };

    console.log(newAlarm);

    if (formData.id) {
      // Updating existing alarm
      setAlarms(
        alarms.map((alarm) => (alarm.id === formData.id ? newAlarm : alarm))
      );
    } else {
      // Adding new alarm
      setAlarms([...alarms, newAlarm]);
    }

    setFormVisible(false);
    // Reset the form
    setFormData({
      type: "",
      limit: "",
      email: "",
      mobileNumber: "",
      severity: "High",
    });
    setSelectedGroups([]);
    setSelectedVehicles([]);
  };
  const handleEdit = (id) => {
    const alarmToEdit = alarms.find((alarm) => alarm.id === id);
    if (alarmToEdit) {
      setFormData({
        type: alarmToEdit.alarmFault,
        limit: alarmToEdit.limit,
        email: alarmToEdit.email,
        mobileNumber: alarmToEdit.mobileNumber,
        severity: alarmToEdit.severity,
      });
      setSelectedGroups(
        alarmToEdit.selectedGroups.map((group) => ({
          value: group,
          label: group,
        }))
      );
      setSelectedVehicles(
        alarmToEdit.selectedVehicles.map((vehicle) => ({
          value: vehicle,
          label: vehicle,
        }))
      );
      setFormVisible(true);
    }
  };
  const handleDelete = (id) => {
    setAlarms(alarms.filter((alarm) => alarm.id !== id));
  };

  return (
    <>
      <div className="AF-header-container">
        <p>Fleet Management</p>
        <p style={{ textAlign : "center" , marginTop : "-58px"}}>Alarms And Faults</p>
      </div>
      <button className="AF-button" onClick={toggleFormVisibility}>
        {isFormVisible ? "Close Form" : "Create Form"}
      </button>
      <div className="AF-table-container">
        <table>
          <thead>
            <tr>
              <th>Alarm/Fault</th>
              <th>Severity</th>
              <th>Assigned to</th>
              <th>Created By</th>
              <th>Created On</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {alarms.map((alarm) => (
              <tr key={alarm.id}>
                <td>{alarm.alarmFault}</td>
                <td>{alarm.severity}</td>
                <td>{alarm.assignedTo}</td>
                <td>{alarm.createdBy}</td>
                <td>{alarm.createdOn}</td>
                <td>
                  <img
                    src={deleteIcon}
                    alt="Delete"
                    style={{
                      width: "15px",
                      height: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDelete(alarm.id)}
                  />
                  <img
                    src={editIcon}
                    alt="Edit"
                    style={{
                      marginLeft: "20px",
                      width: "15px",
                      height: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEdit(alarm.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isFormVisible && (
        <form className="AF-form" onSubmit={handleSubmit}>
          <label>
            Type
            <input
              type="text"
              name="type"
              placeholder="Choose"
              value={formData.type}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
            Set Limit
            <input
              type="text"
              name="limit"
              style={{ marginLeft: "58px" }}
              value={formData.limit}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
            Notification
            <input
              type="email"
              name="email"
              placeholder="Enter Email"
              style={{ marginLeft: "33px", width: "185px" }}
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type="tel"
              name="mobileNumber"
              placeholder="Enter Mobile Number"
              style={{ marginLeft: "90px", width: "185px" }}
              value={formData.mobileNumber}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
            Severity
            <select
              name="severity"
              value={formData.severity}
              onChange={handleChange}
            >
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </select>
          </label>
          <br />
          <br />
          <label>
            Group
            <Select
              isMulti
              className="AF-select-group"
              options={groupOptions}
              value={selectedGroups}
              isSearchable={false}
              onChange={handleGroupChange}
            />
          </label>
          <br />
          <br />
          <label>
            Vehicle
            <Select
              isMulti
              className="AF-select-model"
              options={[allModelsOption, ...modelOptions]}
              value={selectedVehicles}
              isSearchable={false}
              onChange={handleVehicleChange}
            />
          </label>
          <br />
          <button type="submit">Create Alarm</button>
        </form>
      )}
    </>
  );
}

export default AlarmsAndFaults;
