import React, { useState, useRef } from "react";
import './Profile.css';  

const Profile = () => { 
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null); // State to hold the uploaded images 
  const fileInputRef = useRef(null); // Reference to the file input

  const displayProfileSettings = () => {    
    setContent(
      <div className="manage-section">
        <div className="profile-upload" onClick={() => fileInputRef.current.click()}>
          <div 
            className="upload-circle" 
            style={{ 
              backgroundImage: image ? `url(${image})` : 'none', // Set background image
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }} 
          >
            {!image && "upload"} {/* Show "upload" text if no image */}
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }} // Hide the file input
            accept="image/*"
            onChange={handleFileChange} // Handle file selection
          />
        </div>
        <div className="manage-profile">
          <label htmlFor="username">Username:</label>
          <input type="text" id="username" className="form-control" placeholder="Enter Username" />
          
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" className="form-control" placeholder="Enter Email" />
          
          <label htmlFor="phone">Phone Number:</label>
          <input type="text" id="phone" className="form-control" placeholder="Enter Phone Number" />
          
          <label htmlFor="password">Password:</label>
          <input type="password" id="password" className="form-control" placeholder="Enter New Password" />

          <label htmlFor="password">New Password:</label>
          <input type="password" id="confirmPassword" className="form-control" placeholder="Confirm New Password" />
          
          <button onClick={saveProfile} className="s_password">Edit</button>
        </div>
      </div>
    );
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Set the image state to the uploaded file's data URL
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  const saveProfile = () => {
    alert("Profile Editing saved!");
    setContent("");
  };

  return (
    <>
      <div className="profile-header">
        <p>Settings</p>
        <p style={{ textAlign : "center" , marginTop : "-58px"}}>Profile</p>
      </div>
     
      <div id="content-container" style={{ marginTop: "20px" }}>
        {content || displayProfileSettings()}
      </div>
    </>
  );
};

export default Profile;