import React, { useState } from "react";
import Select from "react-select"; // Importing the Select component
import "./FOTA.css";
import { data } from "./DropdownData";

function FOTA() {
  const [selectedModels, setSelectedModels] = useState([]);

  // Create options for the model select dropdown
  const modelOptions = data.fleetOwners[0].group.reduce((acc, group) => {
    const models = group.models.map((model) => ({
      value: model,
      label: model,
    }));
    return acc.concat(models);
  }, []);

  // Function to handle the Flash button click
  const handleFlashClick = (event) => {
    event.preventDefault(); // Prevent the default form submission
    alert("Flashing successful"); // Show the alert pop-up
  };

  return (
    <>
      <div className="FOTA-header-container">
        <p>Firmware Over the Air Updates</p>
      </div>
      <div className="FOTA-form">
        <form>
          <label>
            Fleet User
            <select>
              <option value="">Select Fleet Owner</option>
              {data.fleetOwners.map((owner, index) => (
                <option key={index} value={owner.name}>
                  {owner.name}
                </option>
              ))}
            </select>
          </label>
          <br />
          <br />
          <label>
            Software Version
            <input type="file" accept=".srz" />
          </label>
          <br />
          <br />
          <label>
            Vehicle type
            <select style={{ marginLeft: "182px" }}>
              <option value="">Select Vehicle Type</option>
              <option value="9m-AC">9M AC</option>
              <option value="9m-Non-AC">9M NON AC</option>
              <option value="12M-AC">12M AC</option>
              <option value="12M-NON-AC">12M NON AC</option>
              <option value="Puma">Puma</option>
            </select>
          </label>
          <br />
          <br />
          <br />
          <label>
            Model Name
            <Select
              className="FOTA-Model-Name"
              isMulti
              isSearchable={false}
              options={modelOptions}
              value={selectedModels}
              onChange={setSelectedModels}
            />
          </label>
          <br />
          <br />
          <button onClick={handleFlashClick}>Flash</button>
        </form>
      </div>
    </>
  );
}

export default FOTA;