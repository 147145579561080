import React, { useEffect, useRef, useState } from "react";
import { data } from "../Pages/DropdownData"; // Import the data object
import "daterangepicker/daterangepicker.css";
import "daterangepicker";
import moment from "moment";
import $ from "jquery";
import "./Reports.css";

function Reports() {
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedReportType, setSelectedReportType] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState({});
  const [showCheckboxes, setShowCheckboxes] = useState(false); // New state for checkbox visibility
  const dateRangeRef = useRef(null);
  const tableRef = useRef(null);

  const getReportTitle = (type) => {
    switch (type) {
      case "Can_report":
        return "CAN Report";
      case "Fault_report":
        return "Fault Report";
      case "Hv_report":
        return "HV Report";
      default:
        return "Report";
    }
  };

  const handleClick = () => {
    const csvData = [];
    const visibleColumns = tableColumns.filter((column) => selectedColumns[column]);
    csvData.push(visibleColumns.join(","));
    tableData.forEach((row) => {
      const rowData = [];
      visibleColumns.forEach((column) => {
        rowData.push(row[column]);
      });
      csvData.push(rowData.join(",")); // Push the rowData to csvData
    });
    const blob = new Blob([csvData.join("\n")], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${selectedReportType}.csv`;
    a.click();
  };

  useEffect(() => {
    if (dateRangeRef.current) {
      const maxSpan =
        selectedReportType === "Can_report" ? { days: 3 } : { days: 7 };
      $(dateRangeRef.current).daterangepicker(
        {
          startDate: moment().subtract(7, "days"),
          endDate: moment(),
          opens: "left",
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 3 Days": [moment().subtract(2, "days"), moment()],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
          },
          maxSpan: maxSpan,
          maxDate: moment(),
        },
        function (start, end) {
          console.log(
            "Selected date range: ",
            start.format("YYYY-MM-DD"),
            end.format("YYYY-MM-DD")
          );
          fetchData(
            start.format("YYYY-MM-DD"),
            end.format("YYYY-MM-DD"),
            selectedModel
          );
        }
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReportType, selectedModel]);

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  };

  const handleReportTypeChange = (event) => {
    setSelectedReportType(event.target.value);
  };

  const handleColumnSelectChange = (column) => {
    setSelectedColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const handleToggleCheckboxes = () => {
    setShowCheckboxes((prev) => !prev); // Toggle the visibility
  };

  const filteredModels = data.fleetOwners[0].group.find(
    (group) => group.name === selectedGroup
  )?.models;

  const fetchData = async (startDate, endDate, selectedModel) => {
    let url;
    const formattedDate = startDate.replace(/-/g, "/");
    const formattedModel = selectedModel.replace(/-/, "").toLowerCase();
    console.log(formattedModel);
    switch (selectedReportType) {
      case "Hv_report":
        url = `https://eka-connect.com/Report/Hv_report/?date=${formattedDate}`;
        break;
      case "Can_report":
        url = `https://eka-connect.com/Report/Can_report/?vehicle=${formattedModel}&date=${formattedDate}`;
        break;
      case "Fault_report":
        url = `https://eka-connect.com/Report/Fault_report/?date=${formattedDate}`;
        break;
      default:
        return;
    }
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0) {
        if (typeof data[0] === "object" && data[0] !== null) {
          const columns = Object.keys(data[0]);
          setTableColumns(columns);
          setTableData(data);
          // Initialize selected columns state
          const initialSelectedColumns = {};
          columns.forEach((column) => {
            initialSelectedColumns[column] = true; // Select all columns by default
          });
          setSelectedColumns(initialSelectedColumns);
        } else {
          console.log("Invalid data format");
          return;
        }
      } else {
        console.log("No data available");
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="report-header-container">
        <p>Reports</p>
      </div>
      <div className="report-selection-menu">
        <p className="report-title">Report</p>
        <select
          className="report-dropdown-type"
          onChange={handleReportTypeChange}
          value={selectedReportType}
        >
          <option value="">Select Report Type</option>
          <option value="Can_report">CAN Report</option>
          <option value="Fault_report">Fault Report</option>
          <option value="Hv_report">HV Report</option>
        </select>

        {selectedReportType === "Can_report" && (
          <>
            <p className="report-title">Fleet Owner</p>
            <select className="report-dropdown-owner">
              <option value="">Select Fleet Owner</option>
              {data.fleetOwners.map((owner, index) => (
                <option key={index} value={owner.name}>
                  {owner.name}
                </option>
              ))}
            </select>

            <p className="report-title">Group</p>
            <select
              className="report-dropdown-group"
              value={selectedGroup}
              onChange={handleGroupChange}
            >
              <option value="">Select Group</option>
              {data.fleetOwners[0].group.map((group, index) => (
                <option key={index} value={group.name}>
                  {group.name}
                </option>
              ))}
            </select>

            <p className="report-title">Model</p>
            <select
              className="report-dropdown-model"
              value={selectedModel}
              onChange={handleModelChange}
            >
              <option value="">Select Model</option>
              {filteredModels?.map((model, index) => (
                <option key={index} value={model}>
                  {model}
                </option>
              ))}
            </select>
          </>
        )}

        <p className="report-title">Date Range</p>
        <input
          type="text"
          ref={dateRangeRef}
          className="date-range-picker"
          placeholder="Select Date Range"
          readOnly
        />
      </div>
      <button className="export-button" onClick={handleClick}>
        Export
      </button>
      <div className="report-table-container">
        <h2 className="report-table-header">
          {getReportTitle(selectedReportType)}
          <label className="report-table-column-selecter">
            <input
              type="checkbox"
              checked={showCheckboxes}
              onChange={handleToggleCheckboxes}
            />
            select columns
          </label>
        </h2>
        <hr />
        <table ref={tableRef}>
          <thead>
            <tr>
              {tableColumns.map((column, index) => (
                <th key={index}>
                  {showCheckboxes && (
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedColumns[column] || false}
                        onChange={() => handleColumnSelectChange(column)}
                      />
                    </label>
                  )}
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(tableData) ? (
              tableData.map((row, index) => (
                <tr key={index}>
                  {tableColumns.map((column, index) => (
                    <td key={index}>
                      {selectedColumns[column] ? row[column] : ""}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={tableColumns.length}>No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Reports;
