import React, { useState, useRef, useEffect } from "react";
import "../Summary/HealthMonitoring.css";
import { data } from "../DropdownData";
import ApexCharts from "apexcharts";

function HealthMonitoring() {
  const [selected, setSelected] = useState({
    owner: "",
    group: "",
    model: "",
  });
  const [date, setDate] = useState("");
  const [batteryCvoltageData, setBatteryCvoltageData] = useState(null);
  const [batteryTemperatureData, setBatteryTemperatureData] = useState(null);
  const [batteryPvoltageData, setBatteryPvoltageData] = useState(null);
  const [batteryPcurrentData, setBatteryPcurrentData] = useState(null);
  const [coolingPerformanceData, setCoolingPerformanceData] = useState(null);
  const [hvComponentTemperatureData, setHVComponentTemperatureData] =
    useState(null);
  const [dutyCycleData, setDutyCycleData] = useState(null);
  const [faultsData, setFaultsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const chartRef = useRef(null);
  const chartRefs = useRef({});
  const backendUrl = "https://eka-connect.com/"; // Update with your backend URL

  const handleOwnerChange = (event) => {
    const newOwner = event.target.value;
    setSelected({
      owner: newOwner,
      group: "", // Reset group and model when owner changes
      model: "",
    });
  };

  const handleGroupChange = (event) => {
    const newGroup = event.target.value;
    setSelected((prev) => ({
      ...prev,
      group: newGroup,
      model: "", // Reset model when group changes
    }));
  };

  const handleModelChange = (event) => {
    setSelected((prev) => ({
      ...prev,
      model: event.target.value,
    }));
  };

  const filteredModels = data.fleetOwners
    .find((owner) => owner.name === selected.owner)
    ?.group.find((group) => group.name === selected.group)?.models;

  const fetchCellVoltageData = async (formattedModel, formattedDate) => {
    const url = `${backendUrl}Battery/Cell_voltage/?vehicle=${formattedModel}&date=${formattedDate}`;
    const response = await fetch(url);

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData.error || "Data is unavailable";

      // Display the error message in each of the containers
      const containers = [
        "battery-monitoring-battery-cvoltage",
        "battery-monitoring-battery-temperature",
        "battery-monitoring-battery-pvoltage",
        "battery-monitoring-battery-pcurrent",
      ];

      containers.forEach((containerId) => {
        const container = document.getElementById(containerId);
        if (container) {
          container.textContent = errorMessage; // or use .innerText depending on your preference
        }
      });

      throw new Error(errorMessage); // Re-throw the error to propagate it if needed
    }

    return response.json();
  };

  const fetchCoolingPerformanceData = async (formattedModel, formattedDate) => {
    const url = `${backendUrl}Health_monitoring/Bcs_charts/?vehicle=${formattedModel}&date=${formattedDate}`;
    console.log(url);

    const response = await fetch(url);

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData.error || "Data is unavailable";

      // Display the error message in the specified container
      const container = document.getElementById(
        "healthMonitoring-Cperformance"
      );
      if (container) {
        container.textContent = errorMessage; // or .innerText depending on your preference
      }

      throw new Error(errorMessage); // Re-throw the error to propagate it if needed
    }

    return response.json();
  };

  const fetchHVComponentTemperatureData = async (
    formattedModel,
    formattedDate
  ) => {
    const url = `${backendUrl}Health_monitoring/Hv_monitoring/?vehicle=${formattedModel}&date=${formattedDate}`;
    console.log(url);

    const response = await fetch(url);

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData.error || "Data is unavailable";

      // Display the error message in the specified container
      const container = document.getElementById("healthMonitoring-hv");
      if (container) {
        container.textContent = errorMessage; // or .innerText depending on your preference
      }

      throw new Error(errorMessage); // Re-throw the error to propagate it if needed
    }

    return response.json();
  };

  const fetchDutyCycleData = async (formattedModel, formattedDate) => {
    const url = `${backendUrl}Health_monitoring/Bcs_charts/?vehicle=${formattedModel}&date=${formattedDate}`;
    console.log(url);

    const response = await fetch(url);

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData.error || "Data is unavailable";

      // Display the error message in the specified container
      const container = document.getElementById("healthMonitoring-DCycle");
      if (container) {
        container.textContent = errorMessage; // or .innerText depending on your preference
      }

      throw new Error(errorMessage); // Re-throw the error to propagate it if needed
    }

    return response.json();
  };

  const fetchFaultsData = async (formattedModel, formattedDate) => {
    const url = `${backendUrl}Trails/Individual_faults/?vehicle=${formattedModel}&date=${formattedDate}`;
    console.log(url);

    const response = await fetch(url);

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData.error || "Data is unavailable";

      // Display the error message in the specified container
      const container = document.getElementById("healthMonitoring-Faults");
      if (container) {
        container.textContent = errorMessage; // or .innerText depending on your preference
      }

      throw new Error(errorMessage); // Re-throw the error to propagate it if needed
    }

    return response.json();
  };

  const fetchDataForCharts = async () => {
    if (!selected.model || !date) {
      alert("Please select a model and date");
      return;
    }

    setLoading(true);
    try {
      const formattedDate = date.replace(/-/g, "/");
      const formattedModel = selected.model.replace(/-/g, "").toLowerCase();

      // Fetch cell voltage data
      try {
        const rawData = await fetchCellVoltageData(
          formattedModel,
          formattedDate
        );
        // Process the raw data for cell voltage
        const batteryCvoltageData = rawData.map((data) => ({
          A_Max_Cell_Volt_V: data.A_Max_Cell_Volt_V,
          B_Max_Cell_Volt_V: data.B_Max_Cell_Volt_V,
          A_Min_Cell_Volt_V: data.A_Min_Cell_Volt_V,
          B_Min_Cell_Volt_V: data.B_Min_Cell_Volt_V,
          Time: data.Time,
          A_SOC_Value_: data.A_SOC_Value_,
          B_SOC_Value_: data.B_SOC_Value_,
        }));
        setBatteryCvoltageData(batteryCvoltageData);
        const batteryTemperatureData = rawData.map((data) => ({
          AMinCellTemp: data.AMinCellTemp,
          BMinCellTemp: data.BMinCellTemp,
          AMaxCellTemp: data.AMaxCellTemp,
          BMaxCellTemp: data.BMaxCellTemp,
          Time: data.Time,
          A_SOC_Value_: data.A_SOC_Value_,
          B_SOC_Value_: data.B_SOC_Value_,
        }));
        setBatteryTemperatureData(batteryTemperatureData);

        const batteryPvoltageData = rawData.map((data) => ({
          A_Pack_Voltage_Value: data.A_Pack_Voltage_Value,
          B_Pack_Voltage_Value_V: data.B_Pack_Voltage_Value_V,
          Time: data.Time,
        }));
        setBatteryPvoltageData(batteryPvoltageData);

        const batteryPcurrentData = rawData.map((data) => ({
          A_Pack_Current_Value_A: data.A_Pack_Current_Value_A,
          B_Pack_Current_Value_A: data.B_Pack_Current_Value_A,
          Time: data.Time,
          A_SOC_Value_: data.A_SOC_Value_,
          B_SOC_Value_: data.B_SOC_Value_,
        }));
        setBatteryPcurrentData(batteryPcurrentData);
      } catch (error) {
        console.error("Error fetching cell voltage data:", error);
        // Handle specific error for cell voltage data if needed
      }

      // Fetch cooling performance data
      try {
        const coolingPerformanceData = await fetchCoolingPerformanceData(
          formattedModel,
          formattedDate
        );
        setCoolingPerformanceData(coolingPerformanceData);
      } catch (error) {
        console.error("Error fetching cooling performance data:", error);
        // Handle specific error for cooling performance data if needed
      }

      // Fetch HV component temperature data
      try {
        const hvComponentTemperatureData =
          await fetchHVComponentTemperatureData(formattedModel, formattedDate);
        setHVComponentTemperatureData(hvComponentTemperatureData);
      } catch (error) {
        console.error("Error fetching HV component temperature data:", error);
        // Handle specific error for HV component temperature data if needed
      }

      // Fetch duty cycle data
      try {
        const dutyCycleData = await fetchDutyCycleData(
          formattedModel,
          formattedDate
        );
        setDutyCycleData(dutyCycleData);
      } catch (error) {
        console.error("Error fetching duty cycle data:", error);
        // Handle specific error for duty cycle data if needed
      }

      // Fetch faults data
      try {
        const faultsData = await fetchFaultsData(formattedModel, formattedDate);
        setFaultsData(faultsData);
      } catch (error) {
        console.error("Error fetching faults data:", error);
        // Handle specific error for faults data if needed
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      alert("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const createLoader = (chartContainer) => {
    const loaderContainer = document.createElement("div");
    loaderContainer.className = "loader-container";
    const loader = document.createElement("div");
    loader.className = "loader";
    loaderContainer.appendChild(loader);
    chartContainer.innerHTML = ""; // Clear the container
    chartContainer.appendChild(loaderContainer);
    return loaderContainer;
  };

  const renderCoolingPerformanceChart = (containerId, data) => {
    const container = document.getElementById(containerId);
    if (!data || !container || data.length === 0) {
      return; // Early exit if data is null, undefined, or empty
    }

    container.innerHTML = ""; // Clear previous chart

    const bcsChartOptions = {
      chart: {
        type: "line",
      },
      stroke: {
        width: 2,
      },
      colors: [
        "#6b7ff2", // A Max Cell Temperature
        "#d64549", // B Max Cell Temperature
        "#A8B2F1", // A Min Cell Temperature
        "#f7888c", // B Min Cell Temperature
        "#F9A103", // Thermistor 1
        "#EFFD04", // Thermistor 2
        "#34F793", // Compressor Input Current
        "#E171F5", // Pump Current
      ],
      series: [
        {
          name: "A Max Cell Temperature",
          data: data.map((item) => item.AMaxCellTemp || 0),
        },
        {
          name: "B Max Cell Temperature",
          data: data.map((item) => item.BMaxCellTemp || 0),
        },
        {
          name: "A Min Cell Temperature",
          data: data.map((item) => item.AMinCellTemp || 0),
        },
        {
          name: "B Min Cell Temperature",
          data: data.map((item) => item.BMinCellTemp || 0),
        },
        {
          name: "Thermistor 1",
          data: data.map((item) => item.BCSThermistor1 || 0),
        },
        {
          name: "Thermistor 2",
          data: data.map((item) => item.BCSThermistor2 || 0),
        },
        {
          name: "Compressor Input Current",
          data: data.map((item) => item.BCScompressorinputcurrent || 0),
        },
        {
          name: "Pump Current",
          data: data.map((item) => item.PumpCurrent || 0),
        },
      ],
      xaxis: {
        categories: data.map((item) => {
          const timeParts = item.Time.split(":"); // Split the time string into parts
          const hour = parseInt(timeParts[0], 10);
          const minute = timeParts[1];
          const period = hour >= 12 ? "PM" : "AM"; // Determine AM or PM
          const formattedHour = hour % 12 || 12; // Convert to 12-hour format
          return `${formattedHour}:${minute} ${period}`; // Return formatted time with AM/PM
        }),
        labels: {
          show: true,
        },
        tooltip: {
          enabled: true,
        },
        tickAmount: 10, // Assuming you want a tick every hour in a 12-hour range
      },
      yaxis: {
        title: {
          text: "Temperature",
        },
      },
      legend: {
        position: "right",
        horizontalAlign: "center",
        floating: false,
        verticalAlign: "top",
        offsetX: 0,
        offsetY: 0,
        itemMargin: {
          horizontal: 5,
          vertical: 5,
        },
        scrollable: true,
        maxHeight: 300,
      },
    };

    const chart = new ApexCharts(container, bcsChartOptions);
    chart.render();
    chartRefs.current[containerId] = chart;
  };

  const renderDutyCycleChart = (containerId, data) => {
    const container = document.getElementById(containerId);
    if (!data || !container || data.length === 0) {
      return; // Early exit if data is null, undefined, or empty
    }

    container.innerHTML = ""; // Clear previous chart

    // Prepare the data for the series to ensure it starts from Off and only shows On
    const seriesData = [0]; // Start with Off (0)
    const tcsData = [0]; // Start with Off (0)

    // Populate the series data, starting from Off
    data.forEach((item) => {
      seriesData.push(item.BCScompressorinputcurrent > 1 ? 1 : 0); // 1 for On, 0 for Off
      tcsData.push(item.PumpCurrent > 1 ? 1 : 0); // 1 for On, 0 for Off
    });

    const bcsChartOptions = {
      chart: {
        type: "line",
      },
      stroke: {
        width: 2,
      },
      colors: [
        "#FF5733", // BCS Compressor
        "#33FF57", // TCS Pump
      ],
      series: [
        {
          name: "BCS Compressor",
          data: seriesData,
        },
        {
          name: "TCS Pump",
          data: tcsData,
        },
      ],
      xaxis: {
        categories: ["Off"].concat(
          data.map((item) => {
            const timeParts = item.Time.split(":"); // Split the time string into parts
            const hour = parseInt(timeParts[0], 10);
            const minute = timeParts[1];
            const period = hour >= 12 ? "PM" : "AM"; // Determine AM or PM
            const formattedHour = hour % 12 || 12; // Convert to 12-hour format
            return `${formattedHour}:${minute} ${period}`; // Return formatted time with AM/PM
          })
        ),
        labels: {
          show: true,
        },
        tooltip: {
          enabled: true,
        },
        tickAmount: 10, // You can adjust this value based on your data's time range
      },
      yaxis: {
        min: 0, // Set minimum value for Y-axis to 0 (Off)
        max: 1, // Set maximum value for Y-axis to 1 (On)
        title: {
          text: "Status",
        },
        labels: {
          formatter: (value) => {
            return value === 1 ? "On" : ""; // Only show "On" for 1
          },
        },
        tickAmount: 2, // Ensure only "On" is displayed
      },
      legend: {
        position: "right",
        horizontalAlign: "center",
        floating: false,
        verticalAlign: "top",
        offsetX: 0,
        offsetY: 0,
        itemMargin: {
          horizontal: 5,
          vertical: 5,
        },
        scrollable: true,
        maxHeight: 10, // Set maxHeight to allow scrolling when the number of items is too large
      },
    };

    const chart = new ApexCharts(container, bcsChartOptions);
    chart.render();
    chartRefs.current[containerId] = chart;
  };

  const renderCellVoltageChart = (containerId, data) => {
    const container = document.getElementById(containerId);
    if (!data || !container || data.length === 0) {
      return;
    }

    container.innerHTML = ""; // Clear the container

    // Filter and prepare valid data
    const validData = data.filter(
      (item) => item.Time && !isNaN(new Date(item.Time).getTime())
    );
    if (validData.length === 0) return;

    const colorMapping = {
      A_Max_Cell_Volt_V: "#f5d90a",
      A_Min_Cell_Volt_V: "#ebe3ab",
      B_Max_Cell_Volt_V: "#8731f7",
      B_Min_Cell_Volt_V: "#b589f0",
      A_SOC_Value_: "#84f08f",
      B_SOC_Value_: "#baf026",
    };

    // Define three y-axes: one for A_SOC_Value_, one for B_SOC_Value_, and one for the voltage values
    const yaxis = [
      {
        title: { text: "A SOC (%)" },
        opposite: true, // A_SOC_Value_ on the left y-axis
      },
      {
        title: { text: "B SOC (%)" },
        opposite: true, // B_SOC_Value_ on the right y-axis
      },
      {
        title: { text: "Voltage (V)" },
        opposite: false, // Voltage values on the left y-axis, separate from SOC
      },
    ];

    // Create series for SOC and voltage data
    const series = [
      // SOC Values (A_SOC_Value_)
      {
        name: "A_SOC_Value_",
        data: validData
          .map((item) => {
            const time = new Date(item.Time).getTime();
            const value = item["A_SOC_Value_"];
            return !isNaN(time) && value !== null
              ? [time, parseFloat(value).toFixed(2)]
              : null;
          })
          .filter(Boolean),
        color: colorMapping["A_SOC_Value_"] || "#000000",
        yAxis: 0, // Assign to the first yAxis (A SOC)
      },

      // SOC Values (B_SOC_Value_)
      {
        name: "B_SOC_Value_",
        data: validData
          .map((item) => {
            const time = new Date(item.Time).getTime();
            const value = item["B_SOC_Value_"];
            return !isNaN(time) && value !== null
              ? [time, parseFloat(value).toFixed(2)]
              : null;
          })
          .filter(Boolean),
        color: colorMapping["B_SOC_Value_"] || "#000000",
        yAxis: 1, // Assign to the second yAxis (B SOC)
      },

      // Voltage Values (A_Max_Cell_Volt_V, A_Min_Cell_Volt_V, B_Max_Cell_Volt_V, B_Min_Cell_Volt_V)
      ...[
        "A_Max_Cell_Volt_V",
        "A_Min_Cell_Volt_V",
        "B_Max_Cell_Volt_V",
        "B_Min_Cell_Volt_V",
      ].map((key) => ({
        name: key,
        data: validData
          .map((item) => {
            const time = new Date(item.Time).getTime();
            const value = item[key];
            return !isNaN(time) && value !== null
              ? [time, parseFloat(value).toFixed(2)]
              : null;
          })
          .filter(Boolean),
        color: colorMapping[key] || "#000000",
        yAxis: 2, // Assign to the third yAxis (Voltage)
      })),
    ];

    const title = "Cell Voltage and SOC";

    const chartOptions = {
      chart: { type: "line", height: 300, zoom: { enabled: true, type: "x" } },
      title: { text: title, style: { fontFamily: "Manrope" } },
      xaxis: {
        type: "datetime",
        min: new Date().setHours(0, 0, 0, 0),
        max: Math.max(
          ...validData.map((item) => new Date(item.Time).getTime())
        ),
        tickAmount: 12,
        tickInterval: "2h",
      },
      yaxis: yaxis.map((axis) => ({
        ...axis,
        title: { ...axis.title, style: { fontFamily: "Manrope" } },
      })),
      tooltip: { shared: true, x: { format: "HH:mm:ss" } },
      series: series,
      stroke: { width: 2 },
      legend: {
        position: "right",
        horizontalAlign: "center",
        floating: false,
        verticalAlign: "top",
        itemMargin: { horizontal: 5, vertical: 5 },
        scrollable: true,
        maxHeight: 300,
      },
    };

    const chart = new ApexCharts(container, chartOptions);
    chart.render();
  };

  const renderBatteryTemperatureChart = (containerId, data) => {
    const container = document.getElementById(containerId);
    if (!data || !container || data.length === 0) {
      return;
    }

    container.innerHTML = "";

    const validData = data.filter(
      (item) => item.Time && !isNaN(new Date(item.Time).getTime())
    );
    if (validData.length === 0) return;

    const colorMapping = {
      AMaxCellTemp: "#6b7ff2",
      AMinCellTemp: "#A8B2F1",
      BMaxCellTemp: "#d64549",
      BMinCellTemp: "#f7888c",
      A_SOC_Value_: "#84f08f",
      B_SOC_Value_: "#baf026",
    };

    const yaxis = [
      {
        title: { text: "A SOC (%)" },
        opposite: true, // A_SOC_Value_ on the left y-axis
      },
      {
        title: { text: "B SOC (%)" },
        opposite: true, // B_SOC_Value_ on the right y-axis
      },
      {
        title: { text: "Temperature (°C)" },
        opposite: false, // Voltage values on the left y-axis, separate from SOC
      },
    ];
    const series = [
      // SOC Values (A_SOC_Value_)
      {
        name: "A_SOC_Value_",
        data: validData
          .map((item) => {
            const time = new Date(item.Time).getTime();
            const value = item["A_SOC_Value_"];
            return !isNaN(time) && value !== null
              ? [time, parseFloat(value).toFixed(2)]
              : null;
          })
          .filter(Boolean),
        color: colorMapping["A_SOC_Value_"] || "#000000",
        yAxis: 0, // Assign to the first yAxis (A SOC)
      },

      // SOC Values (B_SOC_Value_)
      {
        name: "B_SOC_Value_",
        data: validData
          .map((item) => {
            const time = new Date(item.Time).getTime();
            const value = item["B_SOC_Value_"];
            return !isNaN(time) && value !== null
              ? [time, parseFloat(value).toFixed(2)]
              : null;
          })
          .filter(Boolean),
        color: colorMapping["B_SOC_Value_"] || "#000000",
        yAxis: 1, // Assign to the second yAxis (B SOC)
      },

      // Voltage Values (A_Max_Cell_Volt_V, A_Min_Cell_Volt_V, B_Max_Cell_Volt_V, B_Min_Cell_Volt_V)
      ...["AMaxCellTemp", "AMinCellTemp", "BMaxCellTemp", "BMinCellTemp"].map(
        (key) => ({
          name: key,
          data: validData
            .map((item) => {
              const time = new Date(item.Time).getTime();
              const value = item[key];
              return !isNaN(time) && value !== null
                ? [time, parseFloat(value).toFixed(2)]
                : null;
            })
            .filter(Boolean),
          color: colorMapping[key] || "#000000",
          yAxis: 2, // Assign to the third yAxis (Voltage)
        })
      ),
    ];
    const title = "Battery Temperature and SOC";

    const chartOptions = {
      chart: { type: "line", height: 300, zoom: { enabled: true, type: "x" } },
      title: { text: title, style: { fontFamily: "Manrope" } },
      xaxis: {
        type: "datetime",
        min: new Date().setHours(0, 0, 0, 0),
        max: Math.max(
          ...validData.map((item) => new Date(item.Time).getTime())
        ),
        tickAmount: 12,
        tickInterval: "2h",
      },
      yaxis: yaxis.map((axis) => ({
        ...axis,
        title: { ...axis.title, style: { fontFamily: "Manrope" } },
      })),
      tooltip: { shared: true, x: { format: "HH:mm:ss" } },
      series: series,
      stroke: { width: 2 },
      legend: {
        position: "right",
        horizontalAlign: "center",
        floating: false,
        verticalAlign: "top",
        itemMargin: { horizontal: 5, vertical: 5 },
        scrollable: true,
        maxHeight: 300,
      },
    };

    const chart = new ApexCharts(container, chartOptions);
    chart.render();
  };

  const renderPackVoltageChart = (containerId, data) => {
    const container = document.getElementById(containerId);
    if (!data || !container || data.length === 0) {
      return;
    }

    container.innerHTML = "";

    const validData = data.filter(
      (item) => item.Time && !isNaN(new Date(item.Time).getTime())
    );
    if (validData.length === 0) return;

    const colorMapping = {
      A_Pack_Voltage_Value: "#ff7ac1",
      B_Pack_Voltage_Value_V: "#f7b6d2",
    };

    const series = Object.keys(validData[0])
      .filter((key) =>
        ["A_Pack_Voltage_Value", "B_Pack_Voltage_Value_V"].includes(key)
      )
      .map((key) => {
        return {
          name: key,
          data: validData
            .map((item) => {
              const time = new Date(item.Time).getTime();
              const value = item[key];
              return !isNaN(time) && value !== null
                ? [time, parseFloat(value).toFixed(2)]
                : null;
            })
            .filter(Boolean),
          color: colorMapping[key] || "#000000",
          yAxis: 0,
        };
      });

    const yaxis = [{ title: { text: "Pack Voltage" }, opposite: false }];

    const title = "Pack Voltage";

    const chartOptions = {
      chart: { type: "line", height: 300, zoom: { enabled: true, type: "x" } },
      title: { text: title, style: { fontFamily: "Manrope" } },
      xaxis: {
        type: "datetime",
        min: new Date().setHours(0, 0, 0, 0),
        max: Math.max(
          ...validData.map((item) => new Date(item.Time).getTime())
        ),
        tickAmount: 12,
        tickInterval: "2h",
      },
      yaxis: yaxis.map((axis) => ({
        ...axis,
        title: { ...axis.title, style: { fontFamily: "Manrope" } },
      })),
      tooltip: { shared: true, x: { format: "HH:mm:ss" } },
      series: series,
      stroke: { width: 2 },
      legend: {
        position: "right",
        horizontalAlign: "center",
        floating: false,
        verticalAlign: "top",
        itemMargin: { horizontal: 5, vertical: 5 },
        scrollable: true,
        maxHeight: 300,
      },
    };

    const chart = new ApexCharts(container, chartOptions);
    chart.render();
  };

  const renderPackCurrentChart = (containerId, data) => {
    const container = document.getElementById(containerId);
    if (!data || !container || data.length === 0) {
      return;
    }

    container.innerHTML = "";

    const validData = data.filter(
      (item) => item.Time && !isNaN(new Date(item.Time).getTime())
    );
    if (validData.length === 0) return;

    const colorMapping = {
      A_Pack_Current_Value_A: "#a923de",
      B_Pack_Current_Value_A: "#c4872b",
      A_SOC_Value_: "#84f08f",
      B_SOC_Value_: "#baf026",
    };

    const yaxis = [
      {
        title: { text: "A SOC (%)" },
        opposite: true, // A_SOC_Value_ on the left y-axis
      },
      {
        title: { text: "B SOC (%)" },
        opposite: true, // B_SOC_Value_ on the right y-axis
      },
      {
        title: { text: "Current (A)" },
        opposite: false, // Voltage values on the left y-axis, separate from SOC
      },
    ];

    const series = [
      // SOC Values (A_SOC_Value_)
      {
        name: "A_SOC_Value_",
        data: validData
          .map((item) => {
            const time = new Date(item.Time).getTime();
            const value = item["A_SOC_Value_"];
            return !isNaN(time) && value !== null
              ? [time, parseFloat(value).toFixed(2)]
              : null;
          })
          .filter(Boolean),
        color: colorMapping["A_SOC_Value_"] || "#000000",
        yAxis: 0, // Assign to the first yAxis (A SOC)
      },

      // SOC Values (B_SOC_Value_)
      {
        name: "B_SOC_Value_",
        data: validData
          .map((item) => {
            const time = new Date(item.Time).getTime();
            const value = item["B_SOC_Value_"];
            return !isNaN(time) && value !== null
              ? [time, parseFloat(value).toFixed(2)]
              : null;
          })
          .filter(Boolean),
        color: colorMapping["B_SOC_Value_"] || "#000000",
        yAxis: 1, // Assign to the second yAxis (B SOC)
      },

      // Voltage Values (A_Max_Cell_Volt_V, A_Min_Cell_Volt_V, B_Max_Cell_Volt_V, B_Min_Cell_Volt_V)
      ...["A_Pack_Current_Value_A", "B_Pack_Current_Value_A"].map((key) => ({
        name: key,
        data: validData
          .map((item) => {
            const time = new Date(item.Time).getTime();
            const value = item[key];
            return !isNaN(time) && value !== null
              ? [time, parseFloat(value).toFixed(2)]
              : null;
          })
          .filter(Boolean),
        color: colorMapping[key] || "#000000",
        yAxis: 2, // Assign to the third yAxis (Voltage)
      })),
    ];

    const title = "Pack Current and SOC";

    const chartOptions = {
      chart: { type: "line", height: 300, zoom: { enabled: true, type: "x" } },
      title: { text: title, style: { fontFamily: "Manrope" } },
      xaxis: {
        type: "datetime",
        min: new Date().setHours(0, 0, 0, 0),
        max: Math.max(
          ...validData.map((item) => new Date(item.Time).getTime())
        ),
        tickAmount: 12,
        tickInterval: "2h",
      },
      yaxis: yaxis.map((axis) => ({
        ...axis,
        title: { ...axis.title, style: { fontFamily: "Manrope" } },
      })),
      tooltip: { shared: true, x: { format: "HH:mm:ss" } },
      series: series,
      stroke: { width: 2 },
      legend: {
        position: "right",
        horizontalAlign: "center",
        floating: false,
        verticalAlign: "top",
        itemMargin: { horizontal: 5, vertical: 5 },
        scrollable: true,
        maxHeight: 300,
      },
    };

    const chart = new ApexCharts(container, chartOptions);
    chart.render();
  };

  const renderHVComponentTemperatureChart = (containerId, data) => {
    const container = document.getElementById(containerId);
    if (!data || !container) {
      console.error("No data or container found");
      return; // Early exit if data or container is null or undefined
    }

    // Check if data is an array
    if (!Array.isArray(data)) {
      console.error("Expected data to be an array, but received:", data);
      return; // Early exit if data is not an array
    }

    if (data.length === 0) {
      console.warn("No data available to render the chart");
      return; // Early exit if data is empty
    }

    container.innerHTML = ""; // Clear previous chart

    // Prepare the series data for each parameter
    const series = [
      { name: "A Min Cell Temperature", data: [], color: "#ebe3ab" }, // Red
      { name: "B Min Cell Voltage", data: [], color: "#b589f0" }, // Green
      { name: "A Max Cell Voltage", data: [], color: "#f5d90a" }, // Blue
      { name: "B Max Cell Voltage", data: [], color: "#8731f7" }, // Yellow
      { name: "MCU Motor", data: [], color: "#FF33A1" }, // Pink
      { name: "MCU", data: [], color: "#33FFF5" }, // Cyan
      { name: "SHIM INDUCTOR", data: [], color: "#FF8C33" }, // Orange
      { name: "TRANSFORMER", data: [], color: "#8C33FF" }, // Purple
      { name: "Primary MOSFET", data: [], color: "#33FF8C" }, // Light Green
      { name: "Secondary MOSFET", data: [], color: "#FF33D4" }, // Light Pink
      { name: "Ecomp Actual Heat Sink", data: [], color: "#FF3333" }, // Dark Red
      { name: "Steering Actual Heat Sink", data: [], color: "#33FF33" }, // Bright Green
      { name: "B Insulation Value", data: [], color: "#FFD433" }, // Gold
    ];

    // Populate the series with data
    data.forEach((item) => {
      const time = new Date(`1970-01-01T${item.Time}Z`).getTime(); // Convert time to timestamp
      series[0].data.push([time, item.AMinCell]);
      series[1].data.push([time, item.BMinCell]);
      series[2].data.push([time, item.AMaxCell]);
      series[3].data.push([time, item.BMaxCell]);
      series[4].data.push([time, item.MCUMotor]);
      series[5].data.push([time, item.MCU]);
      series[6].data.push([time, item.SHIMINDUCTOR]);
      series[7].data.push([time, item.TRANSFORMER]);
      series[8].data.push([time, item.Primarymosfet]);
      series[9].data.push([time, item.Secondarymosfet]);
      series[10].data.push([time, item.EcompActualHeatSink]);
      series[11].data.push([time, item.SteeringActualHeatSink]);
      series[12].data.push([time, item.BInsulationValue / 1000]);
    });

    const hvChartOptions = {
      chart: {
        type: "line",
        zoom: { enabled: true, type: "x" },
      },
      title: {
        align: "center",
        style: {
          fontSize: "15px",
          fontWeight: "bold",
          color: "#333",
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          formatter: (value) =>
            new Date(value).toLocaleTimeString([], {
              hour: "numeric",
              minute: "2-digit",
            }),
        },
        tickAmount: 8,
      },
      yaxis: [
        {
          title: {
            text: "Values",
          },
          labels: {
            formatter: (value) => value.toFixed(0),
          },
        },
      ],
      series: series,
      stroke: {
        width: 1.5,
      },
      tooltip: {
        shared: true,
        intersect: false,
        formatter: function (series) {
          let tooltipContent = "";
          const time = new Date(series[0].data[0][0]).toLocaleTimeString([], {
            hour: "numeric",
            minute: "2-digit",
          });

          tooltipContent += `<div>${time}</div>`;
          // Grouping the first five values in one row
          tooltipContent += "<div>";
          for (let i = 0; i < Math.min(5, series.length); i++) {
            tooltipContent += `<strong>${series[i].seriesName}: </strong>${series[i].data[0][1]}<br>`;
          }
          tooltipContent += "</div>";

          // Adding remaining values in a new row
          if (series.length > 5) {
            tooltipContent += "<div>";
            for (let i = 5; i < series.length; i++) {
              tooltipContent += `<strong>${series[i].seriesName}: </strong>${series[i].data[0][1]}<br>`;
            }
            tooltipContent += "</div>";
          }

          return tooltipContent;
        },
      },
      legend: {
        position: "right",
        horizontalAlign: "center",
        floating: false,
        verticalAlign: "top",
        itemMargin: {
          horizontal: 5,
          vertical: 5,
        },
        scrollable: true,
        maxHeight: 300, // Set maxHeight to allow scrolling when the number of items is too large
      },
    };

    const chart = new ApexCharts(container, hvChartOptions);
    chart.render();
    chartRefs.current[containerId] = chart;
  };

  const renderFaultsTable = (containerId, data, sdate) => {
    const container = document.getElementById(containerId);
    if (!data || !container || !data.faults || data.faults.length === 0) {
      return; // Early exit if data or faults are null, undefined, or empty
    }

    container.innerHTML = ""; // Clear previous content

    // Split faults into an array of individual fault strings
    const faultsArray = data.faults.split(",").map((fault) => fault.trim());

    // Create a table element
    const table = document.createElement("table");
    table.style.width = "100%";
    table.style.borderCollapse = "collapse";

    // Create table header
    const header = table.createTHead();
    const headerRow = header.insertRow(0);
    const headerCell1 = headerRow.insertCell(0);
    const headerCell2 = headerRow.insertCell(1);
    const headerCell3 = headerRow.insertCell(2);
    headerCell1.innerText = "Date";
    headerCell2.innerText = "Model";
    headerCell3.innerText = "Faults";
    headerCell1.style.fontWeight = "bold";
    headerCell2.style.fontWeight = "bold";
    headerCell3.style.fontWeight = "bold";
    headerCell1.style.fontFamily = "Shanti";
    headerCell2.style.fontFamily = "Shanti";
    headerCell3.style.fontFamily = "Shanti";
    headerCell1.style.color = "#255F92";
    headerCell2.style.color = "#255F92";
    headerCell3.style.color = "#255F92";

    // Create table body
    const body = table.createTBody();
    body.style.fontFamily = "Manrope";

    // Loop through faults and create rows
    faultsArray.forEach((fault, index) => {
      const row = body.insertRow();
      const cell1 = row.insertCell(0);
      const cell2 = row.insertCell(1);
      const cell3 = row.insertCell(2);

      // Only set the date and vehicle name for the first fault row, leave empty for subsequent ones
      cell1.innerText = index === 0 ? sdate : ""; // Only show date on the first row
      cell2.innerText = index === 0 ? data.vehicle_name : ""; // Only show model (vehicle name) on the first row
      cell3.innerText = fault; // Set the fault for each row
    });

    // Append the table to the container
    container.appendChild(table);
  };

  useEffect(() => {
    if (loading) {
      const loaderContainers = [
        "battery-monitoring-battery-cvoltage",
        "battery-monitoring-battery-temperature",
        "battery-monitoring-battery-pvoltage",
        "battery-monitoring-battery-pcurrent",
        "healthMonitoring-Cperformance",
        "healthMonitoring-hv",
        "healthMonitoring-DCycle",
        "healthMonitoring-Faults",
      ];

      loaderContainers.forEach((containerId) => {
        const container = document.getElementById(containerId);
        if (container) {
          createLoader(container);
        }
      });
    } else {
      const chartContainers = [
        "battery-monitoring-battery-cvoltage",
        "battery-monitoring-battery-temperature",
        "battery-monitoring-battery-pvoltage",
        "battery-monitoring-battery-pcurrent",
        "healthMonitoring-Cperformance",
        "healthMonitoring-hv",
        "healthMonitoring-DCycle",
        "healthMonitoring-Faults",
      ];

      chartContainers.forEach((containerId) => {
        const container = document.getElementById(containerId);
        if (container) {
          switch (containerId) {
            case "battery-monitoring-battery-cvoltage":
              if (batteryCvoltageData) {
                renderCellVoltageChart(containerId, batteryCvoltageData);
              }
              break;
            case "battery-monitoring-battery-temperature":
              if (batteryTemperatureData) {
                renderBatteryTemperatureChart(
                  containerId,
                  batteryTemperatureData
                );
              }
              break;
            case "battery-monitoring-battery-pvoltage":
              if (batteryPvoltageData) {
                renderPackVoltageChart(containerId, batteryPvoltageData);
              }
              break;
            case "battery-monitoring-battery-pcurrent":
              if (batteryPcurrentData) {
                renderPackCurrentChart(containerId, batteryPcurrentData);
              }
              break;
            case "healthMonitoring-Cperformance":
              if (coolingPerformanceData) {
                renderCoolingPerformanceChart(
                  containerId,
                  coolingPerformanceData
                );
              }
              break;
            case "healthMonitoring-hv":
              if (hvComponentTemperatureData) {
                renderHVComponentTemperatureChart(
                  containerId,
                  hvComponentTemperatureData
                );
              }
              break;
            case "healthMonitoring-DCycle":
              if (dutyCycleData) {
                renderDutyCycleChart(containerId, dutyCycleData);
              }
              break;
            case "healthMonitoring-Faults":
              const modelName = selected.model;
              if (faultsData) {
                renderFaultsTable(containerId, faultsData, date, modelName);
              }
              break;
            default:
              break;
          }
        }
      });
    }
    return () => {
      destroyCharts();
    };
  }, [
    loading,
    batteryCvoltageData,
    batteryTemperatureData,
    batteryPvoltageData,
    batteryPcurrentData,
    coolingPerformanceData,
    hvComponentTemperatureData,
    dutyCycleData,
    faultsData,
    date,
    selected.model,
  ]);

  const destroyCharts = () => {
    Object.values(chartRefs.current).forEach((chart) => {
      if (chart && typeof chart.destroy === "function") {
        chart.destroy();
      }
    });
    chartRefs.current = {};
  };

  return (
    <div ref={chartRef}>
      <div className="healthMonitoring-header-container">
        <p>Summary</p>
        <p style={{ textAlign: "center", marginTop: "-45px" }}>Health Monitoring</p>
      </div>
      <div className="healthMonitoring-selection-container">
        <p className="healthMonitoring-title">Fleet Owner</p>
        <select
          className="healthMonitoring-dropdown-owner"
          value={selected.owner}
          onChange={handleOwnerChange}
        >
          <option>Select Fleet Owner</option>
          {data.fleetOwners.map((owner, index) => (
            <option key={index} value={owner.name}>
              {owner.name}
            </option>
          ))}
        </select>

        <p className="healthMonitoring-title">Group</p>
        <select
          className="healthMonitoring-dropdown-group"
          value={selected.group}
          onChange={handleGroupChange}
        >
          <option value="">Select Group</option>
          {data.fleetOwners
            .find((owner) => owner.name === selected.owner)
            ?.group.map((group, index) => (
              <option key={index} value={group.name}>
                {group.name}
              </option>
            ))}
        </select>

        <p className="healthMonitoring-title">Model</p>
        <select
          className="healthMonitoring-dropdown-model"
          value={selected.model}
          onChange={handleModelChange}
        >
          <option value="">Select Model</option>
          {filteredModels?.map((model, index) => (
            <option key={index} value={model}>
              {model}
            </option>
          ))}
        </select>

        <p className="healthMonitoring-title">Date</p>
        <input
          type="date"
          className="healthMonitoring-date-picker"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
        <button
          className="healthMonitoring-submission"
          onClick={fetchDataForCharts}
        >
          Submit
        </button>
      </div>

      <div className="healthMonitoring-charts-container">
        <h2>Historical Diagnostics</h2>
        <h3>Battery</h3>
        <div className="healthMonitoring-battery-grid">
          <div
            id="battery-monitoring-battery-cvoltage"
            className="healthMonitoring-battery-cvoltage"
          />
          <div
            id="battery-monitoring-battery-temperature"
            className="healthMonitoring-battery-temperature"
          />
        </div>
        <div className="healthMonitoring-battery-grid">
          <div
            id="battery-monitoring-battery-pvoltage"
            className="healthMonitoring-battery-pvoltage"
          />
          <div
            id="battery-monitoring-battery-pcurrent"
            className="healthMonitoring-battery-pcurrent"
          />
        </div>
        <div className="healthMonitoring-battery-grid">
          <div className="healthMonitoring-container">
            <h3>Cooling Performance</h3>
            <div
              id="healthMonitoring-Cperformance"
              className="healthMonitoring-Cperformance"
            />
          </div>
          <div className="healthMonitoring-container">
            <h3>HV Component Temperature</h3>
            <div id="healthMonitoring-hv" className="healthMonitoring-hv" />
          </div>
        </div>
        <div className="healthMonitoring-battery-grid">
          <div className="healthMonitoring-container">
            <h3>Duty Cycle</h3>
            <div
              id="healthMonitoring-DCycle"
              className="healthMonitoring-DCycle"
            />
          </div>
          <div className="healthMonitoring-container">
            <h3>Faults</h3>
            <div
              id="healthMonitoring-Faults"
              className="healthMonitoring-Faults"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HealthMonitoring;
