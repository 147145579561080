// src/SidebarData.js
import liveIcon from "../assets/Live.png";
import summaryIcon from "../assets/Summary.png";
import maintenanceIcon from "../assets/Maintenance.png";
import reportIcon from "../assets/Reports.png";
import trailsIcon from "../assets/Trails.png";
import fleetIcon from "../assets/Fleet.png";
import settingsIcon from "../assets/Settings.png";
import FOTAIcon from "../assets/FOTA.png";
import HomeIcon from "../assets/Home.png";

export const SidebarData = [
  {
    title: "Home",
    path: "/Home",
    icon: HomeIcon,
    component: "Home",
    subNav: null,
  },
  /*  {
    title: "Live",
    path: "/live/fleet",
    icon: liveIcon,
    component: "Live",
    subNav: [
      { title: "Fleet", path: "/live/fleet" },
      { title: "Vehicle", path: "/live/vehicle" },
    ],
  },*/
  {
    title: "Summary",
    path: "/summary/fleet_movement",
    icon: summaryIcon,
    component: "Summary",
    subNav: [
      { title: "Fleet Movement", path: "/summary/fleet_movement" },
      { title: "Health Monitoring", path: "/summary/health_monitoring" },
    ],
  },
  {
    title: "Maintenance and Diagnostics",
    path: "/maintenance",
    icon: maintenanceIcon,
    component: "Maintenance",
    subNav: [
      { title: "Scheduler", path: "/maintenance/scheduler" },
      // { title: "VOR", path: "/maintenance/vor" },
    ],
  },
  {
    title: "Reports",
    path: "/reports",
    icon: reportIcon,
    component: "Reports",
    subNav: null,
  },
  {
    title: "Trails",
    path: "/trails",
    icon: trailsIcon,
    component: "Trails",
    subNav: null,
  },
  {
    title: "FOTA",
    path: "/FOTA",
    icon: FOTAIcon,
    component: "FOTA",
    subNav: null,
  },
  {
    title: "Fleet Management",
    path: "/fleet_management",
    icon: fleetIcon,
    component: "FleetManagement",
    subNav: [
      // { title: "Geofence", path: "/fleet_management/geofence" },
      {
        title: "Access Management",
        path: "/fleet_management/access_management",
      },
      { title: "Fleet User", path: "/fleet_management/fleet" },
      { title: "Alarms and Faults", path: "/fleet_management/alarms_faults" },
      {
        title: "Driver Management",
        path: "/fleet_management/driver_management",
      },
      { title: "Vehicle and Fleet", path: "/fleet_management/vehicle_fleet" },
    ],
  },
  {
    title: "Settings",
    path: "/settings",
    icon: settingsIcon,
    component: "Settings",
    subNav: [
      { title: "Profile", path: "/settings/profile" },
 
    ],
  },
];
