import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "./Home.css";
import Select, { components } from "react-select";
import L from "leaflet";
import ApexCharts from "react-apexcharts";
import Chart from "react-apexcharts";
import busIcon from "../assets/bus.png";
import pumaIcon from "../assets/Puma.png";
import speedOdometer from "../assets/Speedometer.png";
import DTEIcon from "../assets/RangeIcon.png";
import BatteryTempIcon from "../assets/BatteryTempIcon.png";
import BusImage from "../assets/Bus_9M.png";
import PumaImage from "../assets/EKA_Puma.png";

function Home() {
  const [vehicles, setVehicles] = useState([]);
  const [vehicleType, setVehicleType] = useState("All");
  const [fleetSize, setFleetSize] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [chargingCount, setChargingCount] = useState(0);
  const [filter, setFilter] = useState("all");
  const [showDetails, setShowDetails] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null); // State to store selected vehicle's data
  const mapRef = useRef();
  const [isDeepDischargeChartVisible, setIsDeepDischargeChartVisible] =
    useState(false);
  const [
    isInsufficientChargingChartVisible,
    setIsInsufficientChargingChartVisible,
  ] = useState(false);
  const [isChargingUnitsChartVisible, setIsChargingUnitsChartVisible] =
    useState(false);
  const [isDistanceVisible, setIsDistanceVisible] = useState(false);
  const [deepDischargeChartData, setDeepDischargeChartData] = React.useState({
    series: [{ name: "", data: [] }],
    categories: [],
  });

  const [insufficientChargingChartData, setInsufficientChargingChartData] =
    React.useState({ series: [{ name: "", data: [] }], categories: [] });

  /* Distance */
  const [isDistanceChartVisible, setIsDistanceChartVisible] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [isMonthSelectorVisible, setIsMonthSelectorVisible] = useState(false);
  const [, setData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [dailyData, setDailyData] = useState({});
  const [selectedDistanceMonth, setSelectedDistanceMonth] = useState(null);
  const [homeDisplayData, setHomeDisplayData] = useState({});
  //Distance Ends

  //Charging Starts
  const [chargingData, setChargingData] = useState([]);
  const [monthlyChargingData, setMonthlyChargingData] = useState([]);
  const [dailyChargingData, setDailyChargingData] = useState({});
  const [selectedChargingMonth, setSelectedChargingMonth] = useState(null);
  const [isChargingChartVisible, setIsChargingChartVisible] = useState(false);

  const fetchLiveHomeData = () => {
    const apiUrl = "https://eka-connect.com/Live/Live_stats/";
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setHomeDisplayData(data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    setHomeDisplayData({});
    fetchLiveHomeData();

    const intervalId = setInterval(fetchLiveHomeData, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseBus = await fetch(
          "https://eka-connect.com/Live/Live_summary/?model=Bus"
        );
        const dataBus = await responseBus.json();
        setVehicles(dataBus);
        const totalFleetSizeBus =
          dataBus.length -
          dataBus.filter(
            (vehicle) =>
              Number(vehicle.longitude) === 0.0 &&
              Number(vehicle.latitude) === 0.0
          ).length -
          dataBus.filter(
            (vehicle) =>
              vehicle.device_type === "Chota Hathi" ||
              vehicle.device_type === "Person"
          ).length;
        const activeVehiclesBus = dataBus.filter(
          (vehicle) =>
            vehicle.status === "Moving" ||
            (vehicle.status === "Idle" && vehicle.ChargerGunDetected2 === 0) ||
            (vehicle.status === "Stopped" && vehicle.ChargerGunDetected2 === 0)
        ).length;
        const inactiveVehiclesBus =
          dataBus.filter(
            (vehicle) =>
              (vehicle.status === "NOdata" &&
                vehicle.ChargerGunDetected2 === 0) ||
              vehicle.status === "Power off" ||
              vehicle.status === "NoGps" ||
              vehicle.status === "unknown"
          ).length -
          dataBus.filter(
            (vehicle) =>
              Number(vehicle.longitude) === 0.0 &&
              Number(vehicle.latitude) === 0.0
          ).length -
          dataBus.filter(
            (vehicle) =>
              vehicle.device_type === "Chota Hathi" ||
              vehicle.device_type === "Person"
          ).length;
        const chargingVehiclesBus = dataBus.filter(
          (vehicle) =>
            (vehicle.status === "Idle" && vehicle.ChargerGunDetected2 === 1) ||
            (vehicle.status === "Stopped" &&
              vehicle.ChargerGunDetected2 === 1) ||
            (vehicle.status === "NOdata" && vehicle.ChargerGunDetected2 === 1)
        ).length;

        // Update state for Bus model
        setFleetSize(totalFleetSizeBus);
        setActiveCount(activeVehiclesBus);
        setInactiveCount(inactiveVehiclesBus);
        setChargingCount(chargingVehiclesBus);

        // Fetch data for Puma model
        const responsePuma = await fetch(
          "https://eka-connect.com/Live/Live_summary/?model=Puma"
        );
        const dataPuma = await responsePuma.json();
        // dataPuma.map((vehicles) => {

        // })
        setVehicles((prevVehicles) => [...prevVehicles, ...dataPuma]);
        const totalFleetSizePuma = dataPuma.length;

        const activeVehiclesPuma = dataPuma.filter(
          (vehicle) => vehicle.wheel_based_vehicle_speed > 0
        ).length;

        const inactiveVehiclesPuma = dataPuma.filter(
          (vehicle) => vehicle.wheel_based_vehicle_speed === 0
        ).length;

        // Update state for Puma model
        setFleetSize((prevSize) => prevSize + totalFleetSizePuma);
        setActiveCount((prevCount) => prevCount + activeVehiclesPuma);
        setInactiveCount((prevCount) => prevCount + inactiveVehiclesPuma);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const filteredVehicles = vehicles.filter((vehicle) => {
    if (filter === "all") return true; // Show all vehicles if 'all' is selected
    if (filter === "active") {
      return (
        vehicle.status === "Moving" ||
        (vehicle.status === "Idle" && vehicle.ChargerGunDetected2 === 0) ||
        (vehicle.status === "Stopped" && vehicle.ChargerGunDetected2 === 0) ||
        vehicle.wheel_based_vehicle_speed > 0
      );
    }
    if (filter === "inactive") {
      return (
        (vehicle.status === "NOdata" && vehicle.ChargerGunDetected2 === 0) ||
        vehicle.status === "unknown" ||
        vehicle.status === "NoGps" ||
        vehicle.wheel_based_vehicle_speed === 0
      );
    }
    if (filter === "charging") {
      return (
        (vehicle.status === "Idle" && vehicle.ChargerGunDetected2 === 1) ||
        (vehicle.status === "Stopped" && vehicle.ChargerGunDetected2 === 1) ||
        (vehicle.status === "NOdata" && vehicle.ChargerGunDetected2 === 1)
      );
    }
    return true; // Fallback to 'all'
  });

  const handleVehicleTypeChange = (type) => {
    setVehicleType(type);
  };
  const deviceSorting = filteredVehicles.filter((vehicle) => {
    if (vehicleType === "Bus") {
      return vehicle.device_type;
    } else if (vehicleType === "Puma") {
      return !vehicle.device_type;
    } else {
      return true;
    }
  });
  function getBatteryColor(state_of_charge) {
    if (state_of_charge >= 75) {
      return "green"; // High charge - green
    } else if (state_of_charge >= 40) {
      return "yellow"; // Medium charge - yellow
    } else {
      return "red"; // Low charge - red
    }
  }

  //Depth Of Discharge

  const fetchDeepDischargeChartData = async (month) => {
    try {
      const response = await fetch(
        `https://eka-connect.com/Dashboard/Depth_of_Discharge_monthly/?month=${month}`
      );
      const data = await response.json();

      const transformedData = data.map((entry) => ({
        x: entry.Date.slice(8, 10), // Date as x-axis
        y: entry.Count, // Count as y-axis
        models: entry.Model || "Unknown", // Model names for hover tooltip
      }));

      setDeepDischargeChartData({
        series: [
          {
            name: "Depth of Discharge",
            data: transformedData,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching Deep Discharge chart data:", error);
    }
  };

  const deepDischargeTooltip = {
    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      const dataPoint = w.globals.series[seriesIndex][dataPointIndex];
      const date = w.globals.labels[dataPointIndex];
      const models = w.config.series[0].data[dataPointIndex].models;

      return `
         <div class="tooltip-container">
          <strong>Date:</strong> ${date} <br />
          <strong>Count:</strong> ${dataPoint} <br />
          <strong>Models:</strong> ${models}
        </div>
      `;
    },
  };

  //Depth Of Discharge Ends

  //Insuff Charge

  const fetchInsufficientChargingChartData = async (month) => {
    try {
      const response = await fetch(
        `https://eka-connect.com/Dashboard/Insufficent_charge_monthly/?month=${month}`
      );
      const data = await response.json();
      // Transform data to get counts by date
      const chartData = data.reduce((acc, curr) => {
        const { Date: date, Insufficient_Charging, Model } = curr;

        if (!acc[date]) {
          acc[date] = { count: 0, models: new Set() };
        }
        acc[date].count += Insufficient_Charging;
        acc[date].models.add(Model);

        return acc;
      }, {});

      // Sort dates in ascending order
      const sortedDates = Object.keys(chartData).sort(
        (a, b) => new Date(a) - new Date(b)
      );

      // Format data for ApexCharts
      const categories = sortedDates;
      const values = sortedDates.map((date) => chartData[date].count);
      const models = sortedDates.map((date) =>
        Array.from(chartData[date].models).join(", ")
      );

      setInsufficientChargingChartData({
        series: [{ name: "Insufficient Charging", data: values }],
        categories,
        models,
      });
    } catch (error) {
      console.error("Error fetching Insufficient Charging chart data:", error);
    }
  };

  //Insuff End's

  // Charging Units

  const fetchChargingUnitsData = async () => {
    return null;
  };

  //Charging Units Ends

  const toggleDeepDischargeChart = () => {
    setIsDeepDischargeChartVisible(true);
    setIsMonthSelectorVisible(true); // Show the month selector
  };

  const toggleInsufficientChargingChart = () => {
    setIsInsufficientChargingChartVisible(true);
    setIsMonthSelectorVisible(true);
  };

  const toggleChargingUnitsChart = () => {
    setIsChargingUnitsChartVisible(true);
  };

  const toggleDistanceChart = () => {
    setIsDistanceVisible(true);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleMonthSubmit = () => {
    if (selectedMonth) {
      fetchDeepDischargeChartData(selectedMonth);
    } else {
      alert("Please select a month.");
    }
  };
  const handleMonthSubmit1 = () => {
    if (selectedMonth) {
      fetchInsufficientChargingChartData(selectedMonth);
    } else {
      alert("Please select a month.");
    }
  };

  useEffect(() => {
    fetchChargingUnitsData();
  }, []);

  // Function to handle vehicle click
  const handleVehicleClick = (vehicle) => {
    setSelectedVehicle(vehicle); // Set the selected vehicle's data
  };

  // Function to handle back button click
  const handleBackClick = () => {
    setSelectedVehicle(null); // Reset selected vehicle data to close the details container
  };

  const vehicleOptions = filteredVehicles.map((vehicle) => ({
    value: vehicle.id,
    label: vehicle.registration_number || vehicle.vehicle_reg_num,
  }));

  const CustomMultiValue = (props) => {
    const { data, index, getValue } = props;
    // eslint-disable-next-line no-unused-vars
    const selectedValues = getValue();

    if (index >= 2) {
      return null; // Hide the extra items
    }

    return (
      <components.MultiValue {...props}>{data.label}</components.MultiValue>
    );
  };

  //Bus Distance Starts

  // Fetch data on component mount
  const fetchDistanceData = () => {
    fetch("https://eka-connect.com/Dashboard/Bus_distance/") // Replace with your actual API URL
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        processData(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  // Process the raw data into monthly and daily data
  const processData = (rawData) => {
    const monthly = {};
    const daily = {};

    rawData.forEach((item) => {
      const date = new Date(item.date);
      const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`;
      const dayKey = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;

      // Initialize monthly and daily data structures
      if (!monthly[monthKey]) {
        monthly[monthKey] = 0;
        daily[monthKey] = {};
      }

      // Sum the distance for the month
      monthly[monthKey] += item["distance"];

      // Store daily distances, grouped by device and group
      if (!daily[monthKey][dayKey]) {
        daily[monthKey][dayKey] = {};
      }

      if (!daily[monthKey][dayKey][item.Group]) {
        daily[monthKey][dayKey][item.Group] = 0;
      }

      // Add the distance to the correct group for the day
      daily[monthKey][dayKey][item.Group] += item["distance"];
    });

    // Prepare monthly data for chart (sum of distances for each month)
    const monthlyDataFormatted = Object.keys(monthly).map((monthKey) => ({
      month: monthKey,
      value: monthly[monthKey],
    }));

    setMonthlyData(monthlyDataFormatted);
    setDailyData(daily);
  };

  // Handle month click to show daily data
  const handleDataPointClick = (event, chartContext, config) => {
    const month = monthlyData[config.dataPointIndex].month; // Get selected month
    setSelectedDistanceMonth(month); // Set selected month for daily data
  };

  // Monthly chart options
  const monthlyChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      events: {
        dataPointSelection: handleDataPointClick,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    xaxis: {
      categories: monthlyData.map((item) => item.month),
    },
    dataLabels: {
      enabled: false, // **Disable data labels**
    },
  };

  // Monthly chart series
  const monthlyChartSeries = [
    {
      name: "Monthly Distance",
      data: monthlyData.map((item) => item.value),
    },
  ];

  // Daily chart options (Stacked Bar Chart)
  const dailyChartOptions = {
    chart: {
      type: "bar",
      height: 250,
    },
    plotOptions: {
      bar: {
        columnWidth: "70%",
        stacked: true,
      },
    },
    xaxis: {
      categories: selectedDistanceMonth
        ? Object.keys(dailyData[selectedDistanceMonth]).map((dayKey) => {
            const date = new Date(dayKey); // Convert string to Date object
            const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero if needed
            const month = date.toLocaleString("default", { month: "long" }); // Get full month name
            return `${day} ${month}`; // Format as "dd month year"
          })
        : [],
    },
    dataLabels: {
      enabled: false, // **Disable data labels**
    },
    legend: {
      position: "right", // Optional: You can place the legend at the top
    },
  };

  // Prepare the daily chart series for stacking
  const dailyChartSeries = selectedDistanceMonth
    ? Object.keys(
        dailyData[selectedDistanceMonth][
          Object.keys(dailyData[selectedDistanceMonth])[0]
        ]
      ) // Get all groups (e.g., MBMT, UMC)
        .map((group) => {
          return {
            name: group, // Name of the group (e.g., "MBMT", "UMC")
            data: Object.keys(dailyData[selectedDistanceMonth]).map(
              (dayKey) => {
                return dailyData[selectedDistanceMonth][dayKey][group] || 0; // Get the distance for that group, or 0 if not available
              }
            ),
          };
        })
    : [];

  const handleDistanceCharts = () => {
    fetchDistanceData();
    setIsDistanceChartVisible(true);
  };

  // /Bus Distance Ends

  // Puma Starts
  const [isPumaChartVisible, setIsPumaChartVisible] = useState(false);
  const [pumaRawData, setPumaRawData] = useState([]);
  const [pumaMonthlySummary, setPumaMonthlySummary] = useState([]);
  const [pumaDailyDetails, setPumaDailyDetails] = useState({});
  const [pumaSelectedMonthData, setPumaSelectedMonthData] = useState(null);

  const fetchPumaData = () => {
    fetch("https://eka-connect.com/Dashboard/Puma_distance/")
      .then((response) => response.json())
      .then((data) => {
        setPumaRawData(data);
        processPumaData(pumaRawData);
      })
      .catch((error) => console.error("Error fetching Puma data:", error));
  };

  const processPumaData = (data) => {
    const pumaMonthlyTotals = {};
    const pumaDailyBreakdown = {};

    data.forEach((entry) => {
      const date = new Date(entry.date);
      const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`;
      const dayKey = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;

      if (!pumaMonthlyTotals[monthKey]) {
        pumaMonthlyTotals[monthKey] = 0;
        pumaDailyBreakdown[monthKey] = {};
      }

      pumaMonthlyTotals[monthKey] += entry.distance;

      if (!pumaDailyBreakdown[monthKey][dayKey]) {
        pumaDailyBreakdown[monthKey][dayKey] = 0;
      }

      pumaDailyBreakdown[monthKey][dayKey] += entry.distance;
    });

    const formattedPumaMonthlySummary = Object.keys(pumaMonthlyTotals).map(
      (monthKey) => ({
        month: monthKey,
        totalDistance: pumaMonthlyTotals[monthKey],
      })
    );

    setPumaMonthlySummary(formattedPumaMonthlySummary);
    setPumaDailyDetails(pumaDailyBreakdown);
  };

  const handlePumaMonthSelection = (event, chartContext, config) => {
    const month = pumaMonthlySummary[config.dataPointIndex].month;
    setPumaSelectedMonthData(month);
  };

  const pumaMonthlyChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      events: {
        dataPointSelection: handlePumaMonthSelection,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    xaxis: {
      categories: pumaMonthlySummary.map((item) => item.month),
    },
    dataLabels: {
      enabled: false,
    },
  };

  const pumaMonthlyChartSeries = [
    {
      name: "Puma Total Monthly Distance",
      data: pumaMonthlySummary.map((item) => item.totalDistance),
    },
  ];

  const pumaDailyChartOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    xaxis: {
      categories: pumaSelectedMonthData
        ? Object.keys(pumaDailyDetails[pumaSelectedMonthData]).map((dayKey) => {
            const date = new Date(dayKey);
            const day = String(date.getDate()).padStart(2, "0");
            const month = date.toLocaleString("default", { month: "long" });
            return `${day} ${month}`;
          })
        : [],
    },
    dataLabels: {
      enabled: false,
    },
  };

  // Puma Daily chart series (same structure as monthly chart)
  const pumaDailyChartSeries = pumaSelectedMonthData
    ? [
        {
          name: "Puma Daily Distance",
          data: Object.keys(pumaDailyDetails[pumaSelectedMonthData]).map(
            (dayKey) => pumaDailyDetails[pumaSelectedMonthData][dayKey]
          ),
        },
      ]
    : [];

  const showPumaDistanceCharts = () => {
    fetchPumaData();
    setIsPumaChartVisible(true);
  };

  //Puma Ends Here

  //Charging Starts
  const fetchChargingData = () => {
    fetch("https://eka-connect.com/Dashboard/Bus_charging/") // Replace with your actual API URL
      .then((response) => response.json())
      .then((data) => {
        setChargingData(data);
        processChargingData(chargingData);
      })
      .catch((error) => console.error("Error fetching charging data:", error));
  };

  // Process the raw charging data into monthly and daily data
  const processChargingData = (rawData) => {
    const monthly = {};
    const daily = {};

    rawData.forEach((item) => {
      const date = new Date(item.start_date); // Convert start_date to Date object
      const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`;
      const dayKey = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;

      // Initialize monthly and daily data structures
      if (!monthly[monthKey]) {
        monthly[monthKey] = 0;
        daily[monthKey] = {};
      }

      // Sum the Units_ConsumedKwh for the month
      monthly[monthKey] += item["Units_ConsumedKwh"];

      // Store daily consumption, grouped by model and group
      if (!daily[monthKey][dayKey]) {
        daily[monthKey][dayKey] = {};
      }

      if (!daily[monthKey][dayKey][item.Group]) {
        daily[monthKey][dayKey][item.Group] = 0;
      }

      // Add the Units_ConsumedKwh to the correct group for the day
      daily[monthKey][dayKey][item.Group] += item["Units_ConsumedKwh"];
    });

    // Prepare monthly data for chart (sum of Units_ConsumedKwh for each month)
    const monthlyDataFormatted = Object.keys(monthly).map((monthKey) => ({
      month: monthKey,
      value: monthly[monthKey],
    }));

    setMonthlyChargingData(monthlyDataFormatted);
    setDailyChargingData(daily);
  };

  // Handle month click to show daily data
  const handleChargingDataPointClick = (event, chartContext, config) => {
    const month = monthlyChargingData[config.dataPointIndex].month; // Get selected month
    setSelectedChargingMonth(month); // Set selected month for daily data
  };

  // Monthly charging chart options
  const monthlyChargingChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      events: {
        dataPointSelection: handleChargingDataPointClick,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    xaxis: {
      categories: monthlyChargingData.map((item) => item.month),
    },
    dataLabels: {
      enabled: false, // Disable data labels
    },
  };

  // Monthly charging chart series
  const monthlyChargingChartSeries = [
    {
      name: "Monthly Consumption (kWh)",
      data: monthlyChargingData.map((item) => item.value),
    },
  ];

  // Daily charging chart options (Stacked Bar Chart)
  const dailyChargingChartOptions = {
    chart: {
      type: "bar",
      height: 250,
    },
    plotOptions: {
      bar: {
        columnWidth: "70%",
        stacked: true,
      },
    },
    xaxis: {
      categories: selectedChargingMonth
        ? Object.keys(dailyChargingData[selectedChargingMonth]).map(
            (dayKey) => {
              const date = new Date(dayKey); // Convert string to Date object
              const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero if needed
              const month = date.toLocaleString("default", { month: "long" }); // Get full month name
              return `${day} ${month}`; // Format as "dd month year"
            }
          )
        : [],
    },
    dataLabels: {
      enabled: false, // Disable data labels
    },
    legend: {
      position: "right", // Optional: You can place the legend at the top
    },
  };

  // Prepare the daily charging chart series for stacking
  const dailyChargingChartSeries = selectedChargingMonth
    ? Object.keys(
        dailyChargingData[selectedChargingMonth][
          Object.keys(dailyChargingData[selectedChargingMonth])[0]
        ]
      ) // Get all groups (e.g., MBMT)
        .map((group) => {
          return {
            name: group, // Name of the group (e.g., "MBMT")
            data: Object.keys(dailyChargingData[selectedChargingMonth]).map(
              (dayKey) => {
                return (
                  dailyChargingData[selectedChargingMonth][dayKey][group] || 0
                ); // Get the consumption for that group, or 0 if not available
              }
            ),
          };
        })
    : [];

  // Handle charging charts
  const handleChargingCharts = () => {
    fetchChargingData();
    setIsChargingChartVisible(true);
  };
  //Charging Ends

  return (
    <>
      <div className="Home-header-container">
        <p>Home</p>
      </div>
      <div className="Home-main-container">
        <div className="Home-map-container">
          <p className="Home-title">Live Tracking</p>
          <div
            className="Home-Details-Container"
            onClick={() => setShowDetails(true)}
          >
            <div
              className="Home-Details-FleetSize"
              onClick={() => setFilter("all")}
            >
              <p className="Home-FleetSize-Title">Fleet Size</p>
              <p className="Home-FleetSize-Value"> {fleetSize}</p>
            </div>
            <div
              className="Home-Details-Active"
              onClick={() => setFilter("active")}
            >
              <p className="Home-Active-Title">Active</p>
              <p className="Home-Active-Value">{activeCount} </p>
            </div>
            <div
              className="Home-Details-Inactive"
              onClick={() => setFilter("inactive")}
            >
              <p className="Home-Inactive-Title">Inactive</p>
              <p className="Home-Inactive-Value">{inactiveCount} </p>
            </div>
            <div
              className="Home-Details-Charging"
              onClick={() => setFilter("charging")}
            >
              <p className="Home-Charging-Title">Charging</p>
              <p className="Home-Charging-Value">{chargingCount}</p>
            </div>
          </div>

          <div className="Home-Map">
            <MapContainer
              ref={mapRef}
              center={[20.5937, 78.9629]}
              zoom={5}
              style={{ height: "100%", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <MarkerClusterGroup
                spiderfyDistanceMultiplier={1}
                showCoverageOnHover={false}
              >
                {vehicles.map((vehicle) => {
                  if (!vehicle.curr_location) {
                    const lat = parseFloat(vehicle.latitude);
                    const lng = parseFloat(vehicle.longitude);
                    const heading = parseFloat(vehicle.heading); // Vehicle's heading (direction)

                    if (
                      lat &&
                      lng &&
                      !isNaN(heading) &&
                      vehicle.device_type !== "Chota Hathi"
                    ) {
                      // Create a rotating icon based on heading
                      const icon = L.divIcon({
                        className: "custom-icon", // Custom class to style the icon
                        html: `<img src="${busIcon}" style="transform: rotate(${heading}deg); width: 20px; height: 40px;" />`,
                        iconSize: [20, 40], // Set the icon size
                        iconAnchor: [15, 15], // Set the anchor to the center of the icon
                        popupAnchor: [1, -34],
                        shadowSize: [41, 41],
                      });

                      return (
                        <Marker
                          key={vehicle.id}
                          position={[lat, lng]}
                          icon={icon}
                        >
                          <Popup>
                            <div>
                              <h3>{vehicle.name}</h3>
                              <p>{vehicle.chassis_number}</p>
                              <p style={{ marginLeft: "15px" }}>
                                Speed: {vehicle.WheelBasedVehicleSpeedRx} Km/h
                              </p>
                            </div>
                          </Popup>
                        </Marker>
                      );
                    }
                    return null;
                  } else {
                    let lat, lng;
                    const [latStr, lngStr] = vehicle.curr_location.split(",");
                    lat = parseFloat(latStr);
                    lng = parseFloat(lngStr);
                    const heading = vehicle.direction_of_vehicle;
                    const icon = L.divIcon({
                      className: "custom-icon", // Custom class to style the icon
                      html: `<img src="${pumaIcon}" style="transform: rotate(${heading}deg); width: 55px; height: 60px;" />`,
                      iconSize: [20, 40], // Set the icon size
                      iconAnchor: [15, 15], // Set the anchor to the center of the icon
                      popupAnchor: [1, -34],
                      shadowSize: [41, 41],
                    });

                    return (
                      <Marker
                        key={vehicle.id}
                        position={[lat, lng]}
                        icon={icon}
                      >
                        <Popup>
                          <div>
                            <h3
                              style={{
                                textAlign: "center",
                                fontFamily: "Manrope",
                              }}
                            >
                              {vehicle.chassis_number}
                            </h3>
                            <p
                              style={{
                                marginLeft: "15px",
                                textAlign: "center",
                                fontFamily: "Manrope",
                              }}
                            >
                              <b>Speed</b>: {vehicle.wheel_based_vehicle_speed}{" "}
                              Km/h
                            </p>
                          </div>
                        </Popup>
                      </Marker>
                    );
                  }
                })}
              </MarkerClusterGroup>
            </MapContainer>
          </div>
        </div>
        <div className="Home-Value-Container">
          <div className="Home-Search-Container">
            <p className="Home-Search-Title">Search Vehicle</p>
            <Select
              isMulti
              className="Home-selection"
              options={vehicleOptions}
              placeholder="Choose vehicles"
              components={{ MultiValue: CustomMultiValue }}
            />
            <div className="Home-Alerts">
              <p>ALERTS</p>
            </div>
          </div>
          <div className="Home-Secondary-Details-Container">
            <div
              className="Home-Distance-Container"
              onClick={toggleDistanceChart}
            >
              <label className="Home-Secondary-Details-Container-title">
                Distance
              </label>
              <p>{Number(homeDisplayData.Bus_dist).toLocaleString()} Km</p>
            </div>
            <div
              className="Home-CharginUnits-Container"
              onClick={toggleChargingUnitsChart}
            >
              <label className="Home-Secondary-Details-Container-title">
                Charging Units
              </label>
              <p>
                {(
                  Number(homeDisplayData.Bus_Charging_Units) +
                    Number(homeDisplayData.Puma_Charging_Units) ||
                  Number(homeDisplayData.Bus_Charging_Units)
                ).toLocaleString()}{" "}
                kWh
              </p>
            </div>
          </div>
          {isDistanceVisible && (
            <div className="Home-Distance-Chart-Container">
              <button onClick={() => setIsDistanceVisible(false)}>X</button>
              <div style={{ display: "flex", gap: "20px" }}>
                <h3>Bus :</h3>
                <p>{homeDisplayData.Bus_dist} Km</p>
                <p>
                  <u onClick={handleDistanceCharts}>View</u>
                </p>
              </div>
              <div style={{ display: "flex", gap: "20px" }}>
                <h3>Puma :</h3>
                <p>{homeDisplayData.Puma_dist + 60299} Km</p>
                <p>
                  <u onClick={showPumaDistanceCharts}>View</u>
                </p>
              </div>
            </div>
          )}
          {isDistanceChartVisible && (
            <div className="Home-Distance-Chart">
              <button
                onClick={() => setIsDistanceChartVisible(false)}
                className="Home-Distance-Chart-Close"
              >
                X
              </button>
              <p>Monthly kilometers</p>
              <Chart
                options={monthlyChartOptions}
                series={monthlyChartSeries}
                type="bar"
                height={250}
              />
            </div>
          )}
          {selectedDistanceMonth && (
            <div className="Home-Distance-Daily-Chart">
              <buttton
                onClick={() => setSelectedDistanceMonth(false)}
                className="Home-Distance-Daily-Chart-Close"
              >
                X
              </buttton>
              <p>
                {new Date(selectedDistanceMonth + "-01").toLocaleString(
                  "default",
                  { month: "long" }
                )}
                's Daily kilometers
              </p>
              <Chart
                options={dailyChartOptions}
                series={dailyChartSeries}
                type="bar"
                height={400}
              />
            </div>
          )}

          {isPumaChartVisible && (
            <div className="Home-Distance-Chart">
              <button
                onClick={() => setIsPumaChartVisible(false)}
                className="Home-Distance-Daily-Chart-Close"
              >
                X
              </button>
              <p>Monthly Distance Covered By Puma</p>
              <Chart
                options={pumaMonthlyChartOptions}
                series={pumaMonthlyChartSeries}
                type="bar"
                height={250}
              />
            </div>
          )}

          {pumaSelectedMonthData && (
            <div className="Home-Distance-Chart">
              <button
                onClick={() => setPumaSelectedMonthData(null)}
                className="Home-Distance-Daily-Chart-Close"
              >
                X
              </button>
              <p>
                {new Date(pumaSelectedMonthData + "-01").toLocaleString(
                  "default",
                  {
                    month: "long",
                  }
                )}
                's Distance Covered by Puma Daily
              </p>
              <Chart
                options={pumaDailyChartOptions}
                series={pumaDailyChartSeries}
                type="bar"
                height={400}
              />
            </div>
          )}

          {isChargingUnitsChartVisible && (
            <div className="ChargingUnits-Chart-Container">
              <button onClick={() => setIsChargingUnitsChartVisible(false)}>
                X
              </button>
              <div style={{ display: "flex", gap: "20px" }}>
                <h3>Bus Charging Units :</h3>
                <p>{homeDisplayData.Bus_Charging_Units} kWh</p>
                <p onClick={handleChargingCharts}>
                  <u>View</u>
                </p>
              </div>
              {/* <div style={{ display: "flex", gap: "20px" }}>
                <h3>Puma Charging Units:</h3>
                <p>{homeDisplayData.Puma_Charging_Units || 0} kWh</p>
              </div> */}
            </div>
          )}
          {isChargingChartVisible && (
            <div className="Home-Charging-Chart">
              <button
                onClick={() => setIsChargingChartVisible(false)}
                className="Home-Charging-Chart-Close"
              >
                X
              </button>
              <p>Monthly Consumption (kWh)</p>
              <Chart
                options={monthlyChargingChartOptions}
                series={monthlyChargingChartSeries}
                type="bar"
                height={250}
              />
            </div>
          )}

          {selectedChargingMonth && (
            <div className="Home-Charging-Daily-Chart">
              <button
                onClick={() => setSelectedChargingMonth(false)}
                className="Home-Charging-Daily-Chart-Close"
              >
                X
              </button>
              <p>
                {new Date(selectedChargingMonth + "-01").toLocaleString(
                  "default",
                  {
                    month: "long",
                  }
                )}
                's Daily Consumption (kWh)
              </p>
              <Chart
                options={dailyChargingChartOptions}
                series={dailyChargingChartSeries}
                type="bar"
                height={400}
              />
            </div>
          )}
          {showDetails && (
            <div className="Home-Vehicle-Details-display">
              <button onClick={() => setShowDetails(false)}>X</button>
              <h3 className="Home-Vehicle-Type-Selection-Title">
                Select Vehicle Type:
              </h3>
              <div className="Home-Vehicle-Type-Selection">
                <div className="Home-Vehicle-Type-Selection-Bus">
                  <img
                    src={BusImage}
                    alt="Bus"
                    onClick={() => handleVehicleTypeChange("Bus")}
                    style={{
                      cursor: "pointer",
                      border:
                        setVehicleType === "Bus" ? "2px solid blue" : "none",
                      width: "100px",
                      height: "60px",
                      backgroundColor: "transparent",
                    }}
                  />
                </div>
                <div className="Home-Vehicle-Type-Selection-Bus">
                  <img
                    src={PumaImage}
                    alt="Puma"
                    onClick={() => handleVehicleTypeChange("Puma")}
                    style={{
                      cursor: "pointer",
                      border:
                        setVehicleType === "Puma" ? "2px solid blue" : "none",
                      width: "100px",
                      height: "60px",
                      backgroundColor: "white",
                    }}
                  />
                </div>
              </div>
              {deviceSorting.map((vehicle) => {
                if (
                  vehicle.device_type === "Chota Hathi" ||
                  vehicle.device_type === "Person" ||
                  (vehicle.latitude === 0 && vehicle.longitude === 0)
                ) {
                  return null;
                }

                return (
                  <div
                    className="Home-Edited-Vehicle-Box"
                    key={vehicle.id}
                    onClick={
                      () => handleVehicleClick(vehicle) // Trigger the vehicle click
                    }
                  >
                    <div className="Home-Vehicle-Box-Container-1">
                      <div
                        className="Home-Vehicle-Box-Container-1-Status"
                        style={{
                          backgroundColor:
                            vehicle.status === "Moving" ||
                            (vehicle.status === "Idle" &&
                              vehicle.ChargerGunDetected2 === 0) ||
                            (vehicle.status === "Stopped" &&
                              vehicle.ChargerGunDetected2 === 0) ||
                            vehicle.wheel_based_vehicle_speed > 0
                              ? "#99FF7A"
                              : (vehicle.status === "NOdata" &&
                                  vehicle.Chargingtatus2 === 0) ||
                                vehicle.status === "unknown" ||
                                vehicle.status === "NoGps" ||
                                vehicle.wheel_based_vehicle_speed === 0
                              ? "#FC6E6E"
                              : (vehicle.status === "Idle" &&
                                  vehicle.ChargerGunDetected2 === 1) ||
                                (vehicle.status === "Stopped" &&
                                  vehicle.ChargerGunDetected2 === 1) ||
                                (vehicle.status === "NOdata" &&
                                  vehicle.ChargerGunDetected2 === 1)
                              ? "#5DD5FC"
                              : "Transparent",
                        }}
                      >
                        <label
                          style={{
                            color:
                              vehicle.status === "Moving" ||
                              (vehicle.status === "Idle" &&
                                vehicle.ChargerGunDetected2 === 0) ||
                              (vehicle.status === "Stopped" &&
                                vehicle.ChargerGunDetected2 === 0) ||
                              vehicle.wheel_based_vehicle_speed > 0
                                ? "#186A12"
                                : (vehicle.status === "NOdata" &&
                                    vehicle.Chargingtatus2 === 0) ||
                                  vehicle.status === "unknown" ||
                                  vehicle.status === "NoGps" ||
                                  vehicle.wheel_based_vehicle_speed === 0
                                ? "#B90000"
                                : (vehicle.status === "Idle" &&
                                    vehicle.ChargerGunDetected2 === 1) ||
                                  (vehicle.status === "Stopped" &&
                                    vehicle.ChargerGunDetected2 === 1) ||
                                  (vehicle.status === "NOdata" &&
                                    vehicle.ChargerGunDetected2 === 1)
                                ? "#0F71B2"
                                : "#B90000",
                          }}
                        >
                          {vehicle.status === "Moving" ||
                          (vehicle.status === "Idle" &&
                            vehicle.ChargerGunDetected2 === 0) ||
                          (vehicle.status === "Stopped" &&
                            vehicle.ChargerGunDetected2 === 0) ||
                          vehicle.wheel_based_vehicle_speed > 0
                            ? "ACTIVE"
                            : (vehicle.status === "NOdata" &&
                                vehicle.Chargingtatus2 === 0) ||
                              vehicle.status === "unknown" ||
                              vehicle.status === "NoGps" ||
                              vehicle.wheel_based_vehicle_speed === 0
                            ? "INACTIVE"
                            : (vehicle.status === "Idle" &&
                                vehicle.ChargerGunDetected2 === 1) ||
                              (vehicle.status === "Stopped" &&
                                vehicle.ChargerGunDetected2 === 1) ||
                              (vehicle.status === "NOdata" &&
                                vehicle.ChargerGunDetected2 === 1)
                            ? "CHARGING"
                            : vehicle.status || "INACTIVE"}
                        </label>
                      </div>
                      <div className="Home-Vehicle-Box-Container-1-Details">
                        <label style={{ marginTop: "5px" }}>
                          {vehicle.registration_number ||
                            vehicle.vehicle_reg_num}
                        </label>
                        <img
                          src={vehicle.device_type ? BusImage : PumaImage}
                          alt="busImg"
                          style={
                            vehicle.device_type
                              ? {
                                  width: "146.487px",
                                  height: "83.002px",
                                  flexShrink: "0",
                                }
                              : {
                                  width: "161.487px",
                                  height: "109.002px",
                                  flexShrink: "0",
                                }
                          }
                        />
                        <label style={{ marginTop: "10px" }}>
                          {vehicle.device_type ? "Bus" : "Puma"}
                        </label>
                      </div>
                    </div>
                    <div className="Home-Vehicle-Box-Container-2">
                      <div className="Home-Vehicle-Box-Container-2-Details">
                        <div className="Home-Vehicle-Box-Container-2-Details-Speed">
                          <img
                            src={speedOdometer}
                            alt="SpeedOdometer"
                            style={{
                              width: "34px",
                              height: "32px",
                              marginLeft: "6px",
                            }}
                          />
                          <p>
                            {vehicle.device_type
                              ? vehicle.WheelBasedVehicleSpeedRx.toFixed(0)
                              : vehicle.wheel_based_vehicle_speed}
                            Km/h
                          </p>
                        </div>
                        <div className="Home-Battery-SOC">
                          <div className="Home-Battery-SOC-Top"></div>
                          <div className="Home-Battery-SOC-Bottom">
                            <div
                              className="Home-Battery-SOC-Fill"
                              style={{
                                height: `${
                                  (vehicle.stateOfCharge / 100 ||
                                    vehicle.a_SOC_value / 100) * 29
                                }px`, // Dynamic height based on stateOfCharge
                                backgroundColor: getBatteryColor(
                                  vehicle.stateOfCharge || vehicle.a_SOC_value
                                ), // Set the color based on the percentage
                              }}
                            ></div>
                          </div>
                          <p>{vehicle.stateOfCharge || vehicle.a_SOC_value}%</p>
                        </div>
                        <div className="Home-Vehicle-Box-Container-2-Details-Speed">
                          <img
                            src={DTEIcon}
                            alt="DTE"
                            style={{
                              width: "38px",
                              height: "31px",
                              marginTop: "3px",
                            }}
                          />
                          <p>
                            {(vehicle.stateOfCharge * 2).toFixed(0) | "NA" ||
                              (vehicle.a_SOC_value * 1.7).toFixed(0) | "NA"}
                            Km
                          </p>
                        </div>
                        <div className="Home-Vehicle-Box-Container-2-Details-Speed">
                          <img
                            src={BatteryTempIcon}
                            alt="Temp"
                            style={{
                              width: "38px",
                              height: "32px",
                              marginTop: "3px",
                            }}
                          />
                          <p style={{ marginLeft: "-8px" }}>
                            {vehicle.batteryTemperature ||
                              vehicle.a_max_cell_temp}{" "}
                            °C
                          </p>
                        </div>
                      </div>
                      <div className="Home-Vehicle-Box-Container-2-Address">
                        <p>{vehicle.address}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="Home-Secondary-Details-Container">
            <div className="Home-Traction-Container">
              <label className="Home-Secondary-Details-Container-title">
                Traction
              </label>
              <p>{homeDisplayData.Bus_Traction_energy}kWh</p>
            </div>
            <div className="Home-Regeneration-Container">
              <label className="Home-Secondary-Details-Container-title">
                Regeneration
              </label>
              <p>
                {homeDisplayData.Bus_Regen_energy}
                kWh
              </p>
            </div>
          </div>
          <div className="Home-Secondary-Details-Container">
            <div
              className="Home-InsuffCharging-Container"
              onClick={toggleInsufficientChargingChart}
            >
              <label className="Home-Secondary-Details-Container-title">
                Insufficient Charging
              </label>
            </div>

            <div
              className="Home-DeepDischarge-Container"
              onClick={toggleDeepDischargeChart}
            >
              <label className="Home-Secondary-Details-Container-title">
                Deep Discharge
              </label>
            </div>
          </div>
          {isInsufficientChargingChartVisible && (
            <div className="Insufficient-Chart-Container">
              <button
                className="Insufficient-Chart-Container-Close"
                onClick={() => setIsInsufficientChargingChartVisible(false)}
              >
                X
              </button>
              <h3
                style={{
                  fontFamily: "Manrope",
                  color: "#255f92",
                  textAlign: "center",
                  marginBottom: "10px",
                  marginTop: "0px",
                }}
              >
                Insufficient Charging
              </h3>
              {/* Month Selector */}
              {isMonthSelectorVisible && (
                <div className="selection-item1">
                  <label>Select Month:</label>
                  <input
                    type="month"
                    className="form-control1"
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    required
                  />
                  <button
                    onClick={handleMonthSubmit1}
                    className="submit-button"
                  >
                    Submit
                  </button>
                </div>
              )}
              {/* Chart Display */}
              <ApexCharts
                options={{
                  chart: { type: "bar", toolbar: { show: false } },
                  xaxis: {
                    categories: insufficientChargingChartData.categories,
                  },
                  yaxis: { title: { text: "Count" } },
                  tooltip: {
                    theme: "dark",
                    custom: ({ series, seriesIndex, dataPointIndex }) => {
                      const count = series[seriesIndex][dataPointIndex];
                      const models =
                        insufficientChargingChartData.models[dataPointIndex];
                      return `
                              <div class="tooltip-container">
                                <p><strong>Date:</strong> ${insufficientChargingChartData.categories[dataPointIndex]}</p>
                                <p><strong>Count:</strong> ${count}</p>
                                <p><strong>Models:</strong> ${models}</p>
                              </div>
                            `;
                    },
                  },
                  dataLabels: { enabled: false },
                }}
                series={insufficientChargingChartData.series}
                type="bar"
                height={250}
              />
            </div>
          )}
          {isDeepDischargeChartVisible && (
            <div className="Deep-Chart-Container">
              <button
                className="Deep-Chart-Container-Close"
                onClick={() => setIsDeepDischargeChartVisible(false)}
              >
                X
              </button>
              <h3
                style={{
                  fontFamily: "Manrope",
                  color: "#255f92",
                  textAlign: "center",
                  marginBottom: "10px",
                  marginTop: "0px",
                }}
              >
                Deep Discharge
              </h3>
              {/* Month Selector */}
              {isMonthSelectorVisible && (
                <div className="selection-item1">
                  <label>Select Month:</label>
                  <input
                    type="month"
                    className="form-control1"
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    required
                  />
                  <button onClick={handleMonthSubmit} className="submit-button">
                    Submit
                  </button>
                </div>
              )}
              {/* Chart Display */}
              <ApexCharts
                options={{
                  chart: { type: "bar", toolbar: { show: false } },
                  xaxis: {
                    categories:
                      deepDischargeChartData.series[0]?.data?.map((d) => d.x) ||
                      [],
                  },
                  tooltip: deepDischargeTooltip,
                  dataLabels: { enabled: false },
                }}
                series={deepDischargeChartData.series}
                type="bar"
                height={250}
              />
            </div>
          )}
        </div>
        {selectedVehicle && (
          <div className="Home-ModelBased-Container">
            <div style={{ display: "flex" }}>
              <button onClick={handleBackClick}>&#8592;</button>
              <h2>Live Tracking</h2>
            </div>
            <div className="Home-ModelBased-Details">
              <div className="Home-ModelBased-Details-1">
                <div className="Home-ModelBased-Details-1-Monitoring">
                  <h3>Monitoring</h3>
                  <div style={{ display: "flex", gap: "100px" }}>
                    <div className="Home-ModelBased-Details-1-Monitoring-VRN">
                      <p>
                        {selectedVehicle.registration_number ||
                          selectedVehicle.vehicle_reg_num}
                      </p>
                    </div>
                    <div
                      className="Home-ModelBased-Details-1-Monitoring-Status"
                      style={{
                        backgroundColor:
                          selectedVehicle.status === "Moving" ||
                          (selectedVehicle.status === "Idle" &&
                            selectedVehicle.ChargerGunDetected2 === 0) ||
                          (selectedVehicle.status === "Stopped" &&
                            selectedVehicle.ChargerGunDetected2 === 0) ||
                          selectedVehicle.wheel_based_vehicle_speed > 0
                            ? "#99FF7A"
                            : (selectedVehicle.status === "NOdata" &&
                                selectedVehicle.Chargingtatus2 === 0) ||
                              selectedVehicle.status === "unknown" ||
                              selectedVehicle.status === "NoGps" ||
                              selectedVehicle.wheel_based_vehicle_speed === 0
                            ? "#FC6E6E"
                            : (selectedVehicle.status === "Idle" &&
                                selectedVehicle.ChargerGunDetected2 === 1) ||
                              (selectedMonth.status === "Stopped" &&
                                selectedVehicle.ChargerGunDetected2 === 1) ||
                              (selectedVehicle.status === "NOdata" &&
                                selectedVehicle.ChargerGunDetected2 === 1)
                            ? "#5DD5FC"
                            : "Transparent",
                      }}
                    >
                      <p
                        style={{
                          color:
                            selectedVehicle.status === "Moving" ||
                            (selectedVehicle.status === "Idle" &&
                              selectedVehicle.ChargerGunDetected2 === 0) ||
                            (selectedVehicle.status === "Stopped" &&
                              selectedVehicle.ChargerGunDetected2 === 0) ||
                            selectedVehicle.wheel_based_vehicle_speed > 0
                              ? "#186A12"
                              : (selectedVehicle.status === "NOdata" &&
                                  selectedVehicle.Chargingtatus2 === 0) ||
                                selectedVehicle.status === "unknown" ||
                                selectedVehicle.status === "NoGps" ||
                                selectedVehicle.wheel_based_vehicle_speed === 0
                              ? "#B90000"
                              : (selectedVehicle.status === "Idle" &&
                                  selectedVehicle.ChargerGunDetected2 === 1) ||
                                (selectedMonth.status === "Stopped" &&
                                  selectedVehicle.ChargerGunDetected2 === 1) ||
                                (selectedVehicle.status === "NOdata" &&
                                  selectedVehicle.ChargerGunDetected2 === 1)
                              ? "#0F71B2"
                              : "#B90000",
                        }}
                      >
                        {selectedVehicle.status === "Moving" ||
                        (selectedVehicle.status === "Idle" &&
                          selectedVehicle.ChargerGunDetected2 === 0) ||
                        (selectedVehicle.status === "Stopped" &&
                          selectedVehicle.ChargerGunDetected2 === 0) ||
                        selectedVehicle.wheel_based_vehicle_speed > 0
                          ? "ACTIVE"
                          : (selectedVehicle.status === "NOdata" &&
                              selectedVehicle.Chargingtatus2 === 0) ||
                            selectedVehicle.status === "unknown" ||
                            selectedVehicle.status === "NoGps" ||
                            selectedVehicle.wheel_based_vehicle_speed === 0
                          ? "INACTIVE"
                          : (selectedVehicle.status === "Idle" &&
                              selectedVehicle.ChargerGunDetected2 === 1) ||
                            (selectedMonth.status === "Stopped" &&
                              selectedVehicle.ChargerGunDetected2 === 1) ||
                            (selectedVehicle.status === "NOdata" &&
                              selectedVehicle.ChargerGunDetected2 === 1)
                          ? "CHARGING"
                          : selectedVehicle.status || "INACTIVE"}
                      </p>
                    </div>
                  </div>
                  <div className="Home-ModelBased-Details-1-Status">
                    <label className="Home-ModelBased-Details-1-Status-Title">
                      Status
                    </label>
                    <label className="Home-ModelBased-Details-1-Status-Value">
                      Good
                    </label>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Pack Current</th>
                        <th>Pack Voltage</th>
                        <th>Max Cell Voltage</th>
                        <th>Max Temp</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            color: "#255F92",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                        >
                          Pack A
                        </td>
                        <td>
                          {selectedVehicle.APackCurrentValue |
                            selectedVehicle.a_pack_curr_value}
                        </td>
                        <td>
                          {selectedVehicle.APackVoltageValue |
                            selectedVehicle.a_pack_voltage_value}
                        </td>
                        <td>
                          {selectedVehicle.AMaxCellVolt ||
                            selectedVehicle.a_max_cell_volt}
                        </td>
                        <td>
                          {selectedVehicle.AMaxCellTemp ||
                            selectedVehicle.a_max_cell_temp}
                        </td>
                      </tr>
                      {selectedVehicle.device_type && (
                        <tr>
                          <td
                            style={{
                              color: "#255F92",
                              fontSize: "20px",
                              fontWeight: "600",
                            }}
                          >
                            Pack B
                          </td>
                          <td>{selectedVehicle.BPackCurrentValue}</td>
                          <td>{selectedVehicle.BPackVoltageValue}</td>
                          <td>{selectedVehicle.BMaxCellVolt}</td>
                          <td>{selectedVehicle.BMaxCellTemp}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="Home-ModelBased-Details-1-FaultAlerts">
                  <h3>Fault/Alerts</h3>
                  <span
                    style={{
                      position: "absolute",
                      top: "63",
                      left: "157",
                      backgroundColor: "#4CAF50",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      fontSize: "15px",
                    }}
                  >
                    EVCC
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      top: "245",
                      left: "157",
                      backgroundColor: "#4CAF50",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      fontSize: "15px",
                    }}
                  >
                    DCDC
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      top: "147",
                      left: "98",
                      backgroundColor: "#4CAF50",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      fontSize: "15px",
                    }}
                  >
                    TCS
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      top: "30",
                      left: "274px",
                      backgroundColor: "#4CAF50",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      fontSize: "15px",
                    }}
                  >
                    EBS
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      top: "272px",
                      left: "274px",
                      backgroundColor: "#4CAF50",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      fontSize: "15px",
                    }}
                  >
                    BMS
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      top: "272px",
                      left: "360px",
                      backgroundColor: "#4CAF50",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      fontSize: "15px",
                    }}
                  >
                    Battery Pack B
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      top: "34",
                      left: "390px",
                      backgroundColor: "#4CAF50",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      fontSize: "15px",
                    }}
                  >
                    ECAS
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      top: "63",
                      right: "157",
                      backgroundColor: "#4CAF50",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      fontSize: "15px",
                    }}
                  >
                    EComp
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      top: "147",
                      right: "118",
                      backgroundColor: "#4CAF50",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      fontSize: "15px",
                    }}
                  >
                    BCS
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      top: "245",
                      right: "157",
                      backgroundColor: "#4CAF50",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      fontSize: "15px",
                    }}
                  >
                    DCDC
                  </span>
                  <img
                    src={selectedVehicle.device_type ? BusImage : PumaImage}
                    alt="9M_BUS"
                    style={{ width: "420px", height: "240px" }}
                  />
                </div>
              </div>
              <div className="Home-ModelBased-Details-2">
                <div className="Home-ModelBased-Details-2-Map">
                  <MapContainer
                    center={
                      // Check if 'curr_location' exists and split it into lat/lon
                      selectedVehicle.curr_location
                        ? selectedVehicle.curr_location
                            .split(",")
                            .map((coord) => parseFloat(coord))
                        : [selectedVehicle.latitude, selectedVehicle.longitude]
                    }
                    zoom={13}
                    style={{
                      height: "100%",
                      width: "100%",
                      marginTop: selectedVehicle.device_type ? "0" : "-30px",
                    }}
                  >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Marker
                      position={
                        // Same split logic for marker position
                        selectedVehicle.curr_location
                          ? selectedVehicle.curr_location
                              .split(",")
                              .map((coord) => parseFloat(coord))
                          : [
                              selectedVehicle.latitude,
                              selectedVehicle.longitude,
                            ]
                      }
                      icon={
                        selectedVehicle.device_type
                          ? L.divIcon({
                              className: "custom-icon", // Custom class to style the icon
                              html: `<img src="${busIcon}" style="transform: rotate(${selectedVehicle.heading}deg); width: 20px; height: 40px;" />`,
                              iconSize: [20, 40], // Set the icon size
                              iconAnchor: [15, 15], // Set the anchor to the center of the icon
                              popupAnchor: [1, -34],
                              shadowSize: [41, 41],
                            })
                          : L.divIcon({
                              className: "custom-icon", // Custom class to style the icon
                              html: `<img src="${pumaIcon}" style="transform: rotate(${selectedVehicle.direction_of_vehicle}deg); width: 55px; height: 60px;" />`,
                              iconSize: [20, 40], // Set the icon size
                              iconAnchor: [15, 15], // Set the anchor to the center of the icon
                              popupAnchor: [1, -34],
                              shadowSize: [41, 41],
                            })
                      }
                    >
                      <Popup>
                        Vehicle Name:{" "}
                        {selectedVehicle.name || selectedVehicle.chassis_number}
                        <br />
                        Speed :{" "}
                        {selectedVehicle.WheelBasedVehicleSpeedRx ||
                          selectedVehicle.wheel_based_vehicle_speed}
                        Km/h
                      </Popup>
                    </Marker>
                  </MapContainer>
                </div>
                <div className="Home-ModelBased-Details-2-Details">
                  <span>
                    <img src={speedOdometer} alt="SpeedOdometer" />
                    <p
                      style={{
                        marginTop: "0px",
                        fontFamily: "Manrope",
                        marginLeft: "-5px",
                      }}
                    >
                      {selectedVehicle.WheelBasedVehicleSpeedRx |
                        selectedVehicle.wheel_based_vehicle_speed}
                      Km/h
                    </p>
                  </span>
                  <span>
                    <div className="Home-Battery-SOC">
                      <div className="Home-Battery-SOC-Top"></div>
                      <div className="Home-Battery-SOC-Bottom">
                        <div
                          className="Home-Battery-SOC-Fill"
                          style={{
                            height: `${
                              (selectedVehicle.stateOfCharge / 100 ||
                                selectedVehicle.a_SOC_value / 100) * 29
                            }px`, // Dynamic height based on state_of_charge
                            backgroundColor: getBatteryColor(
                              selectedVehicle.stateOfCharge |
                                selectedVehicle.a_SOC_value
                            ), // Set the color based on the percentage
                          }}
                        ></div>
                      </div>
                      <p>
                        {selectedVehicle.stateOfCharge |
                          selectedVehicle.a_SOC_value}
                        %
                      </p>
                    </div>
                  </span>
                  {/* <span>
                    <img src={DTEIcon} alt="DTE_Icon" style={{marginLeft: "60px"}}/>
                    <p style={{ marginTop: "0px", fontFamily: "Manrope" }}>
                      Distance To Empty : 
                      {selectedVehicle.stateOfCharge * 2 ||
                        (selectedVehicle.a_SOC_value * 1.7).toFixed(0)}
                      Km
                    </p>
                  </span> */}
                </div>
                <div className="Home-ModelBased-Details-2-Details-Todays">
                  <h4>Today's</h4>
                  <div className="Home-ModelBased-Details-2-Details-Todays-Details">
                    <span>
                      <label>Run Time</label>
                      <p>
                        {selectedVehicle.device_type
                          ? (selectedVehicle.todayMovementTime / 3600).toFixed(
                              0
                            )
                          : selectedVehicle.running_time}
                        hrs
                      </p>
                    </span>
                    <span>
                      <label>Idle Time</label>
                      <p>
                        {selectedVehicle.device_type
                          ? (selectedVehicle.todayIdleTime / 3600).toFixed(0)
                          : selectedVehicle.ideal_time}
                        hrs
                      </p>
                    </span>
                    <span>
                      <label>Distance</label>
                      <p>
                        {selectedVehicle.device_type
                          ? selectedVehicle.todayKms.toFixed(0)
                          : selectedVehicle.todays_odometer}
                        Km
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Home;
