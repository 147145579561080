import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import video from '../assets/Eka_Loading.mp4';

const Loading = ({ setLoading }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
            navigate('/home');
        }, 2800);

        return () => clearTimeout(timer);
    }, [setLoading, navigate]);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            fontFamily: 'Arial, sans-serif',
            position: 'absolute',
            top: 0,
            left: 0,
            overflow: 'hidden',
            margin: 0
        }}>
            <video
                autoPlay
                muted
                loop
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: -1
                }}
            >
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default Loading;
