import React, { useEffect, useState, useRef } from "react";
import "./Fleetmovement.css";
import { data } from "../DropdownData";
import "daterangepicker/daterangepicker.css";
import "daterangepicker";
import moment from "moment";
import $ from "jquery";
import Plotly from "plotly.js-dist";

function FleetMovement() {
  const [selected, setSelected] = useState({
    owner: "",
    group: "",
    model: "",
  });
  const [summaryData, setSummaryData] = useState({});
  const dateRangeRef = useRef(null);
  const handleOwnerChange = (event) => {
    const newOwner = event.target.value;
    setSelected({
      owner: newOwner,
      group: "", // Reset group and model when owner changes
      model: "",
    });
  };

  const handleGroupChange = (event) => {
    const newGroup = event.target.value;
    setSelected((prev) => ({
      ...prev,
      group: newGroup,
      model: "", // Reset model when group changes
    }));
  };

  const handleModelChange = (event) => {
    setSelected((prev) => ({
      ...prev,
      model: event.target.value,
    }));
  };

  const handleClick = async () => {
    const startDate = $(dateRangeRef.current).data("daterangepicker").startDate;
    const endDate = $(dateRangeRef.current).data("daterangepicker").endDate;

    if (!selected.group || !startDate || !endDate) {
      alert("Please select model and date range");
      return;
    }

    if (selected.group && startDate && endDate) {
      const formattedModel = selected.model.replace(/-/g, " ");

      fetchTotalDistanceTravelled(
        startDate.format("YYYY/MM/DD"),
        endDate.format("YYYY/MM/DD"),
        selected.model,
        selected.group
      );

      fetchEnergyConsumption(
        startDate.format("YYYY/MM/DD"),
        endDate.format("YYYY/MM/DD"),
        formattedModel.toLowerCase().replace(/ /g, ""),
        selected.group
      );

      fetchDepthOfDischarge(
        startDate.format("YYYY/MM/DD"),
        endDate.format("YYYY/MM/DD"),
        selected.model,
        selected.group
      );
      fetchChargingData(
        startDate.format("YYYY/MM/DD"),
        endDate.format("YYYY/MM/DD"),
        selected.model,
        selected.group
      );
      fetchInsufficientData(
        startDate.format("YYYY/MM/DD"),
        endDate.format("YYYY/MM/DD"),
        selected.model,
        selected.group
      );
      fetchActiveData(
        startDate.format("YYYY/MM/DD"),
        endDate.format("YYYY/MM/DD"),
        selected.model,
        selected.group
      );
    }

    if (!selected.owner || !selected.group || !startDate || !endDate) {
      alert(
        "Please select the Group Or Model and the Date Range to Display the Chart"
      );
    }
  };

  const filteredModels = data.fleetOwners
    .find((owner) => owner.name === selected.owner)
    ?.group.find((group) => group.name === selected.group)?.models;

  useEffect(() => {
    setSummaryData({});
    fetchSummaryData();
  }, []);
  const FleetSize = 139;
  const fetchSummaryData = () => {
    const apiUrl = "https://eka-connect.com/Summary/Fleet_summary/";
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setSummaryData(data);
      })
      .catch((error) => console.error("Error fetching Data:", error));
  };

  useEffect(() => {
    const maxSpan = {
      days: 30, // Set the maximum span to 30 days
    };
    if (dateRangeRef.current) {
      $(dateRangeRef.current).daterangepicker({
        startDate: moment().subtract(1, "month"), // Set the start date to 1 month ago
        endDate: moment(),
        opens: "left",
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days"),
          ],
          "Last Month": [
            moment().subtract(1, "month").startOf("month"), // Set the start date to the first day of the previous month
            moment().subtract(1, "month").endOf("month"), // Set the end date to the last day of the previous month
          ],
        },
        maxSpan: maxSpan,
        maxDate: moment(),
      });
    }
  }, []);

  const fetchTotalDistanceTravelled = async (
    startDate,
    endDate,
    model,
    group
  ) => {
    const totalDistanceWithModel_apiUrl = `https://eka-connect.com/Summary/Total_distance/?vehicle=${group}&start_date=${startDate}&end_date=${endDate}&date_range=range&model=${model}`;

    try {
      const response = await fetch(totalDistanceWithModel_apiUrl);

      // Check if the response is OK (status code 200-299)
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.error || "Data is unavailable";

        // Display the error message in the specified container
        const container = document.getElementById(
          "fleetMovement-totalDistance"
        );
        if (container) {
          container.textContent = errorMessage; // or .innerText depending on your preference
        }

        throw new Error(errorMessage); // Re-throw the error to propagate it if needed
      }

      const data = await response.json();

      // Check if the data contains "No data found" message
      if (data.message) {
        const container = document.getElementById(
          "fleetMovement-totalDistance"
        );
        if (container) {
          container.textContent = data.message;
        }
      } else {
        // Otherwise, proceed with plotting the data (assuming plotStackedBarChart exists)
        plotStackedBarChart(data, "fleetMovement-totalDistance");
      }
    } catch (error) {
      console.error("Error fetching Total Distance Travelled data:", error);
      // Optionally, display a generic error message in the container
      const container = document.getElementById("fleetMovement-totalDistance");
      if (container) {
        container.textContent = "Error loading data. Please try again later.";
      }
    }
  };

  const fetchEnergyConsumption = async (startDate, endDate, model, group) => {
    const energyConsumption_apiUrl = `https://eka-connect.com/Health_monitoring/Energy_consumption/?vehicle=${group}&start_date=${startDate}&end_date=${endDate}&model=${model}`;

    try {
      const response = await fetch(energyConsumption_apiUrl);

      // Check if the response is OK (status code 200-299)
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.error || "Data is unavailable";

        // Display the error message in the specified container
        const container = document.getElementById(
          "fleetMovement-energyConsumption"
        );
        if (container) {
          container.textContent = errorMessage; // or .innerText depending on your preference
        }

        throw new Error(errorMessage); // Re-throw the error to propagate it if needed
      }

      const data = await response.json();

      // Check if the data contains "No data found" message
      if (data.message) {
        const container = document.getElementById(
          "fleetMovement-energyConsumption"
        );
        if (container) {
          container.textContent = data.message;
        }
      } else {
        // Otherwise, proceed with plotting the data (assuming plotBarChart exists)
        plotBarChart(data, "fleetMovement-energyConsumption");
      }
    } catch (error) {
      console.error("Error fetching Energy Consumption data:", error);
      // Optionally, display a generic error message in the container
      const container = document.getElementById(
        "fleetMovement-energyConsumption"
      );
      if (container) {
        container.textContent = "Error loading data. Please try again later.";
      }
    }
  };

  const fetchDepthOfDischarge = async (startDate, endDate, model, group) => {
    const DOD_apiUrl = `https://eka-connect.com/Summary/Depth_of_Discharge/?vehicle=${group}&start_date=${startDate}&end_date=${endDate}&date_range=range&model=${model}`;

    try {
      console.log(DOD_apiUrl);
      const response = await fetch(DOD_apiUrl);

      // Check if the response is OK (status code 200-299)
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.error || "Data is unavailable";

        // Display the error message in the specified container
        const container = document.getElementById(
          "fleetMovement-depthOfDischarge"
        );
        if (container) {
          container.textContent = errorMessage; // or .innerText depending on your preference
        }

        throw new Error(errorMessage); // Re-throw the error to propagate it if needed
      }

      const data = await response.json();

      // Check if the data contains "No data found" message
      if (
        data.message &&
        data.message === "No data found for the provided query parameters."
      ) {
        const container = document.getElementById(
          "fleetMovement-depthOfDischarge"
        );
        if (container) {
          container.textContent =
            "No data available for the selected query parameters.";
        }
      } else {
        // Otherwise, handle and display the discharge data
        handleDischargeData(data, "fleetMovement-depthOfDischarge");
      }
    } catch (error) {
      console.error("Error fetching Depth of Discharge data:", error);
      // Optionally, display a generic error message in the container
      const container = document.getElementById(
        "fleetMovement-depthOfDischarge"
      );
      if (container) {
        container.textContent = "Error loading data. Please try again later.";
      }
    }
  };

  // Fetch Charging Data
  const fetchChargingData = async (startDate, endDate, model, group) => {
    const CC_apiUrl = `https://eka-connect.com/Summary/Charging_chart/?vehicle=${group}&start_date=${startDate}&end_date=${endDate}&date_range=range&model=${model}`;

    try {
      const response = await fetch(CC_apiUrl);

      // Check if the response is OK (status code 200-299)
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.error || "Data is unavailable";

        // Display the error message in the specified container
        const container = document.getElementById(
          "fleetMovement-chargingUnits"
        );
        if (container) {
          container.textContent = errorMessage;
        }

        throw new Error(errorMessage);
      }

      const data = await response.json();

      // Check if the data contains "No data found" message
      if (data.message) {
        const container = document.getElementById(
          "fleetMovement-chargingUnits"
        );
        if (container) {
          container.textContent = data.message;
        }
      } else {
        // Handle and plot the charging data
        handleChargingData(data, "fleetMovement-chargingUnits");
      }
    } catch (error) {
      console.error("Error fetching Charging Data:", error);
      const container = document.getElementById("fleetMovement-chargingUnits");
      if (container) {
        container.textContent = "Error loading data. Please try again later.";
      }
    }
  };

  // Fetch Insufficient Charging Data
  const fetchInsufficientData = async (startDate, endDate, model, group) => {
    const IC_apiUrl = `https://eka-connect.com/Summary/Insufficent_charge/?vehicle=${group}&start_date=${startDate}&end_date=${endDate}&date_range=range&model=${model}`;

    try {
      const response = await fetch(IC_apiUrl);

      // Check if the response is OK (status code 200-299)
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.error || "Data is unavailable";

        // Display the error message in the specified container
        const container = document.getElementById(
          "fleetMovement-InsufficientCharging"
        );
        if (container) {
          container.textContent = errorMessage;
        }

        throw new Error(errorMessage);
      }

      const data = await response.json();

      // Check if the data contains "No data found" message
      if (data.message) {
        const container = document.getElementById(
          "fleetMovement-InsufficientCharging"
        );
        if (container) {
          container.textContent = data.message;
        }
      } else {
        // Plot Insufficient Charging Data
        plotInsufficientChargingData(
          data,
          "fleetMovement-InsufficientCharging",
          model
        );
      }
    } catch (error) {
      console.error("Error fetching Insufficient Charging Data:", error);
      const container = document.getElementById(
        "fleetMovement-InsufficientCharging"
      );
      if (container) {
        container.textContent = "Error loading data. Please try again later.";
      }
    }
  };

  // Fetch Active Time Data
  const fetchActiveData = async (startDate, endDate, model, group) => {
    const AD_apiUrl = `https://eka-connect.com/Summary/Active_time/?vehicle=${group}&start_date=${startDate}&end_date=${endDate}&date_range=range&model=${model}`;

    try {
      const response = await fetch(AD_apiUrl);

      // Check if the response is OK (status code 200-299)
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.error || "Data is unavailable";

        // Display the error message in the specified container
        const container = document.getElementById("fleetMovement-activeTime");
        const container2 = document.getElementById("fleetMovement-idleTime");
        if (container) {
          container.textContent = errorMessage;
          container2.textContent = errorMessage;
        }

        throw new Error(errorMessage);
      }

      const data = await response.json();

      // Check if the data contains "No data found" message
      if (data.message) {
        const container = document.getElementById("fleetMovement-activeTime");
        const container2 = document.getElementById("fleetMovement-idleTime");
        if (container) {
          container.textContent = data.message;
          container2.textContent = data.message;
        }
      } else {
        // Plot Active Time and Idle Time (assumed plotPieChart function exists)
        plotPieChart(data, "fleetMovement-activeTime", "Movement Time");
        plotPieChart(data, "fleetMovement-idleTime", "Stoppage Time");
      }
    } catch (error) {
      console.error("Error fetching Active Time Data:", error);
      const container = document.getElementById("fleetMovement-activeTime");
      if (container) {
        container.textContent = data;
      }
    }
  };

  function plotInsufficientChargingData(data, containerId, model) {
    const chartContainer = document.getElementById(containerId);
    if (!chartContainer) {
      console.error("Chart container not found.");
      return;
    }

    // Prepare the data structure
    const dateModelMap = {};

    data.forEach((entry) => {
      const date = formatedDate(entry.Date); // Format date to show with suffix
      const model = entry.Model;

      if (!dateModelMap[date]) {
        dateModelMap[date] = {
          count: 0,
          models: [],
        };
      }

      dateModelMap[date].count += 1; // Increment count
      dateModelMap[date].models.push(model); // Add model to the list
    });

    // Prepare data for plotting
    const dates = Object.keys(dateModelMap).sort(
      (a, b) => new Date(a) - new Date(b) // Sort dates in ascending order
    );

    // If you want to sort in descending order, change the above line to:
    // const dates = Object.keys(dateModelMap).sort(
    //   (a, b) => new Date(b) - new Date(a) // Sort dates in descending order
    // );

    const counts = dates.map((date) => dateModelMap[date].count);
    const hoverTexts = dates.map((date) => {
      const models = dateModelMap[date].models;
      const formattedModels = [];
      for (let i = 0; i < models.length; i += 4) {
        formattedModels.push(models.slice(i, i + 4).join(", "));
      }
      return formattedModels.join("<br>"); // Use HTML line break for new lines
    });

    // Dynamic y-axis settings based on model selection
    const yAxisSettings = model
      ? { dtick: 1, range: [0, Math.max(...counts) + 1] }
      : { dtick: 5, range: [0, Math.max(...counts) + 5] };

    // Create the bar chart
    const trace = {
      x: dates,
      y: counts,
      type: "bar",
      hoverinfo: "text",
      text: [],
      hovertext: hoverTexts,
      marker: {
        color: "#fc8403",
      },
    };

    const layout = {
      title: {
        text: "Charger removed before reaching 90% SOC", // Title of the chart
        font: {
          size: 18, // Optional: adjust title font size
          family: "Manrope", // Optional: adjust font family
        },
        xref: "paper", // Optional: this centers the title
        x: 0.5, // Centers the title horizontally
        y: 0.98, // Adjusts the vertical position (near the top)
        yanchor: "top", // Anchors the title to the top
      },
      xaxis: {
        title: "Date",
        tickangle: -45,
      },
      yaxis: {
        title: {
          text: "<b>Number of Models</b>", // Bold y-axis title
          font: {
            size: 14, // Optional: adjust font size
          },
        },
        dtick: yAxisSettings.dtick,
        range: yAxisSettings.range,
      },
      hovermode: "closest",
    };

    Plotly.newPlot(chartContainer, [trace], layout);
  }

  function plotPieChart(data, containerId, timeType) {
    const timeData = data.filter((item) => item.TimeType === timeType);

    const labels = timeData.map((item) => item.Interval);
    const values = timeData.map((item) => item.Hours);

    // Function to determine color based on Interval
    const getColor = (interval) => {
      if (interval === ">15 hours") return "#EE6666";
      if (interval === "0 hours") return "#5470C6";
      if (interval === "0-5 hours") return "#91CC75";
      if (interval === "10-15 hours") return "#FAC858";
      if (interval === "5-10 hours") return "#FAC858";
      return "#FFFFFF"; // Default color if none match
    };

    // Create an array of colors based on the labels
    const colors = labels.map(getColor);

    const pieData = [
      {
        values: values,
        labels: labels,
        type: "pie",
        textinfo: "percentage",
        insidetextorientation: "radial",
        marker: {
          colors: colors, // Apply colors to the pie chart
        },
      },
    ];

    const layout = {
      showlegend: true,
      legend: {
        orientation: "h", // Horizontal orientation
        yanchor: "bottom", // Anchor to the bottom
        y: -0.2, // Position below the chart
        xanchor: "center", // Center the legend
        x: 0.5, // Center the legend horizontally
        font: {
          size: 10, // Adjust font size of the legend
        },
      },
      margin: {
        l: 0, // Left margin
        r: 0, // Right margin
        t: 0, // Top margin
        b: 100, // Bottom margin
      },
    };

    Plotly.newPlot(containerId, pieData, layout);
  }

  const plotStackedBarChart = (data, containerId) => {
    const chartContainer = document.getElementById(containerId);
    if (!chartContainer) {
      console.error("Chart container not found.");
      return;
    }

    const seriesData = {};
    const categories = new Set();
    let totalDistance = 0;

    data.forEach((item) => {
      if (!item) return; // Skip null or undefined items

      const date = item.Date;
      if (!date) return; // Skip items without a date

      const distance = parseFloat(item.Distance_km);
      if (isNaN(distance)) return; // Skip items with invalid distance

      const dateObject = new Date(date);
      if (isNaN(dateObject.getTime())) {
        console.warn("Skipping invalid date for item:", item);
        return;
      }

      const formattedDate = String(dateObject.getUTCDate()).padStart(2, "0");
      const device = item.Device || "Unknown Device";

      totalDistance += distance;
      if (!seriesData[device]) {
        seriesData[device] = [];
      }
      seriesData[device].push({ x: formattedDate, y: distance });
      categories.add(formattedDate);
    });

    const colors = [
      "#00CC96",
      "#FF4560",
      "#008FFB",
      "#775DD0",
      "#FEB019",
      "#33B5E5",
      "#FFA07A",
      "#44D7A8",
      "#9C69AB",
      "#FFC107",
    ];

    const series = Object.keys(seriesData).map((device, index) => {
      return {
        name: device,
        x: categories,
        y: seriesData[device].map((dp) => dp.y),
        type: "bar",
        stackgroup: "one", // Add this to stack the bars
        marker: {
          color: colors[index % colors.length],
        },
      };
    });

    const layout = {
      title: `Total Distance Travelled: ${totalDistance.toFixed(2)}`,
      xaxis: { title: "Date" },
      yaxis: {
        title: {
          text: "Distance (km)",
          font: {
            size: 14,
            weight: "bold", // This will make the y-axis title bold
          },
        },
      },
      barmode: "stack", // Add this to stack the bars
    };

    Plotly.newPlot(chartContainer, series, layout);
  };

  const plotBarChart = (data, containerId) => {
    const chartContainer = document.getElementById(containerId);
    if (!chartContainer) {
      console.error("Chart container not found.");
      return;
    }

    const modelData = {};
    const dates = [];

    const formatDate = (date) => {
      const day = date.getDate();
      const suffix =
        day % 10 === 1 && day !== 11
          ? "st"
          : day % 10 === 2 && day !== 12
          ? "nd"
          : day % 10 === 3 && day !== 13
          ? "rd"
          : "th";
      return `${day}${suffix}`;
    };

    data.forEach((item) => {
      const date = new Date(item?.Date);
      if (isNaN(date)) {
        console.warn("Skipping invalid date for item:", item);
        return;
      }

      const formattedDate = formatDate(date);
      const modelName = item?.Model || "Unknown Model";
      const ecr = typeof item?.ECR === "number" ? item.ECR : 0;

      if (!modelData[modelName]) {
        modelData[modelName] = {};
      }
      modelData[modelName][formattedDate] =
        (modelData[modelName][formattedDate] || 0) + ecr;

      if (!dates.includes(formattedDate)) {
        dates.push(formattedDate);
      }
    });

    const colors = [
      "#00CC96",
      "#FF4560",
      "#008FFB",
      "#775DD0",
      "#FEB019",
      "#33B5E5",
      "#FFA07A",
      "#44D7A8",
      "#9C69AB",
      "#FFC107",
    ];

    const series = Object.keys(modelData).map((modelName, index) => {
      const data = dates.map((date) => modelData[modelName][date] || 0);
      return {
        name: modelName.toUpperCase(),
        x: dates,
        y: data,
        type: "bar",
        stackgroup: "one", // Add this to stack the
        marker: {
          color: colors[index % colors.length],
        },
      };
    });

    const layout = {
      // title: "ECR by Model",
      xaxis: { title: "Date" },
      yaxis: {
        title: "kWh/km",
        titlefont: {
          family: "Manrope, sans-serif", // You can set the font family if needed
          size: 14, // Set the font size
          color: "#000", // Set the font color
          weight: "bold", // Make the font bold
        },
      },
      barmode: "stack", // Add this to stack the bars
    };

    Plotly.newPlot(chartContainer, series, layout);
  };

  const handleDischargeData = (data, chartContainer) => {
    const labels = [
      ...new Set(data.map((item) => new Date(item.Date).getDate())),
    ];

    const seriesData = Array(labels.length).fill(0);
    const modelMap = {};

    data.forEach((item) => {
      const date = new Date(item.Date).getDate();
      const soc = item["A_SOC_lt_20"];
      const count = item.Count;

      if (isNaN(count) || soc !== "Yes") return;

      const index = labels.indexOf(date);
      if (index !== -1) {
        seriesData[index] += count;
        if (!modelMap[index]) modelMap[index] = new Set();
        item.Model.split(", ").forEach((model) =>
          modelMap[index].add(model.trim())
        );
      }
    });

    const dataToPlot = [
      {
        x: labels,
        y: seriesData,
        type: "bar",
        name: "Vehicles SOC<20%",
        marker: { color: "#fd5c63" },
        hovertemplate: labels.map((label, index) => {
          return `Date: ${formatDate(
            new Date(new Date().setDate(label))
          )}<br>Count: ${seriesData[index]}<br>Models: ${
            Array.from(modelMap[index] || []).join(", ") || "N/A"
          }`;
        }),
      },
    ];

    const layout = {
      outerHeight: "100%",
      title: {
        text: "Vehicle's Running below 20% SOC", // Title of the chart
        font: {
          size: 18, // Optional: adjust title font size
          family: "Manrope", // Optional: adjust font family
        },
        xref: "paper", // Optional: this centers the title
        x: 0.5, // Centers the title horizontally
        y: 0.98, // Adjusts the vertical position (near the top)
        yanchor: "top", // Anchors the title to the top
      },
      xaxis: {
        title: "Date",
        tickvals: labels,
        ticktext: labels.map((label) =>
          formatDate(new Date(new Date().setDate(label)))
        ),
        tickangle: -45,
      },
      yaxis: {
        title: {
          text: "Number of Models",
          font: {
            size: 14, // You can adjust the size as needed
            color: "black", // Optional: specify the color
            family: "Manrope", // Optional: specify the font family
            weight: "bold", // This makes the title bold
          },
        },
        tickmode: "linear",
        dtick: 1, // Set y-axis intervals to 1 unit
        range: [0, Math.max(...seriesData) + 1],
      },
      hovermode: "closest",
      // title: "Vehicles with SOC<20%",
    };

    Plotly.newPlot(chartContainer, dataToPlot, layout);
  };

  const handleChargingData = (data, chartContainer) => {
    const fastModels = {};
    const slowModels = {};
    const socData = {};

    data.forEach((entry) => {
      const model = entry.Model;
      const chargeType = entry.Charge_Type;
      const duration = entry.Charge_Duration;

      if (!model || !chargeType || !duration) return;

      // Convert Charge Duration from "HH:MM:SS hours" to hours
      const durationParts = duration.split(":");
      const hours = parseInt(durationParts[0], 10);
      const minutes = parseInt(durationParts[1], 10);
      const totalHours = hours + minutes / 60;

      if (chargeType === "Fast") {
        fastModels[model] = (fastModels[model] || 0) + totalHours;
        socData[model] = socData[model] || { startSOC: [], endSOC: [] };
        socData[model].startSOC.push(entry.Start_SOC);
        socData[model].endSOC.push(entry.End_SOC);
      } else if (chargeType === "Slow") {
        slowModels[model] = (slowModels[model] || 0) + totalHours;
        socData[model] = socData[model] || { startSOC: [], endSOC: [] };
        socData[model].startSOC.push(entry.Start_SOC);
        socData[model].endSOC.push(entry.End_SOC);
      }
    });

    const models = [
      ...new Set([...Object.keys(fastModels), ...Object.keys(slowModels)]),
    ].sort(); // Sort the models alphabetically

    const fastTimes = models.map((model) =>
      fastModels[model] ? Math.round(fastModels[model] * 10) / 10 : 0
    );
    const slowTimes = models.map((model) =>
      slowModels[model] ? Math.round(slowModels[model] * 10) / 10 : 0
    );

    const trace1 = {
      x: models,
      y: fastTimes,
      name: "Fast Charging",
      type: "bar",
      marker: { color: "#31367d" },
    };

    const trace2 = {
      x: models,
      y: slowTimes,
      name: "Slow Charging",
      type: "bar",
      marker: { color: "#636EFA" },
    };

    const layout = {
      barmode: "stack",
      xaxis: {
        title: "Model",
        tickangle: -50,
      },
      yaxis: {
        title: "Time (hours)",
        titlefont: {
          family: "Manrope",
          size: 16,
          color: "#000",
          weight: "bold", // This will make the title bold
        },
      },
      hovermode: "closest",
    };

    const config = {
      responsive: true,
    };

    Plotly.newPlot(chartContainer, [trace1, trace2], layout, config);
  };

  const formatedDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const suffix =
      day % 10 === 1 && day !== 11
        ? "st"
        : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
        ? "rd"
        : "th";
    return `${day}${suffix}`;
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const suffix =
      day % 10 === 1 && day !== 11
        ? "st"
        : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
        ? "rd"
        : "th";
    return `${day}${suffix}`;
  };

  return (
    <>
      <div className="fleetMovement-header-container">
        <p>Summary</p>
        <p style={{ textAlign: "center", marginTop: "-45px" }}>
          Fleet Movement
        </p>
      </div>
      <div className="fleetMovement-summary">
        <div className="header-row">
          <h5>Fleet Size</h5>
          <h5>Distance</h5>
          <h5>Charging Units</h5>
          <h5>Carbon Saved</h5>
          <h5>Running time</h5>
          <h5>Idle Time</h5>
          {/* <h5>Fleet Utilization</h5>
          <h5>Service hours</h5>
          <h5>Service Due</h5> */}
        </div>
        <div className="value-row">
          <p>{FleetSize}</p>
          <p>{summaryData.total_distance || "Cal.."} km</p>
          <p>{summaryData.Charging_units || "Cal.."} Kwh</p>
          <p>{summaryData.Co2_saved || "Cal.."} Kg</p>
          <p>{summaryData.total_driving_time || "Cal.."} hrs</p>
          <p>{summaryData.total_idle_time || "Cal.."} hrs</p>
          {/* <p>Value 7</p>
          <p>Value 8</p>
          <p>Value 9</p> */}
        </div>
      </div>
      <div className="fleetMovement-selection-container">
        <p className="fleetMovement-title">Fleet Owner</p>
        <select
          className="fleetMovement-dropdown-owner"
          value={selected.owner}
          onChange={handleOwnerChange}
        >
          <option>Select Fleet Owner</option>
          {data.fleetOwners.map((owner, index) => (
            <option key={index} value={owner.name}>
              {owner.name}
            </option>
          ))}
        </select>

        <p className="fleetMovement-title">Group</p>
        <select
          className="fleetMovement-dropdown-group"
          value={selected.group}
          onChange={handleGroupChange}
        >
          <option value="">Select Group</option>
          {data.fleetOwners
            .find((owner) => owner.name === selected.owner)
            ?.group.map((group, index) => (
              <option key={index} value={group.name}>
                {group.name}
              </option>
            ))}
        </select>

        <p className="fleetMovement-title">Model</p>
        <select
          className="fleetMovement-dropdown-model"
          value={selected.model}
          onChange={handleModelChange}
        >
          <option value="">Select Model</option>
          {filteredModels?.map((model, index) => (
            <option key={index} value={model}>
              {model}
            </option>
          ))}
        </select>
        <p className="fleetMovement-title">Date Range</p>
        <input
          type="text"
          ref={dateRangeRef}
          className="date-range-picker"
          placeholder="Select Date Range"
          readOnly
        />
        <button className="fleetMovement-submission" onClick={handleClick}>
          Submit
        </button>
      </div>

      <div className="fleetMovement-charts">
        <div className="fleetMovement-container">
          <div className="FM-chart-container">
            <h2>Active Time</h2>
            <div id="fleetMovement-activeTime" />
          </div>
          <div className="FM-chart-container">
            <h2>Idle Time</h2>
            <div id="fleetMovement-idleTime" />
          </div>
        </div>

        <div className="fleetMovement-container">
          <div className="FM-single-chart-container">
            <h2>Total Distance Travelled</h2>
            <div id="fleetMovement-totalDistance" />
          </div>
        </div>
        <div className="fleetMovement-container">
          <div className="FM-single-chart-container">
            <h2>Charging Units</h2>
            <div id="fleetMovement-chargingUnits" />
          </div>
        </div>
        <div className="fleetMovement-container">
          <div className="FM-single-chart-container">
            <h2>Energy Consumption</h2>
            <div id="fleetMovement-energyConsumption" />
          </div>
        </div>
        {/* <div className="fleetMovement-container">
          <div className="FM-single-chart-container">
            <h2>Deep Discharge</h2>
            <div id="fleetMovement-depthOfDischarge" />
          </div>
        </div>

        <div className="fleetMovement-container">
          <div className="FM-single-chart-container">
            <h2>Insufficient Charging</h2>
            <div id="fleetMovement-InsufficientCharging" />
          </div>
        </div> */}
        {/* <div className="fleetMovement-container">
          <div className="FM-single-chart-container">
            <h2>Vehicle Activity</h2>
            <div id="fleetMovement-vehicleActivity" />
          </div>
        </div>
        <div className="fleetMovement-container">
          <div className="FM-single-chart-container">
            <h2>Driving Style Summary</h2>
            <div id="fleetMovement-drivingStyleSummary" />
          </div>
        </div> */}
      </div>
    </>
  );
}

export default FleetMovement;
