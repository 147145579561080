import React, { useState } from "react";
import "./DriverManagement.css";
import { data } from "../DropdownData";
import editIcon from "../../assets/edit.png";
import deleteIcon from "../../assets/delete.png";

function DriverManagement() {
  const [isFormVisible, setFormVisible] = useState(false);
  const [selectedFleet, setSelectedFleet] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
    if (!isFormVisible) {
      setEditingIndex(null);
      resetForm();
    }
  };

  const handleFleetChange = (e) => {
    const fleetName = e.target.value;
    setSelectedFleet(fleetName);
    const selectedGroup = data.fleetOwners[0].group.find(
      (group) => group.name === fleetName
    );
    setVehicles(selectedGroup ? selectedGroup.models : []);
  };

  const resetForm = () => {
    setName("");
    setSelectedFleet("");
    setVehicles([]);
    setContact("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      name,
      fleet: selectedFleet,
      vehicle: vehicles.length > 0 ? vehicles[0] : "",
      contact,
    };

    if (editingIndex !== null) {
      // Update existing driver
      const updatedDrivers = [...drivers];
      updatedDrivers[editingIndex] = formData;
      setDrivers(updatedDrivers);
      setEditingIndex(null);
    } else {
      // Add new driver
      setDrivers([...drivers, formData]);
    }

    alert(editingIndex !== null ? "Driver Updated" : "Driver Added");
    toggleFormVisibility();
    resetForm();
  };

  const handleEdit = (index) => {
    const driverToEdit = drivers[index];
    setName(driverToEdit.name);
    setSelectedFleet(driverToEdit.fleet);
    setVehicles([driverToEdit.vehicle]);
    setContact(driverToEdit.contact);
    setEditingIndex(index);
    setFormVisible(true);
  };

  const handleDelete = (index) => {
    if (window.confirm("Are you sure you want to delete this driver?")) {
      const updatedDrivers = drivers.filter((_, i) => i !== index);
      setDrivers(updatedDrivers);
    }
  };

  return (
    <>
      <div className="DM-header-container">
        <p>Fleet Management</p>
        <p style={{ textAlign : "center" , marginTop : "-55px"}}>Driver Management</p>
      </div>
      <button className="DM-button" onClick={toggleFormVisibility}>
        {isFormVisible ? "Close Form" : "Add Driver"}
      </button>
      <div className="DM-table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Fleet</th>
              <th>Vehicle</th>
              <th>Contact</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {drivers.map((driver, index) => (
              <tr key={index}>
                <td>{driver.name}</td>
                <td>{driver.fleet}</td>
                <td>{driver.vehicle}</td>
                <td>{driver.contact}</td>
                <td>
                  <img
                    src={deleteIcon}
                    alt="Delete"
                    style={{
                      width: "15px",
                      height: "15px",
                      cursor: "pointer",
                      marginLeft: "10px",
                    }}
                    onClick={() => handleDelete(index)}
                  />
                  <img
                    src={editIcon}
                    alt="Edit"
                    style={{
                      marginLeft: "20px",
                      width: "15px",
                      height: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEdit(index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isFormVisible && (
        <form className="DM-form" onSubmit={handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              name="name"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </label>
          <br />
          <label>
            Fleet
            <select
              style={{ marginLeft: "62px" }}
              onChange={handleFleetChange}
              value={selectedFleet}
            >
              <option value="">Select Fleet</option>
              {data.fleetOwners[0].group.map((group, index) => (
                <option key={index} value={group.name}>
                  {group.name}
                </option>
              ))}
            </select>
          </label>
          <br />
          <label>
            Vehicle
            <select style={{ marginLeft: "42px" }}>
              <option value="">Select Vehicle</option>
              {vehicles.map((vehicle, index) => (
                <option key={index} value={vehicle}>
                  {vehicle}
                </option>
              ))}
            </select>
          </label>
          <br />
          <label>
            Contact
            <input
              type="tel"
              name="mobile"
              placeholder="Mobile number"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              required
              maxLength="10"
              pattern="[0-9]{10}"
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9]/g, "")
                  .slice(0, 10);
              }}
              style={{ marginLeft: "35px" }}
            />
          </label>
          <br />
          <button type="submit">Create</button>
        </form>
      )}
    </>
  );
}

export default DriverManagement;
