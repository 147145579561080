import React, { useState } from "react";
import "./FleetM.css";
import { getAccessNames } from "./accessNames";
import Select from "react-select";
import editIcon from "../../assets/edit.png";
import deleteIcon from "../../assets/delete.png";

function FleetM() {
  const accessNames = getAccessNames();
  const vehicleLots = ["MBMT", "UMC", "Aeromall", "Uber"];
  const [isFormVisible, setFormVisible] = useState(false);
  const [selectedVehicleLots, setSelectedVehicleLots] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    pinCode: "",
    email: "",
    password: "",
    userId: "",
    accessName: "",
  });

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  const handleVehicleLotsSelect = (selectedOptions) => {
    setSelectedVehicleLots(selectedOptions);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleEdit = (index) => {
    const dataToEdit = tableData[index];
    setFormData({
      name: dataToEdit.name,
      companyName: dataToEdit.companyName,
      pinCode: dataToEdit.pinCode,
      email: dataToEdit.email,
      password: dataToEdit.password,
      userId: dataToEdit.userId,
      accessName: dataToEdit.accessName,
    });
    setSelectedVehicleLots(
      dataToEdit.selectedVehicleLots
        .split(", ")
        .map((lot) => ({ value: lot, label: lot }))
    );
    setEditingIndex(index);
    setFormVisible(true);
  };

  const handleDelete = (index) => {
    if (window.confirm("Are you sure you want to delete this entry?")) {
      setTableData((prevData) => prevData.filter((_, i) => i !== index));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all fields are filled and at least one vehicle lot is selected
    const allFieldsFilled = Object.values(formData).every(
      (field) => field.trim() !== ""
    );
    const isVehicleLotsSelected = selectedVehicleLots.length > 0;

    if (!allFieldsFilled || !isVehicleLotsSelected) {
      alert("Please fill in all fields and select at least one vehicle lot.");
      return; // Prevent form submission
    }

    const newData = {
      ...formData,
      selectedVehicleLots: selectedVehicleLots
        .map((option) => option.value)
        .join(", "),
      createdOn: new Date().toLocaleDateString(),
      numberOfVehicles: selectedVehicleLots.length,
    };
    if (editingIndex !== null) {
      // Update existing entry
      setTableData((prevData) =>
        prevData.map((item, index) => (index === editingIndex ? newData : item))
      );
      setEditingIndex(null);
    } else {
      // Add new entry
      setTableData((prevData) => [...prevData, newData]);
    }

    setFormVisible(false);
    setFormData({
      name: "",
      companyName: "",
      pinCode: "",
      email: "",
      password: "",
      userId: "",
      accessName: "",
    });
    setSelectedVehicleLots([]);
  };

  const vehicleLotOptions = vehicleLots.map((lot) => ({
    value: lot,
    label: lot,
  }));

  return (
    <>
      <div className="fleetUser-header-container">
        <p>Fleet Management</p>
        <p style={{ textAlign : "center" , marginTop : "-55px"}}>Fleet User</p>
      </div>
      <button className="fleetUser-button" onClick={toggleFormVisibility}>
        {isFormVisible ? "Close Form" : "Add Fleet User"}
      </button>
      {isFormVisible && (
        <form className="fleetUser-form" onSubmit={handleSubmit}>
          <br />
          <label>
            Name
            <input
              type="text"
              name="name"
              placeholder="Enter Name"
              value={formData.name}
              onChange={handleInputChange}
            />
            <br />
          </label>
          <br />
          <label>
            Company Name
            <input
              type="text"
              name="companyName"
              placeholder="Enter company name"
              style={{ width: "156px", marginLeft: "72px" , marginRight: "40px"}}
              value={formData.companyName}
              onChange={handleInputChange}
            />
            Pin Code
            <input
              type="number"
              name="pinCode"
              placeholder="Enter"
              style={{ width: "156px", marginLeft: "35px" }}
              value={formData.pinCode}
              onChange={handleInputChange}
            />
            <br />
          </label>
          <br />
          <label>
            Email
            <input
              type="email"
              name="email"
              placeholder="Enter Email"
              style={{ marginLeft: "160px" }}
              value={formData.email}
              onChange={handleInputChange}
            />
            <br />
          </label>
          <br />
          <label>
            Set Password
            <input
              type="password"
              name="password"
              placeholder="Enter Password"
              style={{ marginLeft: "90px" }}
              value={formData.password}
              onChange={handleInputChange}
            />
            <br />
          </label>
          <br />
          <label>
            Vehicle Lot
            <Select
              className="fleetUser-select"
              isMulti
              isSearchable={false}
              value={selectedVehicleLots}
              options={vehicleLotOptions}
              onChange={handleVehicleLotsSelect}
            />
          </label>
          <br />
          <br />
          <label>
            User ID
            <input
              type="text"
              name="userId"
              placeholder="Enter"
              style={{ width: "470.177px", marginLeft: "140px" }}
              value={formData.userId}
              onChange={handleInputChange}
            />
            <br />
          </label>
          <br />
          <label>
            Access Names
            <select
              name="accessName"
              className="fleetUser-dropdown"
              style={{ marginLeft: "77px" }}
              value={formData.accessName}
              onChange={handleInputChange}
            >
              <option value="">Select Access Names</option>
              {accessNames.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>
          <br />
          <button type="submit">Create</button>
        </form>
      )}
      <div className="fleetUser-table">
        <table>
          <thead>
            <tr>
              <th>Fleet User</th>
              <th>Fleet</th>
              <th>Created On</th>
              <th>Features</th>
              <th>Number Of Vehicles</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((data, index) => (
              <tr key={index}>
                <td>{data.name}</td>
                <td>{data.selectedVehicleLots}</td>
                <td>{data.createdOn}</td>
                <td>{data.accessName}</td>
                <td>{data.numberOfVehicles}</td>
                <td>
                  <img
                    src={deleteIcon}
                    alt="Delete"
                    style={{
                      width: "15px",
                      height: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDelete(index)}
                  />
                  <img
                    src={editIcon}
                    alt="Edit"
                    style={{
                      marginLeft: "20px",
                      width: "15px",
                      height: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEdit(index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default FleetM;
