import React, { useState, useRef, useEffect } from "react";
import "../Pages/Trails.css";
import { data } from "../Pages/DropdownData";
import L from "leaflet";
import busImage from "../assets/bus.png";
import expandIcon from "../assets/Expand.jpg";
import Plot from "react-plotly.js"; // Import Plotly components  

function Trails() {
  const [group, setGroup] = useState("MBMT");
  const [model, setModel] = useState("");
  const [date, setDate] = useState("");
  const [expandMap, setExpandMap] = useState(false);
  const [tripData, setTripData] = useState(null);
  const [chartData, setChartData] = useState({   
    cellVoltage: [],
    packCurrent: [],
    cellTemperature: [], 
    soc: [],
    speed: [],
    time: [],
  });
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const backendUrl = "https://eka-connect.com/";

  const handleGroupChange = (e) => {
    setGroup(e.target.value);
    setModel("");
  };

  const handleModelChange = (e) => setModel(e.target.value);

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setDate(selectedDate);
    fetchData(selectedDate);
  };

  const getModelOptions = () => {
    const selectedGroup = data.fleetOwners[0].group.find(
      (g) => g.name.toLowerCase() === group.toLowerCase()
    );
    return selectedGroup ? (
      selectedGroup.models.map((m) => (
        <option key={m} value={m}>
          {m}
        </option>
      ))
    ) : (
      <option value="">Choose</option>
    );
  };

  const fetchData = (selectedDate) => {
    if (!model || !selectedDate) {
      return;
    }
    const Formattedmodel = model.replace(/-/, "");
    const apiURL = `${backendUrl}Trails/Display_map/?vehicle=${Formattedmodel.toLowerCase().replace(
      /-/g,
      ""
    )}&date=${selectedDate.replace(/-/g, "/")}`;
    console.log(apiURL);

    fetch(apiURL)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setTripData((prevData) => ({
            ...prevData,
            ...data[0],
          }));
          initializeMap(data);
        } else {
          // alert("No data available for the selected model and date.");
        }
      })
      .catch((error) => {
        console.error("Error fetching route data:", error);
      });
  };

  const fetchFaultData = async (model, selectedDate) => {
    const faultApiUrl = `${backendUrl}Trails/Individual_faults/?vehicle=${model}&date=${selectedDate.replace(
      /-/g,
      "/"
    )}`;
    console.log(faultApiUrl);

    try {
      const response = await fetch(faultApiUrl);
      const faultData = await response.text();
      const faultStatus = faultData || "No";

      setTripData((prevData) => ({
        ...prevData,
        fault: faultStatus,
      }));
    } catch (error) {
      console.error("Error fetching fault data:", error);
      setTripData((prevData) => ({
        ...prevData,
        fault: "Error fetching fault data",
      }));
    }
  };

  const handleModelAndDateChange = () => {
    if (model && date) {
      fetchFaultData(model, date);
    }
  };

  useEffect(() => {
    handleModelAndDateChange();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, date]);

  const initializeMap = (routeData) => {
    if (mapRef.current) {
      mapRef.current.remove();
    }

    const firstCoord = routeData[0].slice(0, 2);
    const map = L.map(mapContainerRef.current, {
      center: firstCoord,
      zoom: 15,
    });
    mapRef.current = map;

    map.invalidateSize();
    map.zoomControl.remove();

    L.control
      .zoom({
        position: "bottomright",
      })
      .addTo(map);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 18,
    }).addTo(map);

    const busIcon = L.divIcon({
      className: "custom-bus-icon",
      html: `<img src=${busImage} style="transform: rotate(0deg);">`,
      iconSize: [30, 30],
      iconAnchor: [15, 15],
    });

    const busMarker = L.marker(firstCoord, { icon: busIcon }).addTo(map);

    const coveredRoute = L.polyline([], { color: "blue" }).addTo(map);
    const remainingRoute = L.polyline(
      routeData.map((p) => [p[0], p[1]]),
      { color: "lightgray" }
    ).addTo(map);

    let index = 0;
    const moveBus = () => {
      if (index < routeData.length - 1) {
        const currentData = routeData[index];
        const nextData = routeData[index + 1];
        const currentLatLng = [currentData[0], currentData[1]];
        const angle = calculateAngle(currentLatLng, [nextData[0], nextData[1]]);

        const currentTime = currentData[2];
        const currentSpeed = currentData[3];
        const currentSOC = currentData[4];
        const currentBatteryTemp = currentData[9];

        const A_maxCellVoltage = currentData[5];
        const B_maxCellVoltage = currentData[6];
        const maxCellVoltage = Math.max(A_maxCellVoltage, B_maxCellVoltage);

        const A_packCurrent = currentData[7];
        const B_packCurrent = currentData[8];
        const maxBatteryCurrent = Math.max(A_packCurrent, B_packCurrent);

        busMarker.setLatLng(currentLatLng);

        const busElement = busMarker.getElement();
        if (busElement) {
          busElement.querySelector(
            "img"
          ).style.transform = `rotate(${angle}deg)`;
        }

        const popupContent = `<b>Time:</b> ${currentTime}<br><b>Speed:</b> ${currentSpeed} km/h<br><b>SOC:</b> ${currentSOC} %<br>`;
        busMarker.bindPopup(popupContent);

        busMarker.on("mouseover", function () {
          this.openPopup();
        });

        busMarker.on("mouseout", function () {
          this.closePopup();
        });

        coveredRoute.addLatLng(currentLatLng);
        const remainingLatLngs = remainingRoute.getLatLngs();
        remainingLatLngs.shift();
        remainingRoute.setLatLngs(remainingLatLngs);

        // Update trip data with current values
        setTripData((prevData) => ({
          ...prevData,
          soc: currentSOC,
          batteryTemperature: currentBatteryTemp,
          cellVoltage: maxCellVoltage,
          batteryCurrent: maxBatteryCurrent,
        }));

        // Update chart data
        setChartData((prevChartData) => ({
          cellVoltage: [...prevChartData.cellVoltage, maxCellVoltage],
          packCurrent: [...prevChartData.packCurrent, maxBatteryCurrent],
          cellTemperature: [
            ...prevChartData.cellTemperature,
            currentBatteryTemp,
          ],
          soc: [...prevChartData.soc, currentSOC],
          speed: [...prevChartData.speed, currentSpeed],
          time: [...prevChartData.time, currentTime],
        }));

        index++;
        setTimeout(moveBus, 6000);
      }
    };

    moveBus();
  };

  const calculateAngle = (coord1, coord2) => {
    const [lat1, lon1] = [
      (coord1[0] * Math.PI) / 180,
      (coord1[1] * Math.PI) / 180,
    ];
    const [lat2, lon2] = [
      (coord2[0] * Math.PI) / 180,
      (coord2[1] * Math.PI) / 180,
    ];

    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    const angle = (Math.atan2(y, x) * 180) / Math.PI;

    return (angle + 360) % 360;
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.invalidateSize();
    }
  }, [expandMap]);

  const toggleExpandMap = () => {
    setExpandMap(!expandMap);

    setTimeout(() => {
      if (mapRef.current) {
        mapRef.current.invalidateSize();
      }
    }, 300);
  };


  return (
    <>
      <div className="trails-header">
        <p>Trails</p>
      </div>

      <div className="selection-container">
        <div className="selection-item">
          <label>Fleet:</label>
          <select className="form-control" id="fleet-dropdown">
            <option value="transvolt">Transvolt</option>
          </select>
        </div>

        <div className="selection-item">
          <label>Group:</label>
          <select
            id="groupDropdown"
            className="form-control"
            value={group}
            onChange={handleGroupChange}
          >
            <option value="">Select Group</option>
            {data.fleetOwners[0].group.map((g) => (
              <option key={g.name} value={g.name.toLowerCase()}>
                {g.name}
              </option>
            ))}
          </select>
        </div>

        <div className="selection-item">
          <label>Model:</label>
          <select
            id="modelDropdown"
            className="form-control"
            value={model}
            onChange={handleModelChange}
          >
            <option value="">Select Group</option>
            {getModelOptions()}
          </select>
        </div>

        <div className="selection-item">
          <label>Date:</label>
          <input
            type="date"
            id="dateInput"
            className="form-control"
            value={date}
            onChange={handleDateChange}
          />
        </div>
      </div>

      <div id="fleet-charts-container" className={expandMap ? "expanded" : ""}>
        <div className="fleet-chart" id="fleet-chart-1">
          {/* <div className="summary-info">
            <p>
              <strong>Driver Score:</strong> 4.8 ⭐
            </p>
            <p>
              <strong>Retarder Status:</strong> OFF
            </p>
            <p>
              <strong>Retarder Time:</strong>0 hr 0 min
            </p>
            <p>
              <strong>Location:</strong>Pune
            </p>
          </div> */}

          <div className="trip-info">
            <h4>Trip Data</h4>
            {tripData ? (
              <div className="trip-data-grid">
                <div className="trip-data-item">
                  <span className="label">
                    <strong>SOC:</strong>
                  </span>{" "}
                  <span className="value">
                    {tripData.soc || ""} %
                  </span>
                </div>
                <div className="trip-data-item">
                  <span className="label">
                    <strong>SOH:</strong>
                  </span>{" "}
                  <span className="value">{tripData.soh} %</span>
                </div>
                <div className="trip-data-item">
                  <span className="label">
                    <strong>Range:</strong>
                  </span>{" "}
                  <span className="value">
                    {tripData.range ? tripData.range : "NO"}
                  </span>
                </div>
                <div className="trip-data-item">
                  <span className="label">
                    <strong>Cell Voltage:</strong>
                  </span>{" "}
                  <span className="value">
                    {tripData.cellVoltage || ""} V
                  </span>
                </div>
                <div className="trip-data-item">
                  <span className="label">
                    <strong>Battery Current:</strong>
                  </span>{" "}
                  <span className="value">
                    {tripData.batteryCurrent || ""} A
                  </span>
                </div>
                <div className="trip-data-item">
                  <span className="label">
                    <strong>Battery Temp:</strong>
                  </span>{" "}
                  <span className="value">
                    {tripData.batteryTemperature || ""} °C
                  </span>
                </div>
                <div className="trip-data-item">
                  <span className="label">
                    <strong>Fault:</strong>
                  </span>{" "}
                  <span className="value">
                    {tripData.fault
                      ? tripData.fault.replace(/"/g, "")
                      : "No fault"}{" "}
                  </span>
                </div>
                <div className="trip-data-item">
                  <span className="label">
                    <strong>Harsh Braking:</strong>
                  </span>{" "}
                  <span className="value">
                    {tripData.harshBraking ? "Yes" : "No"}
                  </span>
                </div>
                <div className="trip-data-item">
                  <span className="label">
                    <strong>Geofence Alert:</strong>
                  </span>{" "}
                  <span className="value">
                    {tripData.geofenceAlert ? "Triggered" : "No"}
                  </span>
                </div>
              </div>
            ) : (
              <p>No trip data available</p>
            )}
          </div>
        </div>

        <div
          className="fleet-chart"
          id="fleet-chart-2"
          ref={mapContainerRef}
          style={{ height: expandMap ? "78vh" : "500px", position: "relative" }}
        >
          {/* Placeholder for Fleet Chart 2 (Map) */}
          <div className="map-expand-icon" onClick={toggleExpandMap}>
            <img
              src={expandIcon}
              alt="Expand Map"
              style={{
                width: "30px",
                height: "30px",
                cursor: "pointer",
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: 1000,
              }}
            />
          </div>
        </div>
      </div>

      <div className="fleet-chart" id="fleet-chart-3">
        {/* Placeholder for Fleet Chart 3 */}
        <div className="fleet-realtime-charts">
          <div className="chart-container">
            <h3>Cell Voltage</h3>
            <Plot
              data={[
                {
                  x: chartData.time,
                  y: chartData.cellVoltage,
                  type: "line",
                },
              ]}
              layout={{
                width: 400,
                height: 400,
              }}
            />
          </div>
          <div className="chart-container">
            <h3>Pack Current</h3>
            <Plot
              data={[
                {
                  x: chartData.time,
                  y: chartData.packCurrent,
                  type: "line",
                },
              ]}
              layout={{
                width: 400,
                height: 400,
              }}
            />
          </div>
          <div className="chart-container">
            <h3>Cell Temperature</h3>
            <Plot
              data={[
                {
                  x: chartData.time,
                  y: chartData.cellTemperature,
                  type: "line",
                },
              ]}
              layout={{
                width: 400,
                height: 400,
              }}
            />
          </div>
          <div className="chart-container">
            <h3>SOC</h3>
            <Plot
              data={[
                {
                  x: chartData.time,
                  y: chartData.soc,
                  type: "line",
                  name: "SOC",
                },
              ]}
              layout={{
                width: 400,
                height: 400,
              }}
            />
          </div>
          <div className="chart-container">
            <h3>Speed</h3>
            <Plot
              data={[
                {
                  x: chartData.time,
                  y: chartData.speed,
                  type: "line",
                  name: "Speed",
                },
              ]}
              layout={{
                width: 400,
                height: 400,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Trails;
