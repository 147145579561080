import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Sidebar from "./Components/CustomSidebar";
import Fleet from "./Pages/Live/Fleet";
import "./App.css";
import Vehicle from "./Pages/Live/Vehicle";
import Home from "./Pages/Home";
import FleetMovement from "./Pages/Summary/Fleetmovement";
import HealthMonitoring from "./Pages/Summary/HealthMonitoring";
import Reports from "./Pages/Reports";
import Trails from "./Pages/Trails";
import FOTA from "./Pages/FOTA";
import Scheduler from "./Pages/Maintenance/Scheduler";
// import VOR from "./Pages/Maintenance/VOR";
import DiagnosticTree from "./Pages/Maintenance/DiagnosticTree";
// import Geofence from "./Pages/FleetManagement/Geofence";
import AccessManagement from "./Pages/FleetManagement/AccessManagement";
import DriverManagement from "./Pages/FleetManagement/DriverManagement";
import FleetM from "./Pages/FleetManagement/FleetM";
import VehicleAndFleet from "./Pages/FleetManagement/VehicleAndFleet";
import AlarmsAndFaults from "./Pages/FleetManagement/AlarmsAndFaults";
import Profile from "./Pages/Settings/Profile";
// import Theme from "./Pages/Settings/Theme";
// import UserManagement from "./Pages/Settings/UserManagement";
// import Developer from "./Pages/Settings/Developer";
import Login from "./Pages/Login";
import Loading from "./Pages/Loading";

const ProtectedRoute = ({ element, isLoggedIn }) => {
  return isLoggedIn ? element : <Navigate to="/" />;
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem("isLoggedIn") === "true";
  });
  const [username, setUsername] = useState(() => {
    return localStorage.getItem("username") || ""; // Retrieve username from localStorage
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem("isLoggedIn", isLoggedIn);
  }, [isLoggedIn]);

  useEffect(() => {
    localStorage.setItem("username", username); // Store username in localStorage
  }, [username]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            isLoggedIn ? (
              <Navigate to="/home" />
            ) : (
              <Login
                setIsLoggedIn={setIsLoggedIn}
                setLoading={setLoading}
                setUsername={setUsername}
              />
            )
          }
        />
        <Route
          path="/loading"
          element={
            loading ? (
              <Loading setLoading={setLoading} />
            ) : (
              <Navigate to="/home" />
            )
          }
        />
        <Route
          path="/*"
          element={
            <div className="side-bar">
              <Sidebar setIsLoggedIn={setIsLoggedIn} username={username} />{" "}
              {/* Pass username prop */}
              <div className="main-content">
                <Routes>
                  {[
                    { path: "/home", element: <Home /> },
                    // { path: "/live/fleet", element: <Fleet /> },
                    // { path: "/live/vehicle", element: <Vehicle /> },
                    {
                      path: "/summary/fleet_movement",
                      element: <FleetMovement />,
                    },
                    {
                      path: "/summary/health_monitoring",
                      element: <HealthMonitoring />,
                    },
                    { path: "/reports", element: <Reports /> },
                    { path: "/trails", element: <Trails /> },
                    { path: "/FOTA", element: <FOTA /> },
                    { path: "/maintenance/scheduler", element: <Scheduler /> },
                    // { path: "/maintenance/vor", element: <VOR /> },
                    // {
                    //   path: "/fleet_management/geofence",
                    //   element: <Geofence />,
                    // },
                    {
                      path: "/fleet_management/access_management",
                      element: <AccessManagement />,
                    },
                    {
                      path: "/fleet_management/driver_management",
                      element: <DriverManagement />,
                    },
                    { path: "/fleet_management/fleet", element: <FleetM /> },
                    {
                      path: "/fleet_management/vehicle_fleet",
                      element: <VehicleAndFleet />,
                    },
                    {
                      path: "/fleet_management/alarms_faults",
                      element: <AlarmsAndFaults />,
                    },
                    { path: "/settings/profile", element: <Profile /> },
                  ].map(({ path, element }) => (
                    <Route
                      key={path}
                      path={path}
                      element={
                        <ProtectedRoute
                          element={element}
                          isLoggedIn={isLoggedIn}
                        />
                      }
                    />
                  ))}
                  <Route path="*" element={<Navigate to="/home" />} />
                </Routes>
              </div>
            </div>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
