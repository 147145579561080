import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { SidebarData } from "./SidebarData";
import { FiMenu } from "react-icons/fi";
import "bootstrap-icons/font/bootstrap-icons.css";
import { AiOutlineBell } from "react-icons/ai";
import ekaLogo from "../assets/eka_logo.png";
import "./Sidebar.css";

const CustomSidebar = ({ setIsLoggedIn, username }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [iconToggled, setIconToggled] = useState(false);
  const [openProfile, setProfile] = useState(false);

  const handleMouseEnter = () => {
    if (collapsed && !iconToggled) {
      setCollapsed(false);
    }
  };

  const handleMouseLeave = () => {
    if (!collapsed && !iconToggled) {
      setCollapsed(true);
    }
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    setIconToggled(!iconToggled); // Toggle iconToggled state
  };

  const handleLogout = () => {
    setIsLoggedIn(false); // Update the login state
    localStorage.setItem("isLoggedIn", "false"); // Clear the local storage
  };

  return (
    <>
      {/* Header */}
      <div className="Sidebar-Header">
        <div className="menu-container">
          <FiMenu onClick={toggleSidebar} className="menu-icon" />
        </div>
        <img src={ekaLogo} alt="EKA Logo" className="heading_center" />
        <div className="right-icons">
          <AiOutlineBell className="bell-icon" />
          <div
            onClick={() => setProfile((prev) => !prev)}
            style={{ display: "flex", cursor: "pointer", position: "absolute" , top : "-26px", right: "25px" }}
          >
            <span className="UserDropDown">
              <i class="bi bi-person"></i>
            </span>
            <p>{username}</p>
          </div>
          {openProfile && (
            <div className="flex flex-col dropDownProfile">
              <ul className="flex flex-col gap-4 list-none">
                {/* <li>{username}</li> */}
                <li
                  onClick={handleLogout}
                  style={{ marginTop: "10px", cursor: "pointer" }}
                >
                  Logout
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* Sidebar */}
      <div
        className="sidebar-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Sidebar
          style={{
            width: collapsed ? "30px" : "340px",
            backgroundColor: "#E6EFF6", // Ensure background color is applied
          }}
          collapsed={collapsed}
          className="sidebar"
        >
          <Menu>
            {SidebarData.map((item, index) =>
              item.subNav ? (
                <SubMenu
                  key={index}
                  label={item.title}
                  icon={
                    <img src={item.icon} alt={item.title} className="icon" />
                  }
                  className="sub-menu"
                >
                  {item.subNav.map((subItem, subIndex) => (
                    <MenuItem
                      key={subIndex}
                      className="sub-menu-item"
                      component={<Link to={subItem.path} />}
                    >
                      {subItem.title}
                    </MenuItem>
                  ))}
                </SubMenu>
              ) : (
                <MenuItem
                  key={index}
                  icon={
                    <img src={item.icon} alt={item.title} className="icon" />
                  }
                  className="menu-item"
                  component={<Link to={item.path} />}
                >
                  {item.title}
                </MenuItem>
              )
            )}
          </Menu>
        </Sidebar>
      </div>
    </>
  );
};

export default CustomSidebar;
