import React, { useEffect, useRef, useState } from "react";
import "./Fleet.css";
import busImg from "../../assets/Bus_9M.png";
import pumaImg from "../../assets/EKA_Puma.png";
import busMapIcon from "../../assets/bus.png";
import pumaMapIcon from "../../assets/Puma.png";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Select, { components } from "react-select";
import Chart from "react-apexcharts";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const Fleet = () => {
  const [liveSummaryData, setLiveSummaryData] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState({
    Moving: true,
    Idle: true,
    Stopped: true,
    NOdata: true,
    unknown: true,
    NoGps: true,
  });
  const [pumaVehicles, setPumaVehicles] = useState([]);
  const markerRefs = useRef({});
  const [selectAll, setSelectAll] = useState(true);
  const mapRef = useRef();

  useEffect(() => {
    setLiveSummaryData({});
    fetchLiveSummaryData();
    fetchVehicleData();
    fetchPumaVehicleData(); // Add this function to fetch Puma data

    const intervalId = setInterval(() => {
      fetchLiveSummaryData();
      fetchVehicleData();
      fetchPumaVehicleData(); // Refresh Puma data every 60 seconds
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);
  const fetchPumaVehicleData = () => {
    const apiUrl = "http://3.109.136.31/Live/Live_summary/?model=Puma";
    let newData = setPumaVehicles([]);
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        newData = data;
        setPumaVehicles(newData);
      })
      .catch((error) =>
        console.error("Error fetching Puma vehicle data:", error)
      );
  };

  const fetchLiveSummaryData = () => {
    const apiUrl = "http://3.109.136.31/Live/Live_stats/";
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setLiveSummaryData(data);
      })
      .catch((error) => console.error("Error fetching Data:", error));
  };

  const fetchVehicleData = () => {
    const apiUrl = "http://3.109.136.31/Live/Live_summary/?model=Bus";
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setVehicles(data);
      })
      .catch((error) => console.error("Error fetching vehicle data:", error));
  };

  // Filter vehicles based on selected options
  // Filter vehicles based on selected options
  const filteredVehicles = vehicles.filter((vehicle) => {
    const statusKey = vehicle.status;

    // Check for Charging status
    const isCharging =
      vehicle.status === "Idle" && vehicle.charger_gun_detected2 === 1;

    // Include vehicles with checked statuses
    return (
      (selectedStatuses[statusKey] === true ||
        (isCharging && selectedStatuses.Charging)) &&
      (selectedVehicles.length === 0 ||
        selectedVehicles.some((selected) => selected.value === vehicle.id))
    );
  });

  const panToVehicle = (latitude, longitude) => {
    if (mapRef.current) {
      mapRef.current.setView([latitude, longitude], 20);
    }
  };

  const vehicleOptions = filteredVehicles.map((vehicle) => ({
    value: vehicle.id,
    label: vehicle.name,
  }));

  const CustomMultiValue = (props) => {
    const { data, index, getValue } = props;
    // eslint-disable-next-line no-unused-vars
    const selectedValues = getValue();

    if (index >= 2) {
      return null; // Hide the extra items
    }

    return (
      <components.MultiValue {...props}>{data.label}</components.MultiValue>
    );
  };

  const displayValue = () => {
    const count = selectedVehicles.length;
    if (count <= 2) {
      return selectedVehicles.map((option) => option.label).join(", ");
    }
    const fullText = `${selectedVehicles[0].label}, ${selectedVehicles[1].label}, and more`;
    return <span title={fullText}>{selectedVehicles[0].label}, ...</span>;
  };

  const handleStatusChange = (status) => {
    console.log("Toggling status:", status);
    setSelectedStatuses((prev) => {
      console.log("Previous state:", prev);
      return {
        ...prev,
        [status]: !prev[status], // Toggle the current status
      };
    });
  };

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedStatuses({
      Moving: newSelectAll,
      Idle: newSelectAll,
      Stopped: newSelectAll,
      NOdata: newSelectAll,
      unknown: newSelectAll,
      NoGps: newSelectAll,
      Charging: newSelectAll, // Include Charging if needed
    });
  };

  const countVehiclesByStatus = () => {
    const counts = {
      Moving: 0,
      Idle: 0,
      Stopped: 0,
      NOdata: 0,
      unknown: 0,
      NoGps: 0,
      Charging: 0,
    };

    pumaVehicles.map((vehicles) => {
      let StatusPuma;
      if (vehicles.ignition === 1) {
        StatusPuma = "Active";
      } else {
        StatusPuma = "Inactive";
      }

      if (StatusPuma === "Active") {
        counts.Moving++;
      } else {
        counts.NOdata++;
      }

      return counts;
    });

    vehicles.forEach((vehicle) => {
      if (vehicle.status === "Moving") counts.Moving++;
      else if (vehicle.status === "Idle") counts.Idle++;
      else if (vehicle.status === "Stopped") counts.Stopped++;
      else if (vehicle.status === "NOdata") counts.NOdata++;
      else if (vehicle.status === "unknown") counts.unknown++;
      else if (vehicle.status === "NoGps") counts.NoGps++;
      if (vehicle.status === "Idle" && vehicle.charger_gun_detected2 === 1) {
        counts.Charging++;
      }
    });

    return counts;
  };

  const vehicleCounts = countVehiclesByStatus();

  const calculateChargeDistribution = () => {
    const distribution = {
      "0-25": 0,
      "25-50": 0,
      "50-75": 0,
      "75-100": 0,
    };

    filteredVehicles.forEach((vehicle) => {
      const charge = vehicle.state_of_charge;
      if (charge >= 0 && charge < 25) {
        distribution["0-25"]++;
      } else if (charge >= 25 && charge < 50) {
        distribution["25-50"]++;
      } else if (charge >= 50 && charge < 75) {
        distribution["50-75"]++;
      } else if (charge >= 75 && charge <= 100) {
        distribution["75-100"]++;
      }
    });

    return distribution;
  };

  const chargeDistribution = calculateChargeDistribution();

  const chartOptions = {
    chart: {
      type: "pie",
    },
    labels: Object.keys(chargeDistribution).map((label) => `${label}%`),
    series: Object.values(chargeDistribution),
    colors: ["#DE3B40BA", "#FFE77DD6", "#C73EF9BF", "#62CD1499"],
    dataLabels: {
      enabled: false,
    },
  };

  const calculateTemperatureDistribution = () => {
    const distribution = {
      "20-24": 0,
      "25-28": 0,
      "29-35": 0,
      ">35": 0,
    };

    filteredVehicles.forEach((vehicle) => {
      const temperature = vehicle.battery_temperature;
      if (temperature >= 20 && temperature < 25) {
        distribution["20-24"]++;
      } else if (temperature >= 25 && temperature < 29) {
        distribution["25-28"]++;
      } else if (temperature >= 29 && temperature <= 35) {
        distribution["29-35"]++;
      } else if (temperature > 35) {
        distribution[">35"]++;
      }
    });

    return distribution;
  };

  const temperatureDistribution = calculateTemperatureDistribution();

  const temperatureChartOptions = {
    chart: {
      type: "pie",
    },
    labels: Object.keys(temperatureDistribution).map((label) => `${label}°C`),
    series: Object.values(temperatureDistribution),
    colors: ["#FFEB92", "#62CD1499", "#C73EF9BF", "#E66A6E"],
    dataLabels: {
      enabled: false,
    },
  };
  return (
    <>
      <div className="fleet-header-container">
        <p>Live</p>
        <p>Fleet</p>
      </div>
      <div className="fleet-live-summary">
        <div className="fleet-live-summary-headers">
          <p>Fleet Size</p>
          <p>Fleet Utilization</p>
          <p>Distance</p>
          <p>Charging Units</p>
          <p>Running Time</p>
          <p>Idle Time</p>
          <p>Charging Time</p>
          <p>Stoppage Time</p>
        </div>
        <div className="fleet-live-summary-values">
          <p>{liveSummaryData.Fleet_Size}</p>
          <p>{liveSummaryData.Fleet_Utilization}%</p>
          <p>{liveSummaryData.todayKms} Km</p>
          <p>{liveSummaryData.Units} units</p>
          <p>{liveSummaryData.todayMovementTime} hours</p>
          <p>{liveSummaryData.todayIdleTime} hours</p>
          <p>{liveSummaryData.charge_time} hours</p>
          <p style={{ color: "red" }}>{liveSummaryData.stoppage_time} hours</p>
        </div>
      </div>
      <div className="fleet-search-container">
        <label>
          <p style={{ marginTop: "20px", marginLeft: "10px" }}>Search</p>
          <Select
            isMulti
            className="fleet-search-container-selection"
            options={vehicleOptions}
            onChange={setSelectedVehicles}
            placeholder="Choose vehicles..."
            value={selectedVehicles}
            components={{ MultiValue: CustomMultiValue }}
            formatDisplayValue={displayValue}
          />
        </label>
      </div>
      <div className="fleet-status">
        <div className="fleet-container">
          <div id="fleet-map-container" className="fleet-map-container">
            <MapContainer
              ref={mapRef}
              center={[20.5937, 78.9629]}
              zoom={5}
              style={{ height: "100%", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <MarkerClusterGroup>
                {filteredVehicles.map((vehicle) => {
                  const lat = parseFloat(vehicle.latitude);
                  const lng = parseFloat(vehicle.longitude);
                  const heading = parseFloat(vehicle.heading);

                  if (lat && lng && !isNaN(heading)) {
                    // Create a rotating icon based on heading
                    const icon = L.divIcon({
                      className: "custom-icon", // Custom class to style the icon
                      html: `<img src="${busMapIcon}" style="transform: rotate(${heading}deg); width: 20px; height: 40px;" />`,
                      iconSize: [20, 40], // Set the icon size
                      iconAnchor: [15, 15], // Set the anchor to the center of the icon
                      popupAnchor: [1, -34],
                      shadowSize: [41, 41],
                    });

                    return (
                      <Marker
                        key={vehicle.id}
                        position={[lat, lng]}
                        icon={icon}
                        ref={(el) => (markerRefs.current[vehicle.id] = el)}
                      >
                        <Popup>
                          <div>
                            <h3>{vehicle.name}</h3>
                            <p>{vehicle.chassis_number}</p>
                            <p style={{ marginLeft: "15px" }}>
                              Speed: {vehicle.speedKph} Km/h
                            </p>
                          </div>
                        </Popup>
                      </Marker>
                    );
                  }
                  return null;
                })}
                {pumaVehicles.map((vehicle) => {
                  let status;
                  if (vehicle.wheel_based_vehicle_speed < 0) {
                    status = "Active";
                  } else {
                    status = "Inactive";
                  }
                  let lat, lng;
                  const [latStr, lngStr] = vehicle.curr_location.split(",");
                  lat = parseFloat(latStr);
                  lng = parseFloat(lngStr);
                  const heading = vehicle.direction_of_vehicle;
                  const icon = L.divIcon({
                    className: "custom-icon", // Custom class to style the icon
                    html: `<img src="${pumaMapIcon}" style="transform: rotate(${heading}deg); width: 55px; height: 60px;" />`,
                    iconSize: [20, 40], // Set the icon size
                    iconAnchor: [15, 15], // Set the anchor to the center of the icon
                    popupAnchor: [1, -34],
                    shadowSize: [41, 41],
                  });

                  return (
                    <Marker
                      key={vehicle.id}
                      position={[lat, lng]}
                      icon={icon}
                      ref={(el) => (markerRefs.current[vehicle.id] = el)}
                    >
                      <Popup>
                        <div>
                          <h3
                            style={{
                              textAlign: "center",
                              fontFamily: "Manrope",
                            }}
                          >
                            {vehicle.chassis_number}
                          </h3>
                          <p
                            style={{
                              marginLeft: "15px",
                              textAlign: "center",
                              fontFamily: "Manrope",
                            }}
                          >
                            <b>Speed</b>: {vehicle.wheel_based_vehicle_speed}{" "}
                            Km/h
                          </p>
                          <p
                            style={{
                              marginLeft: "15px",
                              textAlign: "center",
                              fontFamily: "Manrope",
                            }}
                          >
                            {status}
                          </p>
                        </div>
                      </Popup>
                    </Marker>
                  );
                })}
              </MarkerClusterGroup>
            </MapContainer>

            <div className="live-checkbox-container">
              <label>
                {vehicleCounts.Moving +
                  vehicleCounts.Idle +
                  vehicleCounts.Stopped +
                  vehicleCounts.NOdata +
                  vehicleCounts.unknown +
                  vehicleCounts.NoGps -
                  6}
                <br />
                Select All <br />
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </label>
              <label>
                <span style={{ color: "#838383" }}>
                  {vehicleCounts.Moving +
                    vehicleCounts.Idle +
                    vehicleCounts.Stopped -
                    3}
                </span>{" "}
                <br />
                Active <br />
                <input
                  type="checkbox"
                  checked={
                    selectedStatuses.Moving ||
                    selectedStatuses.Idle ||
                    selectedStatuses.Stopped
                  }
                  onChange={() => {
                    const newStatus = !(
                      selectedStatuses.Moving &&
                      selectedStatuses.Idle &&
                      selectedStatuses.Stopped
                    );
                    setSelectedStatuses((prev) => ({
                      ...prev,
                      Moving: newStatus,
                      Idle: newStatus,
                      Stopped: newStatus,
                    }));
                  }}
                />
              </label>
              <label>
                <span style={{ color: "#838383" }}>
                  {vehicleCounts.NOdata +
                    vehicleCounts.unknown +
                    vehicleCounts.NoGps -
                    3}
                </span>{" "}
                <br />
                Inactive <br />
                <input
                  type="checkbox"
                  checked={
                    selectedStatuses.NOdata ||
                    selectedStatuses.unknown ||
                    selectedStatuses.NoGps
                  }
                  onChange={() => {
                    const newStatus = !(
                      selectedStatuses.NOdata &&
                      selectedStatuses.unknown &&
                      selectedStatuses.NoGps
                    );
                    setSelectedStatuses((prev) => ({
                      ...prev,
                      NOdata: newStatus,
                      unknown: newStatus,
                      NoGps: newStatus,
                    }));
                  }}
                />
              </label>
              <label>
                <span style={{ color: "#10B320" }}>
                  {vehicleCounts.Charging}
                </span>{" "}
                <br />
                Charging <br />
                <input
                  type="checkbox"
                  checked={selectedStatuses.Charging}
                  onChange={() => handleStatusChange("Charging")}
                />
              </label>
            </div>
          </div>
          <div className="fleet-vehicle-container">
            {filteredVehicles.map((vehicle) => {
              if (
                vehicle.device_type === "Chota Hathi" ||
                vehicle.device_type === "Car" ||
                vehicle.device_type === "Person"
              ) {
                return null; // Skip this vehicle
              }
              // Determine the stroke color based on state_of_charge
              let strokeColor;
              if (vehicle.state_of_charge >= 80) {
                strokeColor = "#4caf50"; // Green
              } else if (vehicle.state_of_charge >= 50) {
                strokeColor = "#ffeb3b"; // Yellow
              } else if (vehicle.state_of_charge >= 20) {
                strokeColor = "#ff9800"; // Orange
              } else {
                strokeColor = "#f44336"; // Red
              }

              // Set vehicle status to 'Inactive' if it is NOdata, Nogps, or unknown
              const displayStatus =
                vehicle.status === "NOdata" ||
                vehicle.status === "Nogps" ||
                vehicle.status === "unknown"
                  ? "Inactive"
                  : vehicle.status === "Idle" &&
                    vehicle.charger_gun_detected2 === 1
                  ? "Charging"
                  : vehicle.status === "Moving" ||
                    vehicle.status === "Idle" ||
                    vehicle.status === "Stopped"
                  ? "Active"
                  : "Inactive";

              return (
                <div
                  key={vehicle.id}
                  className="fleet-vehicle-status"
                  onClick={() => {
                    panToVehicle(
                      parseFloat(vehicle.latitude),
                      parseFloat(vehicle.longitude)
                    );
                    if (markerRefs.current[vehicle.id]) {
                      markerRefs.current[vehicle.id].openPopup(); // Open the popup
                    }
                  }}
                >
                  <div
                    className="fleet-vehicle-movement"
                    style={{
                      backgroundColor:
                        displayStatus === "Active"
                          ? "#2A70DB5C"
                          : displayStatus === "Charging"
                          ? "#58DB2A5C"
                          : "transparent",
                    }}
                  >
                    <p
                      style={{
                        color:
                          displayStatus === "Stopped"
                            ? "#FF0303"
                            : displayStatus === "Moving"
                            ? "#266CBE"
                            : displayStatus === "Inactive"
                            ? "#838383" // Change color for inactive status
                            : displayStatus === "Idle"
                            ? "#F1B10D"
                            : displayStatus === "Charging"
                            ? "#10B320"
                            : "#838383",
                      }}
                    >
                      {displayStatus.toUpperCase()}
                    </p>
                  </div>
                  <div className="fleet-vehicle-details">
                    <p>{vehicle.registration_number}</p>
                    <img
                      src={busImg}
                      alt="busImg"
                      style={{ width: "127px", height: "77px" }}
                    />
                    <p>{vehicle.device_type}</p>
                  </div>
                  <div className="fleet-vehicle-info">
                    <label>{vehicle.name}</label>
                    <p>{vehicle.address}</p>
                    <div className="fleet-vehicle-info-alerts">
                      {/* <p>
                        Energy Consumption
                        <br />
                        <br />
                        0.08 kWh/km
                      </p> */}
                      <p>
                        Battery Temp
                        <br />
                        <br />
                        {vehicle.battery_temperature}
                      </p>
                      <p>
                        SOH
                        <br />
                        <br />
                        99%
                      </p>
                    </div>
                  </div>
                  <div className="fleet-vehicle-speedDTE">
                    <p className="fleet-vehicle-speedDTE-speed">
                      {vehicle.speedKph}km/h
                    </p>
                    <div className="fleet-percentage-ring">
                      <svg width="55" height="55">
                        <circle
                          cx="27.5"
                          cy="27.5"
                          r="22.5"
                          stroke="#none"
                          strokeWidth="10"
                          fill="none"
                        />
                        <circle
                          cx="27.5"
                          cy="27.5"
                          r="22.5"
                          stroke={strokeColor}
                          strokeWidth="10"
                          fill="none"
                          strokeDasharray={`${
                            (vehicle.state_of_charge / 100) * 141.37
                          } ${141.37}`}
                          transform="rotate(-90 27.5 27.5)"
                        />
                        <text
                          x="50%"
                          y="50%"
                          textAnchor="middle"
                          fill="#070707"
                          fontFamily="Manrope"
                          fontSize="12"
                        >
                          {vehicle.state_of_charge}%
                        </text>
                      </svg>
                    </div>
                  </div>
                </div>
              );
            })}
            {pumaVehicles.map((vehicle) => {
              const [latitude, longitude] = vehicle.curr_location
                .split(",")
                .map(Number);
              let status;
              if (vehicle.wheel_based_vehicle_speed < 0) {
                status = "Inactive";
              } else {
                status = "Active";
              }
              let strokeColor;
              if (vehicle.a_SOC_value >= 80) {
                strokeColor = "#4caf50"; // Green
              } else if (vehicle.a_SOC_value >= 50) {
                strokeColor = "#ffeb3b"; // Yellow
              } else if (vehicle.a_SOC_value >= 20) {
                strokeColor = "#ff9800"; // Orange
              } else {
                strokeColor = "#f44336"; // Red
              }
              return (
                <div
                  key={vehicle.id}
                  className="fleet-vehicle-status"
                  onClick={() => {
                    panToVehicle(latitude, longitude);
                    if (markerRefs.current[vehicle.id]) {
                      markerRefs.current[vehicle.id].openPopup(); // Open the popup
                    }
                  }}
                >
                  <div
                    className="fleet-vehicle-movement"
                    style={{
                      backgroundColor:
                        status === "Active"
                          ? "#2A70DB5C"
                          : status === "Inactive"
                          ? "#58DB2A5C"
                          : "transparent",
                    }}
                  >
                    <p
                      style={{
                        color:
                          status === "Moving"
                            ? "#266CBE"
                            : status === "Charging"
                            ? "#10B320"
                            : "#838383",
                      }}
                    >
                      {status.toUpperCase()}
                    </p>
                  </div>
                  <div className="fleet-vehicle-details">
                    <p>{vehicle.vehicle_reg_num}</p>
                    <img
                      src={pumaImg}
                      alt="pumaImg"
                      style={{ width: "145px", height: "90px" }}
                    />
                    <p>Puma</p>
                  </div>
                  <div className="fleet-vehicle-info">
                    <label>{vehicle.vehicle_reg_num}</label>
                    <p></p>
                    <div className="fleet-vehicle-info-alerts">
                      {/* <p>
                        Energy Consumption
                        <br />
                        <br />
                        0.08 kWh/km
                      </p> */}
                      <p>
                        Battery Temp
                        <br />
                        <br />
                        {vehicle.a_max_cell_temp}
                      </p>
                      <p>
                        SOH
                        <br />
                        <br />
                        99%
                      </p>
                    </div>
                  </div>
                  <div className="fleet-vehicle-speedDTE">
                    <p className="fleet-vehicle-speedDTE-speed">
                      {vehicle.wheel_based_vehicle_speed}km/h
                    </p>
                    <div className="fleet-percentage-ring">
                      <svg width="55" height="55">
                        <circle
                          cx="27.5"
                          cy="27.5"
                          r="22.5"
                          stroke="#none"
                          strokeWidth="10"
                          fill="none"
                        />
                        <circle
                          cx="27.5"
                          cy="27.5"
                          r="22.5"
                          stroke={strokeColor}
                          strokeWidth="10"
                          fill="none"
                          strokeDasharray={`${
                            (vehicle.a_SOC_value / 100) * 141.37
                          } ${141.37}`}
                          transform="rotate(-90 27.5 27.5)"
                        />
                        <text
                          x="50%"
                          y="50%"
                          textAnchor="middle"
                          fill="#070707"
                          fontFamily="Manrope"
                          fontSize="12"
                        >
                          {vehicle.a_SOC_value}%
                        </text>
                      </svg>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="fleet-chart-container">
        <div className="fleet-chart-container-soc">
          <Chart
            options={{
              ...chartOptions,
              title: {
                text: "SOC",
                style: {
                  color: "#000",
                  fontFamily: "Manrope",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "22px", // 146.667%
                },
                offsetX: 85,
              },
            }}
            series={chartOptions.series}
            type="pie"
          />
        </div>
        <div className="fleet-chart-container-temp">
          <Chart
            options={{
              ...temperatureChartOptions,
              title: {
                text: "Battery Temperature",
                style: {
                  color: "#000",
                  fontFamily: "Manrope",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "22px", // 146.667%
                },
                offsetX: 42,
              },
            }}
            series={temperatureChartOptions.series}
            type="pie"
          />
        </div>
      </div>
    </>
  );
};

export default Fleet;
