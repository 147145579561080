const modelOptions = {
    MBMT: Array.from({ length: 32 }, (_, i) => {
        const index = i + 1;
        // Apply different formatting for MBMT models based on the index
        return index <= 10
            ? `MBMT ${String(index).padStart(2, "0")}` // MBMT 01 to MBMT 10
            : `MBMT-${String(index).padStart(2, "0")}`; // MBMT-11 to MBMT-32
    }),
    Aeromall: Array.from({ length: 2 }, (_, i) => {
        // Always format Aeromall models as Aeromall -1, Aeromall -2
        return `Aeromall -${i + 1}`;
    }),
    UMC: Array.from({ length: 10 }, (_, i) => {
        // UMC models formatted as UMC-1 to UMC-10
        return `UMC-${i + 1}`;
    }),
    Uber: Array.from({ length: 4 }, (_, i) => `Uber-${i + 1}`)
};

const data = {
    fleetOwners: [
        {
            name: "Transvolt",
            group: [
                { name: "MBMT", models: [] },
                { name: "Aeromall", models: [] },
                { name: "UMC", models: [] },
                { name: "Uber", models: [] }
            ]
        }
    ]
};

// Adding models to each group's models array
data.fleetOwners[0].group.forEach(group => {
    group.models = modelOptions[group.name]; // Assign the corresponding models
});

export { data };