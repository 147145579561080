// accessNames.js
let accessNames = []; // Initialize an empty array to store names

export const getAccessNames = () => {
  return accessNames; // Function to get the current names
};

export const addAccessNames = (names) => {
  // Filter out names that already exist in accessNames
  const newNames = names.filter((name) => !accessNames.includes(name));

  // Add only the new names to accessNames
  accessNames = [...accessNames, ...newNames];
};

// Function to fetch access names from the API
export const fetchAccessData = () => {
  const apiUrl = "https://eka-connect.com/Users/Get_access/";
  return fetch(apiUrl)
    .then((response) => response.json())
    .then((data) => {
      const names = data.map((item) => item.name);
      addAccessNames(names);
    })
    .catch((error) => console.error("Error fetching Data:", error));
};

// Immediately invoke fetchAccessData to update accessNames on module load
fetchAccessData();
