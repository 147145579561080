/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./VOR.css";
import "./Scheduler.css";

function VOR() {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [vehicleModel, setVehicleModel] = useState("");
  const [inDate, setInDate] = useState("");
  const [priority, setPriority] = useState("Medium");
  const [whatNeedsToFix, setWhatNeedsToFix] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [status, setStatus] = useState("");
  const [repairHours, setRepairHours] = useState("");
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null); // New state for selected ticket

  // Retrieve tickets from localStorage when component mounts
  useEffect(() => {
    const savedTickets = JSON.parse(localStorage.getItem("tickets")) || [];
    setTickets(savedTickets);
  }, []);

  // Handle form submit for fleet selection
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsFormOpen(true);
  };

  // Handle ticket submission
  const handleTicketSubmit = async () => {
    const newTicket = {
      vehicleModel,
      inDate,
      dueDate,
      status,
      repairHours,
      whatNeedsToFix,
      priority,
    };

    // Add the new ticket to the state and save it in localStorage
    setTickets((prevTickets) => {
      const updatedTickets = [...prevTickets, newTicket];
      localStorage.setItem("tickets", JSON.stringify(updatedTickets));
      return updatedTickets;
    });

    // Clear the form fields
    setVehicleModel("");
    setInDate("");
    setDueDate("");
    setStatus("");
    setRepairHours("");
    setWhatNeedsToFix("");
    setPriority("Medium"); // Reset to default value

    setIsFormOpen(false); // Close the form
  };

  return (
    <>
      <div className="vor-header">
      <p style={{ textAlign : "center" }}>Maintenance and Diagnostics Scheduler</p>
      </div>

      <form className="selection-container" onSubmit={handleSubmit}>
        <div className="selection-item">
          <button className="schd-btn-primary" type="submit">
            New Ticket
          </button>
        </div>
      </form>

      <div className="additional-container1">
        {isFormOpen && (
          <div className="container_maintenance">
            <form>
              <div>
                <label htmlFor="vehicleModel">Vehicle Model:</label>
                <input
                  type="text"
                  id="vehicleModel"
                  value={vehicleModel}
                  onChange={(e) => setVehicleModel(e.target.value)}
                  placeholder="Please enter Vehicle Name"
                />
              </div>
              <div>
                <label htmlFor="inDate">In Date:</label>
                <input
                  type="date"
                  id="inDate"
                  value={inDate}
                  onChange={(e) => setInDate(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="dueDate">Due Date:</label>
                <input
                  type="date"
                  id="dueDate"
                  value={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="repairHours">Repair Hours:</label>
                <input
                  type="number"
                  id="repairHours"
                  style={{
                    width: "300px",
                    height: "40px",
                    fontSize: "16px",
                  }}
                  value={repairHours}
                  onChange={(e) => setRepairHours(e.target.value)}
                  placeholder="Enter estimated repair hours"
                />
              </div>
              <div>
                <label htmlFor="status">Status:</label>
                <select
                  id="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="">Select Status</option>
                  <option value="Resolve">Resolve</option>
                  <option value="Due">Due</option>
                  <option value="OverDue">OverDue</option>
                </select>
              </div>
              <div>
                <label htmlFor="priority">Priority:</label>
                <select
                  id="priority"
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                >
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </div>
              <div>
                <label htmlFor="whatNeedsToFix">What Needs To Fix:</label>
                <textarea
                  id="whatNeedsToFix"
                  value={whatNeedsToFix}
                  onChange={(e) => setWhatNeedsToFix(e.target.value)}
                />
              </div>
              <div>
                <button type="button" onClick={() => setIsFormOpen(false)}>
                  Cancel
                </button>
                <button type="button" onClick={handleTicketSubmit}>
                  Create Ticket
                </button>
              </div>
            </form>
          </div>
        )}

        {tickets.length > 0 && (
          <div className="table-container">
            <table className="ticket-table">
              <thead>
                <tr>
                  <th>Vehicle</th>
                  <th>Issue</th>
                  <th>Created On</th>
                  <th>Due Date</th>
                  <th>Severity</th>
                  <th>Status</th>
                  <th>Repair Hours</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tickets.map((ticket, index) => (
                  <tr key={index}>
                    <td>{ticket.vehicleModel}</td>
                    <td>{ticket.whatNeedsToFix}</td>
                    <td>{ticket.inDate}</td>
                    <td>{ticket.dueDate}</td>
                    <td>{ticket.priority}</td>
                    <td
                      style={{
                        color:
                          ticket.status === "Resolve"
                            ? "#2F9F1D"
                            : ticket.status === "Due"
                            ? "#C96A25"
                            : ticket.status === "OverDue"
                            ? "#FF0B0B"
                            : "black",
                      }}
                    >
                      {ticket.status}
                    </td>
                    <td>{ticket.repairHours}</td>
                    <td>
                      <a
                        href="#"
                        className="details-link"
                        onClick={() => setSelectedTicket(ticket)} // Correctly setting selected ticket
                      >
                        Details
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Optional: Display selected ticket details */}
        {selectedTicket && (
          <div className="ticket-details">
            <h3>BUS Ticket Details</h3>
            <p>
              <strong>Vehicle Model:</strong> &nbsp;{" "}
              {selectedTicket.vehicleModel}
            </p>
            <p>
              <strong>In Date:</strong> &nbsp; {selectedTicket.inDate}
            </p>
            <p>
              <strong>Due Date:</strong> &nbsp; {selectedTicket.dueDate}
            </p>
            <p>
              <strong>Repair Hours:</strong> &nbsp; {selectedTicket.repairHours}
            </p>
            <p>
              <strong>Status:</strong> &nbsp; {selectedTicket.status}
            </p>
            <p>
              <strong>Priority:</strong> &nbsp; {selectedTicket.priority}
            </p>
            <p>
              <strong>What Needs To Fix:</strong>{" "}
              {selectedTicket.whatNeedsToFix}
            </p>
            <button onClick={() => setSelectedTicket(null)}>Close</button>
          </div>
        )}
      </div>

      <div className="prognostics-container">
        <div className="prognostics-box">
          <h3>Prognostics</h3>
          <ul>
            <li>
              <input type="checkbox" /> MBMT20 distance traveled is more than
              5000 kms. Please inspect.
            </li>
            <li>
              <input type="checkbox" /> Aeromall-1 compressor fault likely to
              occur. Please inspect.
            </li>
          </ul>
        </div>

        <div className="scheduler-box">
          <h3>Regular Maintenance Scheduler</h3>
          <table className="maintenance-table">
            <thead>
              <tr>
                <th>Vehicle</th>
                <th>Kms</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>MBMT03</td>
                <td>100,300</td>
                <td>
                  <a href="#" className="schedule-link">
                    Schedule
                  </a>
                </td>
              </tr>
              <tr>
                <td>MBMT09</td>
                <td>100,021</td>
                <td>
                  <a href="#" className="schedule-link">
                    Schedule
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default VOR;
