import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";
import Busses from "../assets/busses.jpeg";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
  }

  body {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    padding: 0 10px;
    position: relative;
  }

  body::before {
    content: "";
    position: absolute;
    width: 100%; 
    height: 100%;
    background: url(${Busses});
    background-position: center;
    background-size: cover;
    z-index: -1;
  }
`;

const Container = styled.div`
  width: 400px;
  margin-top: 60px;
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  border: 2px solid rgba(172, 159, 140, 0.329);
  backdrop-filter: blur(10px);
  z-index: 1;
`;

const InputField = styled.div`
  position: relative;
  border-bottom: 2px solid #ccc;
  margin: 15px 0;

  label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: #fff;
    font-size: 16px;
    pointer-events: none;
    transition: 0.15s ease;
  }

  input {
    width: 100%;
    height: 40px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    color: #fff;

    &:focus ~ label,
    &:valid ~ label {
      font-size: 0.8rem;
      top: 10px;
      transform: translateY(-120%);
    }
  }
`;

const Forget = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0 35px 0;
  color: #fff;
`;

const Button = styled.button`
  background: #fff;
  color: #000;
  font-weight: 600;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 16px;
  border: 2px solid transparent;
  transition: 0.3s ease;

  &:hover {
    color: #fff;
    border-color: #fff;
    background: rgba(255, 255, 255, 0.15);
  }
`;

const CreateAccount = styled.div`
  text-align: center;
  margin-top: 30px; 
  color: #fff;
`;

const Login = ({ setIsLoggedIn, setLoading, setUsername }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        'https://eka-connect.com/Users/User_auth/',

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: email,
            password: password,
          }),
        }
      );

      const data = await response.json(); // Get the response data
      console.log("Response from backend:", data); // Log the response

      if (response.ok) {
        setIsLoggedIn(true);
        setUsername(email); // Set the username
        setLoading(true);
        navigate("/loading");
      } else {
        const errorData = await response.json();
        alert(
          `Error: ${
            errorData.message ||
            "Invalid username or password. Please try again."
          }`
        );
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <GlobalStyle />
      <Container>
        <form onSubmit={handleLogin}>
          <h2
            style={{
              fontSize: "25px",
              marginBottom: "20px",
              color: "#050505",
              fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
            }}
          >
            Fleet Cloud Analytics
          </h2>
          <InputField>
            <input
              type="text"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="email">Email</label>
          </InputField>
          <InputField>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="password">Password</label>
          </InputField>
          <Forget>
            <div>
              <input type="checkbox" /> Remember me
            </div>
            <div>Forget Password?</div>
          </Forget>
          <Button type="submit">Login</Button>
          <CreateAccount>
            Don't have an account? <a href="/signup">Create Account</a>
          </CreateAccount>
        </form>
      </Container>
    </>
  );
};

export default Login;
