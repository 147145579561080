import React, { useEffect, useState } from "react";
import "./AccessManagement.css";
import editIcon from "../../assets/edit.png";
import deleteIcon from "../../assets/delete.png";

function AccessManagement() {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    live: false,
    fleet: false,
    vehicle: false,
    summary: false,
    fleetMovement: false,
    healthMonitoring: false,
    maintenanceAndDiagnostics: false,
    scheduler: false,
    vor: false,
    diagnosticTree: false,
    reports: false,
    trails: false,
    fleetManagement: false,
    geofence: false,
    accessManagement: false,
    fleetUser: false,
    alarmsAndFaults: false,
    driverManagement: false,
    vehicleAndFleet: false,
    settings: false,
    profile: false,
    theme: false,
    userManagement: false,
  });

  const [accessData, setAccessData] = useState([]);
  const [editingId, setEditingId] = useState(null);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  useEffect(() => {
    setAccessData([]);
    fetchAccessData();
  }, []);

  const fetchAccessData = () => {
    const apiUrl = "https://eka-connect.com/Users/Get_access/";
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setAccessData(data);
      })
      .catch((error) => console.error("Error fetching Data:", error));
  };
  const handleEdit = (item) => {
    setEditingId(item.name);
    setFormData(item);
    setShowForm(true);
  };

  const handleDelete = async (name) => {
    if (window.confirm("Are you sure you want to delete this access?")) {
      try {
        const response = await fetch(
          `https://eka-connect.com/access/${name}`,
          {
            method: "DELETE",
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        fetchAccessData(); // Refresh the data after deletion
      } catch (error) {
        console.error("Error deleting access:", error);
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: checked,
      ...(name === "live" && { fleet: checked, vehicle: checked }),
      ...(name === "summary" && {
        fleetMovement: checked,
        healthMonitoring: checked,
      }),
      ...(name === "maintenanceAndDiagnostics" && {
        scheduler: checked,
        vor: checked,
        diagnosticTree: checked,
      }),
      ...(name === "fleetManagement" && {
        geofence: checked,
        accessManagement: checked,
        fleetUser: checked,
        alarmsAndFaults: checked,
        driverManagement: checked,
        vehicleAndFleet: checked,
      }),
      ...(name === "settings" && {
        profile: checked,
        theme: checked,
        userManagement: checked,
      }),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = editingId
        ? `https://eka-connect.com/access/${editingId}`
        : "https://eka-connect.com/Users/Access_name/";
      const method = editingId ? "POST" : "POST";

      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result);
      setShowForm(false);
      setEditingId(null);
      fetchAccessData(); // Refresh the data after submission
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="AM-header-container">
        <p>Fleet Management</p>
        <p style={{ textAlign : "center" , marginTop : "-35px"}}>Access Management</p>
      </div>
      <button className="AM-button" onClick={handleButtonClick}>
        {showForm ? "Hide Form" : "Define Access"}
      </button>
      <div className="AM-access-table">
        <table>
          <thead>
            <tr>
              <th>Access</th>
              <th>Created On</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {accessData.map((item) => (
              <tr key={item.name}>
                <td>{item.name}</td>
                <td>{new Date(item.date).toLocaleDateString()}</td>
                <td>Active</td>
                <td>
                  <img
                    src={deleteIcon}
                    alt="Delete"
                    style={{
                      width: "15px",
                      height: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDelete(item.name)}
                  />
                  <img
                    src={editIcon}
                    alt="Edit"
                    style={{
                      marginLeft: "20px",
                      width: "15px",
                      height: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEdit(item)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showForm && (
        <div className="AM-existing-access AM-modal">
          <form className="AM-form" onSubmit={handleSubmit}>
            <label className="AM-AccessName">
              Access Name
              <input
                type="text"
                name="name"
                placeholder="Enter Name"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
            </label>
            <p className="AM-SideBar">SideBar</p>
            <label>
              <input
                type="checkbox"
                name="live"
                checked={formData.live}
                onChange={handleCheckboxChange}
              />
              Live
              <div className="AM-submenu">
                <label>
                  <input
                    type="checkbox"
                    name="fleet"
                    checked={formData.fleet}
                    onChange={(e) =>
                      setFormData({ ...formData, fleet: e.target.checked })
                    }
                  />
                  Fleet
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="vehicle"
                    checked={formData.vehicle}
                    onChange={(e) =>
                      setFormData({ ...formData, vehicle: e.target.checked })
                    }
                  />
                  Vehicle
                </label>
              </div>
            </label>
            <label>
              <input
                type="checkbox"
                name="summary"
                checked={formData.summary}
                onChange={handleCheckboxChange}
              />
              Summary
              <div className="AM-submenu">
                <label>
                  <input
                    type="checkbox"
                    name="scheduler"
                    checked={formData.scheduler}
                    onChange={(e) =>
                      setFormData({ ...formData, scheduler: e.target.checked })
                    }
                  />
                  Scheduler
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="vor"
                    checked={formData.vor}
                    onChange={(e) =>
                      setFormData({ ...formData, vor: e.target.checked })
                    }
                  />
                  VOR
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="diagnosticTree"
                    checked={formData.diagnosticTree}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        diagnosticTree: e.target.checked,
                      })
                    }
                  />
                  Diagnostic Tree
                </label>
              </div>
            </label>
            <label>
              <input
                type="checkbox"
                name="reports"
                checked={formData.reports}
                onChange={(e) =>
                  setFormData({ ...formData, reports: e.target.checked })
                }
              />
              Reports
            </label>
            <label>
              <input
                type="checkbox"
                name="trails"
                checked={formData.trails}
                onChange={(e) =>
                  setFormData({ ...formData, trails: e.target.checked })
                }
              />
              Trails
            </label>
            <label>
              <input
                type="checkbox"
                name="fleetManagement"
                checked={formData.fleetManagement}
                onChange={handleCheckboxChange}
              />
              Fleet Management
              <div className="AM-submenu">
                <label>
                  <input
                    type="checkbox"
                    name="geofence"
                    checked={formData.geofence}
                    onChange={(e) =>
                      setFormData({ ...formData, geofence: e.target.checked })
                    }
                  />
                  Geofence
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="accessManagement"
                    checked={formData.accessManagement}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        accessManagement: e.target.checked,
                      })
                    }
                  />
                  Access Management
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="fleetUser"
                    checked={formData.fleetUser}
                    onChange={(e) =>
                      setFormData({ ...formData, fleetUser: e.target.checked })
                    }
                  />
                  Fleet User
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="alarmsAndFaults"
                    checked={formData.alarmsAndFaults}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        alarmsAndFaults: e.target.checked,
                      })
                    }
                  />
                  Alarms and Faults
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="driverManagement"
                    checked={formData.driverManagement}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        driverManagement: e.target.checked,
                      })
                    }
                  />
                  Driver Management
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="vehicleAndFleet"
                    checked={formData.vehicleAndFleet}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        vehicleAndFleet: e.target.checked,
                      })
                    }
                  />
                  Vehicle And Fleet
                </label>
              </div>
            </label>
            <label>
              <input
                type="checkbox"
                name="settings"
                checked={formData.settings}
                onChange={handleCheckboxChange}
              />
              Settings
              <div className="AM-submenu">
                <label>
                  <input
                    type="checkbox"
                    name="profile"
                    checked={formData.profile}
                    onChange={(e) =>
                      setFormData({ ...formData, profile: e.target.checked })
                    }
                  />
                  Profile
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="theme"
                    checked={formData.theme}
                    onChange={(e) =>
                      setFormData({ ...formData, theme: e.target.checked })
                    }
                  />
                  Theme
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="userManagement"
                    checked={formData.userManagement}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        userManagement: e.target.checked,
                      })
                    }
                  />
                  User Management
                </label>
              </div>
            </label>
            <button type="submit">Submit</button>
          </form>
        </div>
      )}
    </>
  );
}

export default AccessManagement;
